// Modules

// Services
import Http from "services/http.service";

// Get User
export const getUser = async () => {
  const response = await Http.get("/users/me");

  return response.data;
};

// Get Permissions
export const getPermissions = async () => {
  const response = await Http.get("/users/permissions");

  return response.data;
};

// Get Treatment Plans
export const getTreatmentPlans = async () => {
  const response = await Http.get(`/users/treatment-plans`);

  return response.data;
};

// Delete Treatment Plans
export const deleteTreatmentPlan = async planId => {
  const response = await Http.delete(`/users/treatment-plans/${planId}`);

  return response.data;
};

// Upload Treatment Plan
export const uploadTreatmentPlan = async ({ file, fileAlias }) => {
  try {
    const url = `/users/treatment-plans/upload?alias=${fileAlias}`;

    const payload = new FormData();

    payload.append("file", file);

    const response = await Http.post(url, payload, {
      headers: { "Content-Type": "multipart/form-data" }
    });

    return response.data;
  } catch (exception) {
    return Promise.reject(exception);
  }
};

// Get AR Claims
export const getARClaims = async () => {
  const response = await Http.get(`/users/ar-claims`);

  return response.data;
};

// Delete AR Claims
export const deleteARClaim = async planId => {
  const response = await Http.delete(`/users/ar-claims/${planId}`);

  return response.data;
};

// Upload AR Claim
export const uploadARClaim = async ({ file, fileAlias }) => {
  try {
    const url = `/users/ar-claims/upload?alias=${fileAlias}`;

    const payload = new FormData();

    payload.append("file", file);

    const response = await Http.post(url, payload, {
      headers: { "Content-Type": "multipart/form-data" }
    });

    return response.data;
  } catch (exception) {
    return Promise.reject(exception);
  }
};

// Exports
export default {
  getUser,
  getPermissions,
  getARClaims,
  deleteARClaim,
  uploadARClaim,
  getTreatmentPlans,
  deleteTreatmentPlan,
  uploadTreatmentPlan
};
