// Modules
import _ from "lodash";
import Jwt from "jsonwebtoken";

// Services
import Http from "services/http.service";
import StorageService from "services/storage.service";

// Is Logged In
export const isLoggedIn = () => {
  // Get token from storage
  const token = StorageService.getFromLocalStorage("act");

  // Token not found return false
  if (_.isEmpty(token)) {
    return false;
  }

  // Decode token
  const decodedToken = Jwt.decode(token);

  // Not able to decode token, return false
  if (_.isEmpty(decodedToken)) {
    return false;
  }

  const { exp, iat, id } = decodedToken;
  const now = Math.round(new Date().getTime() / 1000);

  // Return result
  return id && now > iat && now < exp;
};

// Login
export const login = ({ username, password }) => {
  return Http.post("/auth/login", { username, password }).then(response => {
    const { tokens, user } = response.data;

    StorageService.setInLocalStorage("act", tokens.accessToken);
    StorageService.setInLocalStorage("rft", tokens.refreshToken);
    StorageService.setInLocalStorage("url", user.role);
    StorageService.setInLocalStorage("usr", user.id);

    return user;
  });
};

// Request Password Reset
export const requestPasswordReset = ({ emailAddress }) => {
  return Http.post("/auth/request-password-reset", { emailAddress }).then(
    response => response.data
  );
};

// Logout
export const logout = ({ shouldRedirect = true } = {}) => {
  // Clear Storage
  StorageService.removeFromLocalStorage("act");
  StorageService.removeFromLocalStorage("rft");
  StorageService.removeFromLocalStorage("url");
  StorageService.removeFromLocalStorage("usr");

  if (shouldRedirect) {
    window.location.href = "/login";
  }
};

// Sign Up
export const signUp = async user => {
  try {
    const payload = {};

    _.forIn(user, (value, key) => {
      if (!_.isString(value)) {
        value = _.toString(value);
      }

      payload[key] = value;
    });

    const response = await Http.post(`/auth/signup`, payload);

    return response.data;
  } catch (exception) {
    return Promise.reject(exception);
  }
};

// Change Password
export const changePassword = async ({ newPassword }) => {
  return await Http.post(`/auth/change-password`, { newPassword });
};

// Exports
export default {
  changePassword,
  isLoggedIn,
  login,
  logout,
  requestPasswordReset,
  signUp
};
