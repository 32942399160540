// Modules

// Services
import Http from "services/http.service";

// Get Locations
export const getLocations = async () => {
  try {
    const response = await Http.get(`/locations`);

    return response.data;
  } catch (exception) {
    return Promise.reject(exception);
  }
};

// Get Location
export const getLocation = async locationId => {
  try {
    const response = await Http.get(`/locations/${locationId}`);

    return response.data;
  } catch (exception) {
    return Promise.reject(exception);
  }
};

// Exports
export default {
  getLocation,
  getLocations
};
