// Modules
import Styled from "styled-components";

// Theme
import Spacing from "theming/spacing";

// Wrapper
export const Wrapper = Styled.div`
  display: flex;
  flex-direction: column;
`;

// Row
export const Row = Styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

// Blocks
export const Blocks = {
  Wrapper: Styled.div`
    display: flex;
    margin: -${Spacing.SpaceNx(0.5)};
    margin-bottom: 0;
    flex-wrap: wrap;
  `,
  Block: Styled.div`
    width: 25%;
    padding: ${Spacing.SpaceNx(0.5)};
    padding-bottom: 0;
  `
};
