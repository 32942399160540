// Modules

// Services
import Http from "services/http.service";

// Get Integrations
export const getIntegrations = async () => {
  const response = await Http.get(`/integrations`);

  return response.data;
};

// Enable Open Dental
export const enableOpenDental = async () => {
  const response = await Http.post(`/integrations/opendental/toggle`, {
    enable: true
  });

  return response.data;
};

// Disable Open Dental
export const disableOpenDental = async () => {
  const response = await Http.post(`/integrations/opendental/toggle`, {
    enable: false
  });

  return response.data;
};

// Save Open Dental Credentials
export const saveOpenDentalCredentials = async credentials => {
  const response = await Http.post(`/integrations/opendental/credentials`, {
    customerKey: credentials.customerKey,
    developerKey: credentials.developerKey
  });

  return response.data;
};

// Exports
export default {
  enableOpenDental,
  disableOpenDental,
  saveOpenDentalCredentials,
  getIntegrations
};
