// Modules
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

// Redux
import {
  setBackgroundTint,
  setFluidLayout,
  setPageTitle
} from "redux/layout.slice";

// UI
import {
  Dialog,
  Button,
  IconButton,
  Spinner,
  KeyIcon,
  TextInputField,
  toaster
} from "evergreen-ui";

// Assets
import { USER_ROLE_OPTIONS } from "common/constants";

// Styles
import { Wrapper, StateWrapper } from "./users.styles";

// Shared
import Table from "components/shared/table";
import EmptyState from "components/shared/empty-state";
import ErrorState from "components/shared/error-state";

// Services
import AdminService from "services/admin.service";

// Component
export const Users = () => {
  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();

  // Reducers
  const userReducer = useSelector(({ user }) => user);

  const { permissions, user } = userReducer;

  // State
  const [users, setUsers] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [hasFailedToFetchUsers, setHasFailedToFetchUsers] = useState(false);
  const [isFetchingUsers, setIsFetchingUsers] = useState(true);
  const [newPassword, setNewPassword] = useState("");

  // Get Users
  const _getUsers = async () => {
    try {
      setIsFetchingUsers(true);

      const users = await AdminService.getUsers();

      setUsers(users);
      setHasFailedToFetchUsers(false);
      setIsFetchingUsers(false);
    } catch (exception) {
      setHasFailedToFetchUsers(true);

      toaster.danger(exception.uiMessage, {
        duration: 60000
      });
    }
  };

  // Open Password Change Popup
  const _openPasswordChangePopup = user => {
    setIsPasswordModalOpen(true);
    setSelectedUser(user);
  };

  // On Close Change Password Modal
  const _onCloseChangePasswordModal = () => {
    setIsPasswordModalOpen(false);
    setSelectedUser(null);
  };

  // On Change New Password
  const _onChangeNewPassword = event => {
    setNewPassword(event.target.value);
  };

  // On Change Password
  const _onChangePassword = async () => {
    try {
      setIsChangingPassword(true);

      await AdminService.changeUserPassword({
        userId: selectedUser.id,
        newPassword
      });

      toaster.success("Password changed!");

      setIsChangingPassword(false);
      setIsPasswordModalOpen(false);
      setSelectedUser(null);
    } catch (exception) {
      setIsChangingPassword(false);

      toaster.danger(exception.uiMessage);
    }
  };

  useEffect(() => {
    if (!permissions.canSeeUsers) {
      return history.replace("/not-found");
    }

    if (user?.role !== USER_ROLE_OPTIONS.administrator.id) {
      return history.replace("/not-found");
    }

    dispatch(setFluidLayout(false));
    dispatch(setPageTitle({ title: "Users" }));
    dispatch(setBackgroundTint(true));

    _getUsers();
  }, []);

  const tableHeaders = [
    {
      id: "thType",
      label: "Type"
    },
    {
      id: "thFirstName",
      label: "First Name"
    },
    {
      id: "thLastName",
      label: "Last Name"
    },
    {
      id: "thEmailAddress",
      label: "Email"
    },
    {
      id: "thMobileNumber",
      label: "Mobile Number"
    },
    {
      id: "thUsername",
      label: "Username"
    },
    {
      id: "thActions",
      width: 32
    }
  ];

  const tableData = _.map(users, user => ({
    id: user.id,
    fields: [
      {
        thId: "thType",
        value: (() => {
          if (user.role === 1) {
            return "Dental Office";
          }

          if (user.role === 2) {
            return "Insurance Agent";
          }

          if (user.role === 99) {
            return "Administrator";
          }

          return "-";
        })()
      },
      {
        thId: "thFirstName",
        value: (() => {
          if (userReducer.user.id === user.id) {
            return `${user.firstName} (Me)`;
          }

          return user.firstName;
        })()
      },
      { thId: "thLastName", value: user.lastName },
      { thId: "thEmailAddress", value: user.emailAddress },
      { thId: "thMobileNumber", value: user.mobileNumber },
      { thId: "thUsername", value: user.username },
      {
        thId: "thActions",
        value: () => {
          if (user.role === 99) {
            return null;
          }

          return (
            <IconButton
              icon={KeyIcon}
              onClick={() => _openPasswordChangePopup(user)}
              marginLeft={12}
            />
          );
        },
        width: 32
      }
    ]
  }));

  if (isFetchingUsers) {
    return (
      <Wrapper>
        <StateWrapper>
          <Spinner />
        </StateWrapper>
      </Wrapper>
    );
  }

  if (hasFailedToFetchUsers) {
    return (
      <Wrapper>
        <StateWrapper>
          <ErrorState />
        </StateWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {_.isEmpty(users) ? (
        <StateWrapper>
          <EmptyState />
        </StateWrapper>
      ) : (
        <Table data={tableData} header={tableHeaders} />
      )}

      {selectedUser && (
        <Dialog
          title={`Change Password for ${selectedUser.emailAddress}`}
          isShown={isPasswordModalOpen}
          hasClose={!isChangingPassword}
          shouldCloseOnOverlayClick={!isChangingPassword}
          shouldCloseOnEscapePress={!isChangingPassword}
          onCloseComplete={_onCloseChangePasswordModal}
          hasFooter={false}
        >
          <TextInputField
            type="text"
            label="New Password"
            disabled={isChangingPassword}
            onChange={_onChangeNewPassword}
            marginBottom={8}
          />
          <Button
            marginBottom={24}
            onClick={_onChangePassword}
            isLoading={isChangingPassword}
            disabled={isChangingPassword}
            appearance="primary"
          >
            Change Password
          </Button>
        </Dialog>
      )}
    </Wrapper>
  );
};

// Exports
export default Users;
