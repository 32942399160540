// Modules
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Moment from "moment";

// UI
import {
  Button,
  Dialog,
  IconButton,
  TrashIcon,
  CloudDownloadIcon,
  Spinner,
  toaster
} from "evergreen-ui";

// Redux
import {
  setBackgroundTint,
  setFluidLayout,
  setPageTitle
} from "redux/layout.slice";

// Styles
import { Wrapper, StateWrapper } from "./treatment-plans.styles";

// Assets
import { DateFormatLong } from "common/utilities";

// Shared
import Table from "components/shared/table";
import EmptyState from "components/shared/empty-state";
import ErrorState from "components/shared/error-state";
import Stripe from "components/shared/stripe";

// Services
import UserService from "services/user.service";

// Component
export const TreatmentPlans = () => {
  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();

  // Reducers
  const userReducer = useSelector(({ user }) => user);

  const { permissions } = userReducer;

  // State
  const [treatmentPlans, setTreatmentPlans] = useState([]);
  const [isFetchingPlans, setIsFetchingPlans] = useState(false);
  const [hasFailedToFetchPlans, setHasFailedToFetchPlans] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [currentItemToDelete, setCurrentItemToDelete] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // Get Treatment Plans
  const _getTreatmentPlans = async () => {
    try {
      setIsFetchingPlans(true);
      setHasFailedToFetchPlans(false);

      const treatmentPlans = await UserService.getTreatmentPlans();

      setTreatmentPlans(treatmentPlans);
      setHasFailedToFetchPlans(false);
      setIsFetchingPlans(false);
    } catch (exception) {
      setHasFailedToFetchPlans(true);
      setIsFetchingPlans(false);

      toaster.danger(exception.uiMessage);
    }
  };

  // On Upload Treatment Plan
  const _onUploadTreatmentPlan = async () => {
    try {
      setIsUploading(true);

      const { files } = event.target;

      const [selectedFile] = files;

      if (!selectedFile) {
        return;
      }

      // Upload
      await UserService.uploadTreatmentPlan({
        file: selectedFile,
        fileAlias: encodeURIComponent(selectedFile.name)
      });

      // Fetch Treatment Plans
      _getTreatmentPlans();

      setIsUploading(false);
    } catch (exception) {
      setIsUploading(false);

      toaster.danger(exception.uiMessage, {
        duration: 60000
      });
    }
  };

  // On Download Treatment Plan
  const _onDownloadTreatmentPlan = path => {
    window.open(path);
  };

  // On Show Delete Confirmation
  const _onShowDeleteConfirmation = planId => {
    setShowDeleteConfirmation(true);

    setCurrentItemToDelete(planId);
  };

  // On Confirm Delete Confirmation
  const _onConfirmDeleteConfirmation = async () => {
    try {
      setIsDeleting(true);

      await UserService.deleteTreatmentPlan(currentItemToDelete);

      // Update treatment plans
      const updatedTreatmentPlans = _.filter(
        treatmentPlans,
        t => !_.isEqual(t.id, currentItemToDelete)
      );

      setTreatmentPlans(updatedTreatmentPlans);

      toaster.success("Deleted!");

      setIsDeleting(false);
      setCurrentItemToDelete(null);
      setShowDeleteConfirmation(false);
    } catch (exception) {
      setIsDeleting(false);

      toaster.danger("Couldn't delete the treatment plan. Please try again.");
    }
  };

  // On Close Delete Confirmation
  const _onCloseDeleteConfirmation = () => {
    setCurrentItemToDelete(null);
    setShowDeleteConfirmation(false);
  };

  // On Cancel Delete Confirmation
  const _onCancelDeleteConfirmation = () => {
    setCurrentItemToDelete(null);
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    if (!permissions.canSeeTreatmentPlans) {
      return history.replace("/not-found");
    }

    dispatch(setFluidLayout(false));
    dispatch(setPageTitle({ title: "Treatment Plans" }));
    dispatch(setBackgroundTint(true));

    // Get Treatment Plans
    _getTreatmentPlans();
  }, []);

  const tableHeaders = [
    {
      id: "thDocumentId",
      label: "Unique ID",
      width: 240
    },
    {
      id: "thName",
      label: "Name"
    },
    {
      id: "thDentalOfficeName",
      hidden: !permissions.canSeeDentalOfficeNameInTreatmentPlans,
      label: "Dental Office Name"
    },
    {
      id: "thUploadDate",
      label: "Uploaded On"
    },
    {
      id: "thActions",
      width: 96
    }
  ];
  const tableData = _.map(treatmentPlans, plan => {
    return {
      id: plan.id,
      fields: [
        { thId: "thDocumentId", value: _.toUpper(plan.id), width: 240 },
        { thId: "thName", value: plan.name },
        {
          thId: "thDentalOfficeName",
          hidden: !permissions.canSeeDentalOfficeNameInTreatmentPlans,
          value: plan.dentalOfficeName
        },
        {
          thId: "thUploadDate",
          value: Moment.unix(plan.uploadedOn).format(DateFormatLong)
        },
        {
          thId: "thActions",
          value: () => (
            <React.Fragment>
              <IconButton
                icon={CloudDownloadIcon}
                onClick={() => _onDownloadTreatmentPlan(plan.path)}
                marginLeft={12}
              />
              <IconButton
                intent="danger"
                icon={TrashIcon}
                onClick={() => _onShowDeleteConfirmation(plan.id)}
                marginLeft={12}
              />
            </React.Fragment>
          ),
          width: 96
        }
      ]
    };
  });

  if (isFetchingPlans) {
    return (
      <Wrapper>
        <StateWrapper>
          <Spinner />
        </StateWrapper>
      </Wrapper>
    );
  }

  if (hasFailedToFetchPlans) {
    return (
      <Wrapper>
        <StateWrapper>
          <ErrorState />
        </StateWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {permissions.canUploadTreatmentPlan && (
        <Stripe
          rightContent={
            <React.Fragment>
              <input
                type="file"
                id="file-input"
                accept="application/pdf"
                onChange={_onUploadTreatmentPlan}
                hidden
              />

              <Button
                appearance="primary"
                isLoading={isUploading}
                disabled={isUploading}
              >
                <label style={{ cursor: "pointer" }} htmlFor="file-input">
                  Upload Treatment Plan
                </label>
              </Button>
            </React.Fragment>
          }
        />
      )}

      {_.isEmpty(treatmentPlans) ? (
        <StateWrapper>
          <EmptyState />
        </StateWrapper>
      ) : (
        <Table data={tableData} header={tableHeaders} />
      )}

      <Dialog
        intent="danger"
        isShown={showDeleteConfirmation}
        title="Delete Treatment Plan"
        onConfirm={_onConfirmDeleteConfirmation}
        onCloseComplete={_onCloseDeleteConfirmation}
        onCancel={_onCancelDeleteConfirmation}
        isConfirmLoading={isDeleting}
        isConfirmDisabled={isDeleting}
        shouldCloseOnOverlayClick={!isDeleting}
        shouldCloseOnEscapePress={!isDeleting}
        hasClose={!isDeleting}
        hasCancel={!isDeleting}
        confirmLabel="Delete"
      >
        Are you sure you want to delete this treatment plan?
      </Dialog>
    </Wrapper>
  );
};

// Exports
export default TreatmentPlans;
