// Modules
import Styled from "styled-components";

// UI
import { Heading, Text } from "evergreen-ui";

// Theme
import Colors, { withAlpha } from "theming/colors";
import Spacing from "theming/spacing";

// Wrapper
export const Wrapper = Styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 42rem; 
  margin: auto;

  padding: ${Spacing.Space1x};
  padding-top: ${Spacing.Space3x};
  padding-bottom: 160px;
`;

// Section
export const Section = Styled.div`
  width: 100%;
  margin-bottom: ${Spacing.Space1x};
  margin-top: ${Spacing.Space1x};

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

// Header
export const Header = {
  Wrapper: Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  `,
  Details: Styled.div`
    display: flex;
    flex-direction: column;
  `,
  Actions: Styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `
};

// Invoice Components
export const InvoiceComponents = {
  Row: Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    background: ${Colors.White};
    border-radius: 4px;
    padding: 1rem;

    margin-top: ${Spacing.SpaceNx(0.3)};
    margin-bottom: ${Spacing.SpaceNx(0.3)};

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  `,
  Column: Styled.div`
    display: flex;
    flex-direction: column;
  `
};

// Error State Wrapper
export const ErrorStateWrapper = Styled.div`
  display: flex;
  flex-direction: column;
  
  align-items: center;
  justify-content: center;
  padding: ${Spacing.Space4x};
  width: 100%;

  button {
    width: 25%;
    margin-top: ${Spacing.Space1x};
  }
`;

// Spinner Wrapper
export const SpinnerWrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spacing.Space4x};
  width: 100%;
`;
