// Modules
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Services
import InsuranceService from "services/insurance.service";

// Get Insurances
export const getInsurances = createAsyncThunk(
  "insurance/getInsurances",
  async () => await InsuranceService.getInsurances()
);

// Initial State
const initialState = {
  insurances: null
};

// Arrange By Appointment Date
const arrangeByAppointmentDate = insurances => {
  return insurances.sort((a, b) => {
    return (
      new Date(b.patientInfo.appointmentDate) -
      new Date(a.patientInfo.appointmentDate)
    );
  });
};

// Slice
export const insurancesSlice = createSlice({
  name: "insurances",
  initialState,
  reducers: {
    updateInsurances: (state, action) => {
      state.insurances = arrangeByAppointmentDate(action.payload);
    }
  },
  extraReducers: {
    [getInsurances.fulfilled]: (state, action) => {
      state.insurances = arrangeByAppointmentDate(action.payload);
    }
  }
});

// Actions
export const { updateInsurances } = insurancesSlice.actions;

// Exports
export default insurancesSlice.reducer;
