// Modules
import Styled from "styled-components";

// Theme
import Colors from "theming/colors";
import Spacing from "theming/spacing";

// Wrapper
export const Wrapper = Styled.div`
  width: 100%; height: 70px;
  border-bottom: 1px solid ${Colors.Gray};
  background: ${Colors.White};
`;

// Inner Wrapper
export const InnerWrapper = {
  Main: Styled.div`
    display: flex;
    width: 100%; height: 100%;
    justify-content: center;
    align-items: center;
  `,
  Left: Styled.div`
    display: flex;
    align-items: center;
    width: 100%; height: 100%;
    border-right: 1px solid ${Colors.Gray};
    padding-right: ${Spacing.Space1x};
    padding-left: ${Spacing.Space1x};
    flex: 0 0 320px;
  `,
  Right: Styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: ${Spacing.Space1x};
    padding-left: ${Spacing.Space1x};
    width: 100%; height: 100%;
    flex: 1 1 auto;
  `
};

// Logo Wrapper
export const LogoWrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  width: 100%;
`;

// Page Title Wrapper
export const PageTitle = {
  Wrapper: Styled.div`
    padding-top: 3px;
  `,
  TitleWrapper: Styled.div`
    > * {
      color: ${Colors.Primary};
    }
  `,
  SubTitleWrapper: Styled.div``
};

// Mini Profile Wrapper
export const MiniProfileWrapper = Styled.div`
  display: flex;
  padding-top: 6px;
`;
