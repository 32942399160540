// Modules
import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

// Styles
import { Wrapper } from "./mini-profile.styles.js";

// UI
import { Avatar, Text } from "evergreen-ui";

// Component
export const MiniProfile = React.memo(() => {
  // Selectors
  const userReducer = useSelector(({ user }) => user);

  const { user } = userReducer;

  if (_.isEmpty(user)) {
    return null;
  }

  const name = `${user.firstName} ${user.lastName}`;

  return (
    <Wrapper.Main>
      <Wrapper.Left>
        <Text>{name}</Text>
      </Wrapper.Left>
      <Wrapper.Right>
        <Avatar shape="square" name={name} size={38} />
      </Wrapper.Right>
    </Wrapper.Main>
  );
});

// Exports
export default React.memo(MiniProfile);
