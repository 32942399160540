// Modules
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStripe, useElements } from "@stripe/react-stripe-js";

// Styles
import {
  Wrapper,
  Section,
  Header,
  InvoiceComponents,
  ErrorStateWrapper,
  SpinnerWrapper
} from "./invoices.styles";

// UI
import { Button, Heading, Text, Strong, Spinner } from "evergreen-ui";

// Redux
import {
  setBackgroundTint,
  setFluidLayout,
  setPageTitle
} from "redux/layout.slice";

// Shared
import ErrorState from "components/shared/error-state";

// Services
import BillingService from "services/billing.service";

// Component
export const Invoices = () => {
  // Hooks
  const dispatch = useDispatch();
  const elements = useElements();
  const stripe = useStripe();

  // Reducers
  const userReducer = useSelector(({ user }) => user);

  const { user, permissions } = userReducer;

  // State
  const [invoice, setInvoice] = useState(null);
  const [failedToFetchInvoice, setFailedToFetchInvoice] = useState(true);
  const [isFetchingInvoice, setIsFetchingInvoice] = useState(true);

  // Get Invoice
  const getInvoice = async () => {
    try {
      setIsFetchingInvoice(true);
      setFailedToFetchInvoice(false);

      const invoice = await BillingService.getInvoice();

      setInvoice(invoice);

      setIsFetchingInvoice(false);
      setFailedToFetchInvoice(false);
    } catch (exception) {
      setIsFetchingInvoice(false);
      setFailedToFetchInvoice(true);
    }
  };

  // On Pay
  const onPay = async event => {
    event.preventDefault();

    if (!elements) {
      return null;
    }

    await stripe.redirectToCheckout({
      submitType: "pay",
      clientReferenceId: user.id,
      customerEmail: user.emailAddress,
      cancelUrl: `${window.location.href}?state=CANCEL`,
      successUrl: `${window.location.href}?state=SUCCESS`,
      lineItems: [
        // Base Subscription
        { price: invoice?.basePlanId, quantity: 1 },

        // Instant Verified Forms
        {
          price: invoice?.instantVerificationPlanId,
          quantity: invoice?.instantVerifiedFormsCount
        }
      ],
      billingAddressCollection: "auto",
      mode: "payment"
    });
  };

  useEffect(() => {
    if (!permissions.canSeeInvoices) {
      return history.replace("/not-found");
    }

    dispatch(setFluidLayout(false));
    dispatch(setPageTitle({ title: "Invoices" }));
    dispatch(setBackgroundTint(true));

    getInvoice();
  }, []);

  if (isFetchingInvoice) {
    return (
      <Wrapper>
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      </Wrapper>
    );
  }

  if (failedToFetchInvoice) {
    return (
      <ErrorStateWrapper>
        <ErrorState />
        <Button appearance="primary" onClick={getInvoice}>
          Retry
        </Button>
      </ErrorStateWrapper>
    );
  }

  const instantVerificationsCostTotal =
    invoice?.singleInstantVerificationCost *
      invoice?.instantVerifiedFormsCount || 0;

  const totalBill = invoice?.subscriptionFee + instantVerificationsCostTotal;

  return (
    <Wrapper>
      {/* Header */}
      <Section>
        <Header.Wrapper>
          <Header.Details>
            <Heading>Invoices</Heading>
            <Text>Billing at a glance.</Text>
          </Header.Details>
        </Header.Wrapper>
      </Section>

      {/* Invoice Components */}
      <Section>
        <InvoiceComponents.Row>
          <InvoiceComponents.Column>
            <Text>Subscription</Text>
            <Text size={300} color="muted">
              {invoice?.subscriptionName}
            </Text>
          </InvoiceComponents.Column>
          <InvoiceComponents.Column>
            <Text>
              {invoice?.currency} {invoice?.subscriptionFee}
            </Text>
          </InvoiceComponents.Column>
        </InvoiceComponents.Row>
        <InvoiceComponents.Row>
          <InvoiceComponents.Column>
            <Text>Instant Vertifications</Text>
            <Text size={300} color="muted">
              Billed at {invoice?.currency}{" "}
              {invoice?.singleInstantVerificationCost} per verification
            </Text>
          </InvoiceComponents.Column>
          <InvoiceComponents.Column>
            <Text>
              {invoice?.currency} {instantVerificationsCostTotal}
            </Text>
          </InvoiceComponents.Column>
        </InvoiceComponents.Row>
        <InvoiceComponents.Row>
          <Strong>Total</Strong>
          <Strong>
            {invoice?.currency} {totalBill}
          </Strong>
        </InvoiceComponents.Row>
      </Section>

      <Section>
        <Button
          type="submit"
          appearance="primary"
          disabled={!stripe || !elements}
          onClick={onPay}
        >
          Pay {invoice?.currency} {totalBill}
        </Button>
      </Section>
    </Wrapper>
  );
};

// Exports
export default Invoices;
