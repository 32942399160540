// Modules
import Styled from "styled-components";

// Theme
import Colors, { withAlpha } from "theming/colors";
import Spacing from "theming/spacing";

// Wrapper
export const Wrapper = Styled.div`
  padding-bottom: 70px;
`;

// Section
export const Section = {
  Wrapper: Styled.div`
    padding: ${Spacing.Space1x};
    border-bottom: 1px dashed ${Colors.Gray};
    background: ${Colors.White};
  `,
  Header: {
    Wrapper: Styled.div`
      display: flex;
      justify-content: space-between;
      margin-bottom: ${Spacing.Space1x};
    `,
    Left: Styled.div`
      flex: 1;
    `,
    Right: Styled.div`
      flex: 0;
    `,
    TitleWrapper: Styled.div`
      margin-bottom: 4px;
    `,
    TextWrapper: Styled.div``,
    Actions: Styled.div``
  },
  Content: Styled.div``
};

// Spinner Wrapper
export const SpinnerWrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spacing.Space4x};
  width: 100%;
`;

// Open Dental
export const OpenDental = {
  Wrapper: Styled.div``,
  Row: Styled.div`
    margin-top: ${Spacing.Space1x};
    margin-bottom: ${Spacing.Space1x};

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `
};
