// Modules
import Styled from "styled-components";

// Theme
import Colors, { withAlpha } from "theming/colors";

// Wrapper
export const Wrapper = Styled.div`
  display: flex;
  width: 100vw; height: 100vh;
  overflow: hidden;
`;

// Side Bar
export const SidebarWrapper = Styled.div`
  flex: 0 0 320px;
  width: 100%; height: 100%;
  overflow: hidden;
`;

// Content Wrapper
export const ContentWrapper = Styled.div`
  width: 100%; height: 100%;
  flex: 1 1 auto;
`;

// Page Layout
export const PageLayout = {
  Wrapper: Styled.div`
    display: flex;
    width: 100%; height: 100%;
    flex-direction: column;

    background: ${props => {
      if (props.$isTinted) {
        return withAlpha(Colors.Gray, 0.65);
      }

      return Colors.White;
    }};
  `,
  NavigationWrapper: Styled.div`
    width: 100%; height: 100%;
    flex: 0 0 70px;
  `,
  RoutesWrapper: Styled.div`
    flex: 1 1 auto;
    width: 100%; height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  `
};
