// Modules
import Styled from "styled-components";

// UI
import { Heading, Text } from "evergreen-ui";

// Theme
import Colors from "theming/colors";
import Spacing from "theming/spacing";

// Wrapper
export const Wrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: ${Spacing.Space1x};
  padding-top: ${Spacing.Space3x};
  padding-bottom: 160px;
`;

// Section
export const Section = Styled.div`
  margin-bottom: ${Spacing.Space1x};
  margin-top: ${Spacing.Space1x};

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

// Greeting
export const Greeting = {
  Wrapper: Styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  HeadingWrapper: Styled.div`
    margin-bottom: 4px;
  `,
  TextWrapper: Styled.div``
};

// Details
export const Details = {
  Wrapper: Styled.div`
    padding: ${Spacing.SpaceNx(0.25)};
    margin: -${Spacing.SpaceNx(0.25)};
    max-width: 720px; 
  `,
  Blocks: Styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: ${Colors.White};
    border: 1px solid ${Colors.Gray};
    border-right: 0;
    border-radius: 4px;
    width: 100%;
  `,
  Block: Styled.div`
    flex: 1 1 33.33%;
    border-bottom: 1px solid ${Colors.Gray};
    border-right: 1px solid ${Colors.Gray};
    width: 33.33%;
  `,
  TileWrapper: Styled.div`
    padding: ${Spacing.SpaceNx(1)};
  `,
  Label: Styled(Heading)`
    font-size: 90%;
  `,
  Value: Styled(Text)``
};

// Tiles
export const Tiles = {
  Wrapper: Styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: -${Spacing.SpaceNx(0.25)};
    max-width: 720px;
  `,
  TileWrapper: Styled.div`
    width: 50%;
    padding: ${Spacing.SpaceNx(0.25)};
  `
};

// State Wrapper
export const StateWrapper = Styled.div``;

// Providers
export const Providers = {
  Wrapper: Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Label: Styled.div`
    margin-bottom: ${Spacing.Space1x};
  `,
  Images: Styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
  `,
  Image: Styled.img`
    height: 72px;
    margin-right: ${Spacing.SpaceNx(1.25)};
    margin-left: ${Spacing.SpaceNx(1.25)};
  `
};
