// Modules

// Services
import Http from "services/http.service";

// Get Invoice
export const getInvoice = async () => {
  try {
    const response = await Http.get(`/billing/invoice`);

    return response.data;
  } catch (exception) {
    return Promise.reject(exception);
  }
};

// Exports
export default {
  getInvoice
};
