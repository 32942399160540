// Modules

// Services
import Http from "services/http.service";

// Get Schema
export const getSchema = async () => {
  const response = await Http.get("/schema");

  return response.data;
};

// Get Schema By Owner
export const getSchemaByOwner = async schemaOwnerId => {
  const response = await Http.get(`/schema/owner/${schemaOwnerId}`);

  return response.data;
};

// Update Schema
export const updateSchema = async ({ schemaId, changes }) => {
  const response = await Http.patch(`/schema/${schemaId}`, changes);

  return response.data;
};

// Exports
export default {
  getSchema,
  getSchemaByOwner,
  updateSchema
};
