// Modules
import { defaultTheme, mergeTheme } from "evergreen-ui";

// Theme
export const appTheme = mergeTheme(defaultTheme, {
  components: {
    // Button
    Button: {
      appearances: {
        primary: {
          color: "#ffffff",
          backgroundColor: "#75CAAA",
          _hover: {
            backgroundColor: "#52BD95"
          },
          _active: {
            backgroundColor: "#429777"
          },
          _focus: {
            boxShadow: "0 0 0 2px #BAE5D5"
          },
          _disabled: {
            opacity: 0.5
          }
        }
      },
      baseStyle: {
        color: "#ffffff",
        backgroundColor: "#75CAAA",
        _hover: {
          backgroundColor: "#75CAAA"
        },
        _active: {
          backgroundColor: "#429777"
        },
        _focus: {
          boxShadow: "0 0 0 2px #BAE5D5"
        },
        _disabled: {
          opacity: 0.5
        }
      }
    },

    // Link
    Link: {
      baseStyle: {
        color: "#75CAAA",
        _hover: {
          color: "#52BD95"
        },
        _active: {
          color: "#429777"
        }
      }
    }
  }
});
