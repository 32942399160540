// Modules
import Styled from "styled-components";

// Theme
import Colors, { withAlpha } from "theming/colors";
import Spacing from "theming/spacing";

// Header
export const Header = Styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${withAlpha(Colors.Gray, 0.25)};
  padding: ${Spacing.SpaceNx(0.9)} ${Spacing.Space1x};
  backdrop-filter: blur(4px);
  margin-bottom: 1px;
  position: sticky;
  top: 0;
`;

// Table Wrapper
export const TableWrapper = Styled.div`
  margin: -1px;
`;
