// Modules
import Moment from "moment";
import { customAlphabet } from "nanoid";
import _ from "lodash";

// Defaults
export const DateFormatShort = "YYYY-MM-DD";
export const DateFormatLong = "MMM. DD, YYYY";

// Diff
export const diff = (object, base) => {
  function changes(object, base) {
    return _.transform(object, (result, value, key) => {
      if (_.isEqual(value, base[key])) {
        return undefined;
      }

      if (_.isObject(value) && _.isObject(base[key])) {
        result[key] = changes(value, base[key]);
      } else {
        result[key] = value;
      }
    });
  }

  return changes(object, base);
};

// Format Date
export const formatDate = (date, format? = DateFormatShort) => {
  return Moment(date).format(format);
};

// New Random Number
export const newRandomNumber = (upto = 999999) => {
  return Math.floor(Math.random() * upto);
};

// New Unique ID
export const newUniqueId = (length = 18) => {
  const includeInString = "abcdefghijklmnopqrstuvwxyz";

  return customAlphabet(includeInString, length)();
};

// UTC Time Stamp
export const newUtcTimestamp = () => {
  return Moment.utc().format("X");
};

// Exports
export default {
  diff,
  formatDate,
  newRandomNumber,
  newUniqueId,
  newUtcTimestamp
};
