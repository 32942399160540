// Modules
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

// Styles
import {
  Wrapper,
  Section,
  Greeting,
  Details,
  Tiles,
  Providers,
  StateWrapper
} from "./dashboard.styles.js";

// UI
import { Heading, Text, Spinner, toaster } from "evergreen-ui";

// Assets
import { USER_ROLE_OPTIONS } from "common/constants";

// Redux
import {
  setBackgroundTint,
  setFluidLayout,
  setPageTitle
} from "redux/layout.slice";

// Elements
import Tile from "./tile";

// Services
import InsuranceService from "services/insurance.service.js";

// Images
import CignaImage from "./images/cigna.svg";
import HumanaImage from "./images/humana.svg";
import RenaissanceImage from "./images/renaissance.svg";
import SpiritImage from "./images/spirit.svg";

// Component
export const Dashboard = () => {
  // Hooks
  const dispatch = useDispatch();

  // Selectors
  const userReducer = useSelector(({ user }) => user);

  const { user } = userReducer;

  // State
  const [statistics, setStatistics] = useState(null);
  const [isFetchingStats, setIsFetchingStats] = useState(true);

  // Get Statistics
  const _getStatistics = async () => {
    try {
      setIsFetchingStats(true);

      const statistics = await InsuranceService.getStatistics();

      setStatistics(statistics);
      setIsFetchingStats(false);
    } catch (exception) {
      setIsFetchingStats(false);

      toaster.danger("Something went wrong!", {
        duration: 60000,
        description: exception.uiMessage
      });
    }
  };

  // Greet
  const _greet = () => {
    const today = new Date();
    const hourNow = today.getHours();

    if (hourNow > 18) {
      return "Good evening";
    }

    if (hourNow > 12) {
      return "Good afternoon";
    }

    if (hourNow > 0) {
      return "Good morning";
    }

    return "Hi there";
  };

  // Render Header
  const _renderHeader = () => {
    return (
      <Greeting.Wrapper>
        <Greeting.HeadingWrapper>
          <Heading size={700}>
            {_greet()}, {user?.firstName}!
          </Heading>
        </Greeting.HeadingWrapper>
        <Greeting.TextWrapper>
          <Text>Here's an overview of your work.</Text>
        </Greeting.TextWrapper>
      </Greeting.Wrapper>
    );
  };

  useEffect(() => {
    dispatch(setFluidLayout(false));
    dispatch(setPageTitle({ title: "Dashboard" }));
    dispatch(setBackgroundTint(true));

    // Get Statistics
    _getStatistics();
  }, []);

  const name = `${user.firstName} ${user.lastName}`;
  const address = `${user.address} - ${user.zipCode}`;

  const userRole = _.find(USER_ROLE_OPTIONS, x => _.isEqual(x.id, user.role));

  if (isFetchingStats) {
    return (
      <Wrapper>
        {/* Greeting */}
        <Section>{_renderHeader()}</Section>

        {/* Spinner */}
        <Section>
          <StateWrapper>
            <Spinner />
          </StateWrapper>
        </Section>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {/* Greeting */}
      <Section>{_renderHeader()}</Section>

      {/* Office Details */}
      <Section>
        <Details.Wrapper>
          <Details.Blocks>
            <Details.Block>
              <Details.TileWrapper>
                <Details.Label>Name</Details.Label>
                <Details.Value>{name}</Details.Value>
              </Details.TileWrapper>
            </Details.Block>
            <Details.Block>
              <Details.TileWrapper>
                <Details.Label>Practice Name</Details.Label>
                <Details.Value>{user.practiceName || "-"}</Details.Value>
              </Details.TileWrapper>
            </Details.Block>
            <Details.Block>
              <Details.TileWrapper>
                <Details.Label>Tax ID</Details.Label>
                <Details.Value>{user.taxId || "-"}</Details.Value>
              </Details.TileWrapper>
            </Details.Block>
            <Details.Block>
              <Details.TileWrapper>
                <Details.Label>Phone Number</Details.Label>
                <Details.Value>{user.mobileNumber || "-"}</Details.Value>
              </Details.TileWrapper>
            </Details.Block>
            <Details.Block>
              <Details.TileWrapper>
                <Details.Label>Fax Number</Details.Label>
                <Details.Value>{user.faxNumber || "-"}</Details.Value>
              </Details.TileWrapper>
            </Details.Block>
            <Details.Block>
              <Details.TileWrapper>
                <Details.Label>Email Address</Details.Label>
                <Details.Value>{user.emailAddress || "-"}</Details.Value>
              </Details.TileWrapper>
            </Details.Block>
            <Details.Block>
              <Details.TileWrapper>
                <Details.Label>NPI #</Details.Label>
                <Details.Value>{user.npiNumber || "-"}</Details.Value>
              </Details.TileWrapper>
            </Details.Block>
            <Details.Block>
              <Details.TileWrapper>
                <Details.Label>Provider Name</Details.Label>
                <Details.Value>{user.providerName || "-"}</Details.Value>
              </Details.TileWrapper>
            </Details.Block>
            <Details.Block>
              <Details.TileWrapper>
                <Details.Label>Role</Details.Label>
                <Details.Value>{userRole.name || "-"}</Details.Value>
              </Details.TileWrapper>
            </Details.Block>
            <Details.Block>
              <Details.TileWrapper>
                <Details.Label>Address</Details.Label>
                <Details.Value>{address}</Details.Value>
              </Details.TileWrapper>
            </Details.Block>
          </Details.Blocks>
        </Details.Wrapper>
      </Section>

      {/* Tiles */}
      {!isFetchingStats && (
        <Section>
          <Tiles.Wrapper>
            <Tiles.TileWrapper>
              <Tile
                linkTo={`/insurances?filterDate=${statistics?.monday?.date}`}
                label="Monday"
                count={statistics?.monday?.count}
                color="#caffbf"
              />
            </Tiles.TileWrapper>
            <Tiles.TileWrapper>
              <Tile
                linkTo={`/insurances?filterDate=${statistics?.tuesday?.date}`}
                label="Tuesday"
                count={statistics?.tuesday?.count}
                color="#ffadad"
              />
            </Tiles.TileWrapper>
            <Tiles.TileWrapper>
              <Tile
                linkTo={`/insurances?filterDate=${statistics?.wednesday?.date}`}
                label="Wednesday"
                count={statistics?.wednesday?.count}
                color="#a0c4ff"
              />
            </Tiles.TileWrapper>
            <Tiles.TileWrapper>
              <Tile
                linkTo={`/insurances?filterDate=${statistics?.thursday?.date}`}
                label="Thursday"
                count={statistics?.thursday?.count}
                color="#ffd6a5"
              />
            </Tiles.TileWrapper>
            <Tiles.TileWrapper>
              <Tile
                linkTo={`/insurances?filterDate=${statistics?.friday?.date}`}
                label="Friday"
                count={statistics?.friday?.count}
                color="#ffc6ff"
              />
            </Tiles.TileWrapper>
            <Tiles.TileWrapper>
              <Tile
                linkTo="/insurances?filterType=Instant%20Verified"
                label="Total Instant Verified"
                count={statistics?.totalInstantVerifiedCount}
                color="#bdb2ff"
              />
            </Tiles.TileWrapper>
          </Tiles.Wrapper>
        </Section>
      )}

      {/* Dental Providers Images */}
      <Section>
        <Providers.Wrapper>
          <Providers.Label>
            <Text color="muted">Supported Providers</Text>
          </Providers.Label>
          <Providers.Images>
            <Providers.Image src={CignaImage} />
            <Providers.Image src={HumanaImage} />
            <Providers.Image src={RenaissanceImage} />
            <Providers.Image src={SpiritImage} />
          </Providers.Images>
        </Providers.Wrapper>
      </Section>
    </Wrapper>
  );
};

// Exports
export default Dashboard;
