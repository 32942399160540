// Modules
import React from "react";

// Styles
import { Wrapper, LeftWrapper, RightWrapper } from "./stripe.styles.js";

// Component
export const Stripe = ({ isSticky, leftContent, rightContent }) => {
  return (
    <Wrapper $isSticky={isSticky}>
      <LeftWrapper>{leftContent}</LeftWrapper>
      <RightWrapper>{rightContent}</RightWrapper>
    </Wrapper>
  );
};

// Exports
export default React.memo(Stripe);
