// Modules
import _ from "lodash";

// Services
import Http from "services/http.service";

// Construct Address
const constructAddress = patient => {
  const addressComponents = [patient.address, patient.city, patient.state];

  return addressComponents.filter(item => (item ? item : null)).join(", ");
};

// Converter
const converter = patient => ({
  ...patient,
  address: constructAddress(patient)
});

// Get Appointments
export const getAppointments = async (startDate, endDate) => {
  const response = await Http.get(
    `/integrations/opendental/appointments?startDate=${startDate}&endDate=${endDate}`
  );

  return _.map(response.data, converter);
};

// Exports
export default {
  getAppointments
};
