// Modules
import Styled from "styled-components";

// Theme
import Colors, { withAlpha } from "theming/colors";
import Spacing from "theming/spacing";

// Wrapper
export const Wrapper = Styled.div`
  padding-bottom: 70px;
`;

// State Wrapper
export const StateWrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spacing.Space4x};
  width: 100%;
`;
