// Modules
import Styled from "styled-components";

// Theme
import Colors, { withAlpha } from "theming/colors";
import Spacing from "theming/spacing";

// Wrapper
export const Wrapper = Styled.div`
  padding-bottom: 70px;
`;

// Section
export const Section = {
  Wrapper: Styled.div`
    padding: ${Spacing.Space1x};
    border-bottom: 1px dashed ${Colors.Gray};
    background: ${Colors.White};
  `,
  Header: {
    Wrapper: Styled.div`
      margin-bottom: ${Spacing.Space1x};
    `,
    TitleWrapper: Styled.div`
      margin-bottom: 4px;
    `,
    TextWrapper: Styled.div``
  },
  Content: Styled.div``
};

// Spinner Wrapper
export const SpinnerWrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spacing.Space4x};
  width: 100%;
`;
