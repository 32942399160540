// Modules
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import Moment from "moment";

// Styles
import { Wrapper, StateWrapper } from "./insurances.styles.js";

// UI
import {
  Badge,
  Button,
  IconButton,
  Popover,
  Menu,
  SearchInput,
  Spinner,
  PlusIcon,
  Position,
  Tooltip,
  Strong,
  ThFilteredIcon,
  FilterListIcon,
  DownloadIcon,
  DiagnosisIcon,
  Icon,
  DeleteIcon,
  TickCircleIcon,
  HandIcon,
  toaster,
  CalendarIcon
} from "evergreen-ui";

// Redux
import {
  setBackgroundTint,
  setFluidLayout,
  setPageTitle
} from "redux/layout.slice";
import { getInsurances } from "redux/insurances.slice";

// Assets
import { DateFormatLong } from "common/utilities";

// Shared
import Table from "components/shared/table";
import DateRangeSelector from "components/shared/date-range-selector";
import ErrorState from "components/shared/error-state";
import EmptyState from "components/shared/empty-state";
import Stripe from "components/shared/stripe";

// Services
import StorageService from "services/storage.service";

// Component
export const Insurances = () => {
  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();

  // Selectors
  const userReducer = useSelector(({ user }) => user);
  const insurancesReducer = useSelector(({ insurances }) => insurances);

  const { permissions } = userReducer;
  const { insurances } = insurancesReducer;

  // Insurance States
  const insuranceStates = {
    active: true,
    inactive: false,
    onHold: "on-hold"
  };

  // Filter Types
  const FilterTypes = {
    All: "All",
    Verified: "Verified",
    InstantVerified: "Instant Verified",
    Unverified: "Unverified",
    InstantUnverified: "Instant Unverified",
    Active: "Active",
    Inactive: "Inactive",
    OnHold: "On Hold",
    NewPatient: "New Patient",
    Recall: "Recall"
  };

  // Filterable Columns
  const FilterableColumns = [
    {
      id: "patientInfo.patientFirstName",
      label: "Patient First Name"
    },
    {
      id: "patientInfo.patientLastName",
      label: "Patient Last Name"
    },
    {
      id: "createdBy.name",
      hidden: !permissions.canSeeReqDentalOfficeColumn,
      label: "Req. Dental Office"
    },
    {
      id: "createdBy.taxId",
      hidden: !permissions.canSeeReqDentalOfficeColumn,
      label: "Req. Dental Office Tax ID"
    },
    {
      id: "patientInfo.insuranceCompanyName",
      label: "Insurance Company"
    }
  ];

  // State
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredInsurances, setFilteredInsurances] = useState(null);
  const [isFetchingInsurances, setIsFetchingInsurances] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(FilterTypes.All);
  const [dentalOffices, setDentalOffices] = useState([]);
  const [selectedDentalOffice, setSelectedDentalOffice] = useState(null);
  const [selectedFilterDateRange, setSelectedFilterDateRange] = useState([]);
  const [filterableColumn, setFilterableColumn] = useState(
    FilterableColumns[0]
  );
  const [hasFailedToFetchInsurances, setHasFailedToFetchInsurances] = useState(
    false
  );

  // Get Insurances
  const _getInsurances = async () => {
    try {
      setIsFetchingInsurances(true);

      if (insurances) {
        // Update insurances asyncrhonously
        dispatch(getInsurances());
      } else {
        // Get insurances syncrhonously
        await dispatch(getInsurances());
      }

      setHasFailedToFetchInsurances(false);
      setIsFetchingInsurances(false);
    } catch (exception) {
      setHasFailedToFetchInsurances(true);

      toaster.danger(exception.uiMessage, {
        duration: 60000
      });
    }
  };

  // On Filter Insurances
  const _onFilterInsurances = () => {
    let updatedFilteredInsurances = insurances;

    // Search Filter
    if (searchTerm) {
      updatedFilteredInsurances = _.filter(updatedFilteredInsurances, item => {
        if (_.includes(filterableColumn.id, ".")) {
          const splitColumnId = _.split(filterableColumn.id, ".");

          return _.includes(
            _.toLower(item[splitColumnId[0]][splitColumnId[1]]),
            _.toLower(searchTerm)
          );
        }

        return _.includes(
          _.toLower(item[filterableColumn.id]),
          _.toLower(searchTerm)
        );
      });
    }

    // Filter: All Insurances
    if (currentFilter === FilterTypes.All) {
      updatedFilteredInsurances = updatedFilteredInsurances;
    }

    // Filter: Verified Insurances
    if (currentFilter === FilterTypes.Verified) {
      updatedFilteredInsurances = _.filter(updatedFilteredInsurances, x => {
        return x.isVerified;
      });
    }

    // Filter: Instant Verified Insurances
    if (currentFilter === FilterTypes.InstantVerified) {
      updatedFilteredInsurances = _.filter(updatedFilteredInsurances, x => {
        return x.isVerifiedInstantly;
      });
    }

    // Filter: Unverified Insurances
    if (currentFilter === FilterTypes.Unverified) {
      updatedFilteredInsurances = _.filter(updatedFilteredInsurances, x => {
        return (
          !x.isVerified &&
          !x.isVerifiedInstantly &&
          !x.requiresInstantVerification
        );
      });
    }

    // Filter: Instant Unverified Insurances
    if (currentFilter === FilterTypes.InstantUnverified) {
      updatedFilteredInsurances = _.filter(updatedFilteredInsurances, x => {
        // prettier-ignore
        return !x.isVerified && !x.isVerifiedInstantly && x.requiresInstantVerification
      });
    }

    // Filter: Active Insurances
    if (currentFilter === FilterTypes.Active) {
      updatedFilteredInsurances = _.filter(updatedFilteredInsurances, x => {
        return x.isActive === insuranceStates.active;
      });
    }

    // Filter: Inactive Insurances
    if (currentFilter === FilterTypes.Inactive) {
      updatedFilteredInsurances = _.filter(updatedFilteredInsurances, x => {
        return x.isActive === insuranceStates.inactive;
      });
    }

    // Filter: On Hold
    if (currentFilter === FilterTypes.OnHold) {
      updatedFilteredInsurances = _.filter(updatedFilteredInsurances, x => {
        return x.isActive === insuranceStates.onHold;
      });
    }

    // Filter: New Patient
    if (currentFilter === FilterTypes.NewPatient) {
      updatedFilteredInsurances = _.filter(updatedFilteredInsurances, x => {
        const {
          patientInfo: { patientType }
        } = x;

        if (!patientType) {
          return x;
        }

        return _.isEqual(patientType, "NEW");
      });
    }

    // Filter: Recall Patient
    if (currentFilter === FilterTypes.Recall) {
      updatedFilteredInsurances = _.filter(updatedFilteredInsurances, x => {
        const {
          patientInfo: { patientType }
        } = x;

        return _.isEqual(patientType, "RECALL");
      });
    }

    // Filter: Appointment Date
    if (selectedFilterDateRange?.[0] || selectedFilterDateRange?.[1]) {
      updatedFilteredInsurances = _.filter(updatedFilteredInsurances, item => {
        const { patientInfo } = item;

        if (patientInfo.appointmentDate) {
          if (
            // prettier-ignore
            Moment(patientInfo.appointmentDate).isSameOrAfter(Moment(selectedFilterDateRange?.[0])) &&
            // prettier-ignore
            Moment(patientInfo.appointmentDate).isSameOrBefore(Moment(selectedFilterDateRange?.[1]))
          ) {
            return item;
          }
        }
      });
    }

    setFilteredInsurances(updatedFilteredInsurances);
  };

  // On Change Filterable Column
  const _onChangeFitlerableColumn = filterableColumn => {
    setFilterableColumn(filterableColumn);
  };

  // On Search Insurances
  const _onSearchInsurances = event => {
    const { value: query } = event.target;

    setSearchTerm(query);
  };

  // On Change Filter
  const _onChangeFilter = showing => {
    setCurrentFilter(showing);
  };

  // On Filter By Appointment Date
  const _onFilterByAppointmentDate = range => {
    setSelectedFilterDateRange(range);
  };

  // To Create Insurance
  const _toCreateInsurance = () => {
    history.push("/insurances/create");
  };

  // Render Status Badge
  const _renderStatusBadge = item => {
    const {
      requiresInstantVerification,
      isVerifiedInstantly,
      isVerified,
      verifiedAt: isInsuranceVerified
    } = item;

    // Verified
    if (isInsuranceVerified) {
      if (isVerifiedInstantly) {
        return <Badge color="green">Verified Instantly</Badge>;
      }
      if (isVerified) {
        return <Badge color="teal">Verified</Badge>;
      }
    }

    // Unverified
    if (!isVerifiedInstantly && !isVerified) {
      if (!isVerifiedInstantly && requiresInstantVerification) {
        return <Badge color="red">Instant Unverified</Badge>;
      }

      return <Badge>Unverified</Badge>;
    }
  };

  // Render Item State
  const _renderItemState = item => {
    const { isActive } = item;

    let color = isActive ? "success" : "danger";
    let icon = isActive ? TickCircleIcon : DeleteIcon;
    let state = isActive ? "Active" : "Inactive";

    if (isActive === insuranceStates.onHold) {
      state = "On Hold";
      color = "warning";
      icon = HandIcon;
    }

    return (
      <Tooltip position={Position.RIGHT} content={state}>
        <Icon icon={icon} color={color} />
      </Tooltip>
    );
  };

  // On Click Item
  const _onClickItem = insuranceId => {
    history.push(`/insurances/${insuranceId}`);
  };

  // Get Insurances Filtered By Dental Office
  const _getInsurancesFilteredByDentalOffice = (dentalOfficeId, insurances) => {
    if (!dentalOfficeId) {
      return insurances;
    }

    return _.filter(insurances, insurance => {
      return insurance.createdBy?.id === dentalOfficeId;
    });
  };

  // Get Filtered Insurances CSV
  const _getFilteredInsurancesCsv = () => {
    const headers = [
      "State",
      "Patient Name",
      "NPI",
      "Insurance Company Name",
      "Req. Dental Office",
      "Req. Dental Office Tax ID",
      "Req. Date",
      "Appointment Date",
      "Patient Type",
      "Status"
    ];

    const finalisedInsurances = _getInsurancesFilteredByDentalOffice(
      selectedDentalOffice?.id,
      filteredInsurances
    );

    const rows = _.map(finalisedInsurances, item => {
      const row = [];

      const {
        createdBy,
        patientInfo,
        requiresInstantVerification,
        isVerifiedInstantly,
        isVerified,
        verifiedAt: isInsuranceVerified
      } = item;

      const name = `${patientInfo.patientFirstName} ${patientInfo.patientLastName}`;

      let insuranceStatus = "Unverified";

      // Verified
      if (isInsuranceVerified) {
        if (isVerifiedInstantly) {
          insuranceStatus = "Verified Instantly";
        }
        if (isVerified) {
          insuranceStatus = "Verified";
        }
      }

      // Unverified
      if (!isVerifiedInstantly && !isVerified) {
        if (!isVerifiedInstantly && requiresInstantVerification) {
          insuranceStatus = "Instant Unverified";
        }
      }

      row.push(item.isActive ? "Active" : "Inactive");
      row.push(name);
      row.push(createdBy?.npiNumber || "-");
      row.push(patientInfo.insuranceCompanyName);
      row.push(createdBy?.name || "-");
      row.push(createdBy?.taxId || "-");
      row.push(Moment.unix(item.createdOn).format(DateFormatLong));
      row.push(Moment(patientInfo.appointmentDate).format(DateFormatLong));
      row.push(patientInfo.patientType === "RECALL" ? "Recall" : "New");
      row.push(insuranceStatus);

      return row;
    });

    return [headers, ...rows];
  };

  // On Select Dental Office
  const _onSelectDentalOffice = dentalOffice => {
    StorageService.setInLocalStorage(
      "selectedDentalOfficeId",
      dentalOffice?.id
    );

    setSelectedDentalOffice(dentalOffice);
  };

  useEffect(() => {
    if (!permissions.canSeeInsurances) {
      return history.replace("/not-found");
    }

    dispatch(setFluidLayout(false));
    dispatch(setPageTitle({ title: "Insurances" }));
    dispatch(setBackgroundTint(true));

    // Get Insurances
    _getInsurances();
  }, []);

  useEffect(() => {
    _onFilterInsurances();
  }, [searchTerm, currentFilter, selectedFilterDateRange]);

  useEffect(() => {
    setFilteredInsurances(insurances);

    if (permissions.canFilterInsurancesByDentalOffice) {
      const dentalOffices = _.uniqBy(
        _.map(insurances, insurance => ({
          id: insurance?.createdBy?.id,
          name: insurance?.createdBy?.name
        })),
        "name"
      );

      // Get selected dental office from storage
      const selectedDentalOfficeId = StorageService.getFromLocalStorage(
        "selectedDentalOfficeId"
      );

      if (selectedDentalOfficeId) {
        const dentalOffice = _.find(dentalOffices, office => {
          return office.id === selectedDentalOfficeId;
        });

        // eslint-disable-next-line max-depth
        if (dentalOffice) {
          setSelectedDentalOffice(dentalOffice);
        }
      }

      setDentalOffices(dentalOffices);
    }
  }, [insurances]);

  useEffect(() => {
    if (!insurances) {
      return;
    }

    const params = new URLSearchParams(window.location.search);

    const filterType = params.get("filterType");
    const filterDate = params.get("filterDate");

    if (filterType) {
      _onChangeFilter(filterType);
    }
    if (filterDate) {
      const convertedDate = Moment(filterDate).toDate();

      _onFilterByAppointmentDate([convertedDate, convertedDate]);
    }
  }, [window.location.search, insurances]);

  const finalisedInsurances = _getInsurancesFilteredByDentalOffice(
    selectedDentalOffice?.id,
    filteredInsurances
  );

  const tableHeaders = [
    {
      id: "thState",
      width: 32
    },
    {
      id: "thPatientName",
      label: "Patient Name"
    },
    {
      id: "thNpi",
      label: "NPI #"
    },
    {
      id: "thInsuranceCompanyName",
      label: "Ins. Company"
    },
    {
      id: "thReqDentalOffice",
      label: "Req. Dental Office",
      hidden: !permissions.canSeeReqDentalOfficeColumn,
      width: 320
    },
    {
      id: "thReqDentalOfficeTaxId",
      label: "Req. Tax ID",
      hidden: !permissions.canSeeReqDentalOfficeColumn
    },
    {
      id: "thReqDate",
      label: "Req. Date"
    },
    {
      id: "thAppointmentDate",
      label: "Appointment Date"
    },
    {
      id: "thPatientType",
      label: "Patient Type",
      width: 140
    },
    {
      id: "thStatus",
      label: "Status"
    }
  ];
  const tableData = _.map(finalisedInsurances, item => {
    const { createdBy, patientInfo } = item;

    const name = `${patientInfo.patientFirstName} ${patientInfo.patientLastName}`;

    return {
      id: item.id,
      fields: [
        { thId: "thState", value: () => _renderItemState(item), width: 32 },
        { thId: "thPatientName", value: name },
        {
          thId: "thNpi",
          value: createdBy?.npiNumber
        },
        { thId: "thCompany", value: patientInfo.insuranceCompanyName || "-" },
        {
          thId: "thReqDentalOffice",
          value: createdBy?.name || "-",
          hidden: !permissions.canSeeReqDentalOfficeColumn,
          width: 320
        },
        {
          thId: "thReqDentalOfficeTaxId",
          value: createdBy?.taxId || "-",
          hidden: !permissions.canSeeReqDentalOfficeColumn
        },
        {
          thId: "thReqDate",
          value: Moment.unix(item.createdOn).format(DateFormatLong)
        },
        {
          thId: "thAppointmentDate",
          value: patientInfo.appointmentDate
            ? Moment(patientInfo.appointmentDate).format(DateFormatLong)
            : "-"
        },
        {
          thId: "thPatientType",
          value: patientInfo.patientType === "RECALL" ? "Recall" : "New",
          width: 140
        },
        { thId: "thStatus", value: () => _renderStatusBadge(item) }
      ]
    };
  });

  if (isFetchingInsurances) {
    return (
      <Wrapper>
        <StateWrapper>
          <Spinner />
        </StateWrapper>
      </Wrapper>
    );
  }

  if (hasFailedToFetchInsurances) {
    return (
      <Wrapper>
        <StateWrapper>
          <ErrorState />
        </StateWrapper>
      </Wrapper>
    );
  }

  const shouldShowDownloadListButton =
    permissions.canDownloadFilteredInsurances && filteredInsurances?.length;

  return (
    <Wrapper>
      <Stripe
        leftContent={
          <React.Fragment>
            <Popover
              position={Position.BOTTOM_LEFT}
              content={
                <Menu>
                  <Menu.Group>
                    {_.map(FilterableColumns, x => {
                      if (x.hidden) {
                        return null;
                      }

                      return (
                        <Menu.Item
                          key={x.id}
                          onSelect={() => _onChangeFitlerableColumn(x)}
                        >
                          Search by <Strong>{x.label}</Strong>
                        </Menu.Item>
                      );
                    })}
                  </Menu.Group>
                </Menu>
              }
            >
              <IconButton marginRight={12} icon={FilterListIcon} />
            </Popover>

            <SearchInput
              onChange={_onSearchInsurances}
              placeholder={`Search by ${filterableColumn?.label}`}
              marginRight={12}
            />

            {permissions.canFilterInsurancesByDentalOffice && (
              <Popover
                position={Position.BOTTOM_CENTER}
                content={
                  <Menu>
                    <Menu.Group>
                      <Menu.Item onSelect={() => _onSelectDentalOffice(null)}>
                        All
                      </Menu.Item>
                      {_.map(dentalOffices, office => {
                        if (!office.name) {
                          return null;
                        }

                        return (
                          <Menu.Item
                            key={office.id}
                            onSelect={() => _onSelectDentalOffice(office)}
                          >
                            {office.name}
                          </Menu.Item>
                        );
                      })}
                    </Menu.Group>
                  </Menu>
                }
              >
                <Button
                  iconBefore={DiagnosisIcon}
                  appearance={selectedDentalOffice ? "primary" : "default"}
                >
                  {selectedDentalOffice
                    ? `Showing ${selectedDentalOffice.name} Only`
                    : "Showing All Dental Offices"}
                </Button>
              </Popover>
            )}
          </React.Fragment>
        }
        rightContent={
          <React.Fragment>
            <DateRangeSelector
              icon={CalendarIcon}
              selectedRange={selectedFilterDateRange}
              onChange={_onFilterByAppointmentDate}
              label="Filter By Appointment Date"
            />

            <Popover
              position={Position.BOTTOM_RIGHT}
              content={
                <Menu>
                  <Menu.Group>
                    <Menu.Item
                      onSelect={() => _onChangeFilter(FilterTypes.All)}
                    >
                      Show <Strong>All</Strong>
                    </Menu.Item>
                  </Menu.Group>
                  <Menu.Divider />
                  <Menu.Group>
                    <Menu.Item
                      onSelect={() => _onChangeFilter(FilterTypes.Verified)}
                    >
                      Show Only <Strong>Verified</Strong>
                    </Menu.Item>
                    <Menu.Item
                      onSelect={() =>
                        _onChangeFilter(FilterTypes.InstantVerified)
                      }
                    >
                      Show Only <Strong>Instant Verified</Strong>
                    </Menu.Item>
                  </Menu.Group>
                  <Menu.Divider />
                  <Menu.Group>
                    <Menu.Item
                      onSelect={() => _onChangeFilter(FilterTypes.Unverified)}
                    >
                      Show Only <Strong>Unverified</Strong>
                    </Menu.Item>
                    <Menu.Item
                      onSelect={() =>
                        _onChangeFilter(FilterTypes.InstantUnverified)
                      }
                    >
                      Show Only <Strong>Instant Unverified</Strong>
                    </Menu.Item>
                  </Menu.Group>
                  <Menu.Divider />
                  <Menu.Group>
                    <Menu.Item
                      onSelect={() => _onChangeFilter(FilterTypes.Active)}
                    >
                      Show Only <Strong>Active</Strong>
                    </Menu.Item>
                    <Menu.Item
                      onSelect={() => _onChangeFilter(FilterTypes.Inactive)}
                    >
                      Show Only <Strong>Inactive</Strong>
                    </Menu.Item>
                    <Menu.Item
                      onSelect={() => _onChangeFilter(FilterTypes.OnHold)}
                    >
                      Show Only <Strong>On Hold</Strong>
                    </Menu.Item>
                  </Menu.Group>
                  <Menu.Divider />
                  <Menu.Group>
                    <Menu.Item
                      onSelect={() => _onChangeFilter(FilterTypes.NewPatient)}
                    >
                      Show Only <Strong>New Patients</Strong>
                    </Menu.Item>
                    <Menu.Item
                      onSelect={() => _onChangeFilter(FilterTypes.Recall)}
                    >
                      Show Only <Strong>Recall Patients</Strong>
                    </Menu.Item>
                  </Menu.Group>
                </Menu>
              }
            >
              <Button marginLeft={12} iconBefore={ThFilteredIcon}>
                Showing {currentFilter}
              </Button>
            </Popover>

            {shouldShowDownloadListButton ? (
              <CSVLink
                filename="insurances.csv"
                data={_getFilteredInsurancesCsv()}
              >
                <IconButton marginLeft={12} icon={DownloadIcon} />
              </CSVLink>
            ) : null}

            {permissions.canCreateInsurance && (
              <Button
                appearance="primary"
                onClick={_toCreateInsurance}
                iconBefore={PlusIcon}
                marginLeft={12}
              >
                Create Insurance
              </Button>
            )}
          </React.Fragment>
        }
      />

      {_.isEmpty(insurances) ? (
        <StateWrapper>
          <EmptyState />
        </StateWrapper>
      ) : (
        <Table
          data={tableData}
          onClickItem={_onClickItem}
          header={tableHeaders}
        />
      )}
    </Wrapper>
  );
};

// Exports
export default Insurances;
