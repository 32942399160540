// Modules
import React, { useState } from "react";
import Moment from "moment";
import _ from "lodash";

// Styles
import { Wrapper, Threads, Thread, Composer } from "./comments.styles";

// UI
import {
  Pill,
  Button,
  Text,
  Heading,
  Small,
  Dialog,
  TextInput
} from "evergreen-ui";

// Assets
import { USER_ROLE_OPTIONS } from "common/constants";

// Component
export const Comments = ({
  threads,
  onAddComment,
  onChangeMessage,
  isCommenting
}) => {
  // State
  const [isOpen, setIsOpen] = useState(false);

  // On Open
  const _onOpen = () => {
    setIsOpen(true);
  };

  // On Close
  const _onClose = () => {
    setIsOpen(false);
  };

  // On Send Message
  const _onSendMessage = () => {
    onAddComment();
  };

  return (
    <React.Fragment>
      <Button appearance="primary" onClick={_onOpen}>
        Discussions
        <Pill display="inline-flex" marginLeft={8}>
          {threads?.length || 0}
        </Pill>
      </Button>

      <Dialog
        isShown={isOpen}
        hasFooter={false}
        onCloseComplete={_onClose}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}
        hasClose={!isCommenting}
        preventBodyScrolling
        title="Discussions"
      >
        <Wrapper>
          <Composer.Wrapper>
            <Composer.InputWrapper>
              <TextInput
                width="100%"
                disabled={isCommenting}
                placeholder="Type your message here"
                onChange={onChangeMessage}
                autoFocus
              />
            </Composer.InputWrapper>
            <Composer.SubmitWrapper>
              <Button
                appearance="primary"
                onClick={_onSendMessage}
                disabled={isCommenting}
              >
                {isCommenting ? "Sending..." : "Send"}
              </Button>
            </Composer.SubmitWrapper>
          </Composer.Wrapper>

          <Threads>
            {_.map(threads, thread => {
              let author = "Unknown User";

              if (thread.author === USER_ROLE_OPTIONS.administrator.id) {
                author = "Administrator";
              }
              if (thread.author === USER_ROLE_OPTIONS.insuranceAgent.id) {
                author = "Insurance Agent";
              }
              if (thread.author === USER_ROLE_OPTIONS.dentalOffice.id) {
                author = "Dental Office";
              }

              return (
                <Thread.Wrapper key={thread.id}>
                  <Thread.Author>
                    <Heading size={400}>{author}</Heading>
                  </Thread.Author>

                  <Thread.Timestamp>
                    <Text color="muted">
                      <Small>
                        {Moment.unix(thread.timestamp).format(
                          "h:mm A · DD MMM, YYYY"
                        )}
                      </Small>
                    </Text>
                  </Thread.Timestamp>

                  <Thread.Message>
                    <Text color="muted">{thread.message}</Text>
                  </Thread.Message>
                </Thread.Wrapper>
              );
            })}
          </Threads>
        </Wrapper>
      </Dialog>
    </React.Fragment>
  );
};

// Exports
export default React.memo(Comments);
