// Modules
import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  user: null,
  permissions: null
};

// Slice
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    }
  }
});

// Actions
export const { setUser, setPermissions } = userSlice.actions;

// Exports
export default userSlice.reducer;
