// Modules

// Services
import Http from "services/http.service";

// Get Users
export const getUsers = async () => {
  const response = await Http.get("/users");

  return response.data;
};

// Change User Password
export const changeUserPassword = async ({ userId, newPassword }) => {
  const response = await Http.post("/admin/change-user-password", {
    userId,
    newPassword
  });

  return response.data;
};

// Exports
export default {
  changeUserPassword,
  getUsers
};
