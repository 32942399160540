// Modules
import React from "react";

// Styles
import {
  Wrapper,
  Illustration,
  TitleWrapper,
  MessageWrapper
} from "./empty-state.styles.js";

// UI
import { Heading, Text } from "evergreen-ui";

// Images
import ImageEmpty from "./images/empty.svg";

// Component
export const EmptyState = React.memo(() => {
  return (
    <Wrapper>
      <Illustration src={ImageEmpty} />
      <TitleWrapper>
        <Heading size={600}>Nothing here yet!</Heading>
      </TitleWrapper>
      <MessageWrapper>
        <Text color="muted">Looks like there is nothing to show here.</Text>
      </MessageWrapper>
    </Wrapper>
  );
});

// Exports
export default React.memo(EmptyState);
