// Modules
import React from "react";

// Styles
import { Wrapper } from "./logo.styles";

// Images
import LogoImage from "./images/logo.png";

// Logo Types
export const LogoTypes = {
  Color: "color",
  Monotone: "monotone"
};

// Component
export const Logo = React.memo(() => {
  return <Wrapper src={LogoImage} alt="" />;
});

// Exports
export default React.memo(Logo);
