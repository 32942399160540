// Modules
import { configureStore } from "@reduxjs/toolkit";

// Reducers
import AuthReducer from "redux/auth.slice";
import InsuranceReducer from "redux/insurances.slice";
import LayoutReducer from "redux/layout.slice";
import UserReducer from "redux/user.slice";

// Exports
export default configureStore({
  reducer: {
    auth: AuthReducer,
    insurances: InsuranceReducer,
    layout: LayoutReducer,
    user: UserReducer
  }
});
