// Modules
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import autoTable from "jspdf-autotable";
import { jsPDF } from "jspdf";

// Styles
import {
  Wrapper,
  TableWrapper,
  SpinnerWrapper
} from "./reports-details.styles";

// Theme
import Colors from "theming/colors";

// UI
import {
  Button,
  IconButton,
  Spinner,
  ChevronLeftIcon,
  toaster
} from "evergreen-ui";

// Redux
import {
  setBackgroundTint,
  setFluidLayout,
  setPageTitle
} from "redux/layout.slice";

// Shared
import Stripe from "components/shared/stripe";

// Services
import InsuranceService from "services/insurance.service";
import SchemaService from "services/schema.service";

const styles = {
  table: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%"
  },
  label: {
    height: 50,
    fontWeight: "bold",
    border: `1px solid ${Colors.Gray}`,
    backgroundColor: Colors.withAlpha(Colors.Gray, 0.25),
    padding: 16
  },
  text: {
    height: 50,
    border: `1px solid ${Colors.Gray}`,
    backgroundColor: "white",
    padding: 16
  }
};

// Component
export const ReportDetails = () => {
  // Hooks
  const dispatch = useDispatch();
  const locationParams = useParams();
  const history = useHistory();

  // State
  const [schema, setSchema] = useState(null);
  const [patientInfo, setPatientInfo] = useState(null);
  const [insuranceVerification, setInsuranceVerification] = useState(null);
  const [insuranceBreakdown, setInsuranceBreakdown] = useState(null);
  const [patientHistory, setPatientHistory] = useState(null);
  const [insuranceMeta, setInsuranceMeta] = useState(null);

  // Flags
  const [isFetchingSchema, setIsFetchingSchema] = useState(true);
  const [isFetchingInsurance, setIsFetchingInsurance] = useState(true);

  // Create Label Value Pairs
  const createLabelValuePairs = (rows, values) => {
    return _.map(rows, item => {
      const field = {
        label: item.props.label,
        value: values?.[item.id]
      };

      if (item.type === "Select") {
        field.value = _.find(item.props.options, x => {
          return x.value === values?.[item.id];
        })?.label;
      }

      if (item.type === "Boolean") {
        field.value = _.capitalize(_.toString(values?.[item.id]));
      }

      if (item.id === "requiresInstantVerification") {
        // prettier-ignore
        field.value = _.capitalize(_.toString(insuranceMeta?.requiresInstantVerification));
      }

      return field;
    });
  };

  // Construct Table Structure
  const _constructTableStructure = schema => {
    const patientInfoStruct = [];
    const insuranceVerificationStruct = [];
    const insuranceBreakdownStruct = [];
    const patientHistoryStruct = [];

    // Patient Info
    _.forEach(schema.patientInfo, innerArray => {
      // prettier-ignore
      patientInfoStruct.push(createLabelValuePairs(innerArray, patientInfo));
    });

    // Insurance Verification
    _.forEach(schema.insuranceVerification, innerArray => {
      // prettier-ignore
      insuranceVerificationStruct.push(createLabelValuePairs(innerArray, insuranceVerification));
    });

    // Insurance Breakdown
    _.forEach(schema.insuranceBreakdown, innerArray => {
      // prettier-ignore
      insuranceBreakdownStruct.push(createLabelValuePairs(innerArray, insuranceBreakdown));
    });

    // Patient History
    _.forEach(schema.patientHistory, innerArray => {
      // prettier-ignore
      patientHistoryStruct.push(createLabelValuePairs(innerArray, patientHistory));
    });

    return [
      ...patientInfoStruct,
      ...insuranceVerificationStruct,
      ...insuranceBreakdownStruct,
      ...patientHistoryStruct
    ];
  };

  // Get Schema
  const _getSchema = async schemaOwnerId => {
    try {
      setIsFetchingSchema(true);

      const response = await SchemaService.getSchemaByOwner(schemaOwnerId);

      setSchema(response?.schema);

      setIsFetchingSchema(false);
    } catch (exception) {
      setIsFetchingSchema(false);

      toaster.danger(exception.uiMessage);
    }
  };

  // Get Insurance
  const _getInsurance = async insuranceId => {
    try {
      setIsFetchingInsurance(true);

      const insurance = await InsuranceService.getInsurance(insuranceId);

      const {
        patientInfo,
        insuranceBreakdown,
        insuranceVerification,
        patientHistory,
        meta
      } = insurance;

      setPatientInfo(patientInfo);
      setInsuranceBreakdown(insuranceBreakdown);
      setInsuranceVerification(insuranceVerification);
      setPatientHistory(patientHistory);
      setInsuranceMeta(meta);

      const schemaOwnerId = insurance?.createdBy?.id;

      // Get Schema
      await _getSchema(schemaOwnerId);

      setIsFetchingInsurance(false);
    } catch (exception) {
      setIsFetchingInsurance(false);

      toaster.danger("Something went wrong!", {
        duration: 60000,
        description: exception.uiMessage
      });
    }
  };

  // On Back Press
  const _onBackPress = () => {
    return history.goBack();
  };

  // On Print
  const _onPrint = () => {
    // Get the HTML of div
    const divElements = document.getElementById("table-wrapper").innerHTML;

    // Get the HTML of whole page
    const oldPage = document.body.innerHTML;

    // Reset the page's HTML with div's HTML only
    document.body.innerHTML = `<html><head><title></title></head><body>${divElements}</body>`;

    // Print Page
    window.print();

    // Restore orignal HTML
    document.body.innerHTML = oldPage;
  };

  // On Download
  const _onDownload = () => {
    const document = new jsPDF({
      compress: true
    });

    autoTable(document, { html: "#table" });

    const fileName = locationParams.reportId;

    document.save(`${fileName}.pdf`);
  };

  useEffect(() => {
    const { reportId } = locationParams;

    dispatch(setFluidLayout(false));
    dispatch(setBackgroundTint(true));
    dispatch(
      setPageTitle({
        title: "Reports",
        subTitle: _.toUpper(reportId)
      })
    );

    // Get Insurance
    _getInsurance(reportId);
  }, []);

  if (isFetchingInsurance || isFetchingSchema) {
    return (
      <Wrapper>
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      </Wrapper>
    );
  }

  const tableStructure = _constructTableStructure(schema);

  return (
    <Wrapper>
      {/* Stripe */}
      <Stripe
        isSticky
        leftContent={
          <IconButton icon={ChevronLeftIcon} onClick={_onBackPress} />
        }
        rightContent={
          <React.Fragment>
            <Button appearance="default" onClick={_onPrint}>
              Print
            </Button>

            <Button marginLeft={12} appearance="primary" onClick={_onDownload}>
              Download as PDF
            </Button>
          </React.Fragment>
        }
      />

      {/* Table */}
      <TableWrapper id="table-wrapper">
        <table id="table" style={styles.table}>
          <tbody>
            {_.map(tableStructure, (row, ndx) => (
              <React.Fragment key={`report-table-row-${ndx}`}>
                <tr>
                  {_.map(row, (item, index) => {
                    const key = `report-table-row-header-${item.label}-${index}`;

                    return (
                      <td key={key} style={styles.label}>
                        {item.label}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  {_.map(row, (item, index) => {
                    const key = `report-table-row-value-${item.label}-${index}`;

                    return (
                      <td key={key} style={styles.text}>
                        {item.value || "-"}
                      </td>
                    );
                  })}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    </Wrapper>
  );
};

// Exports
export default ReportDetails;
