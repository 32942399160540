// Modules
import React from "react";
import { ThemeProvider } from "evergreen-ui";
import { BrowserRouter } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";

// Styles
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/core/lib/css/blueprint.css";

// Theming
import { appTheme } from "theming/theme";

// Assets
import { STRIPE } from "common/constants";

// Redux
import Store from "redux/store";

// Components
import Bootstrap from "components/bootstrap";

// Initialise Stripe
const stripePromise = loadStripe(STRIPE.PUBLIC_KEY);

// Render
ReactDOM.render(
  <Elements stripe={stripePromise}>
    <Provider store={Store}>
      <BrowserRouter>
        <ThemeProvider value={appTheme}>
          <Bootstrap />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </Elements>,
  document.getElementById("app")
);
