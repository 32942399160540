// Modules
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Styles
import {
  Wrapper,
  Section,
  Header,
  Tabs,
  Builder,
  SchemaItem,
  Options
} from "./form-builder.styles";

// UI
import {
  Button,
  Heading,
  Text,
  IconButton,
  TrashIcon,
  TextInputField,
  SelectField,
  Tooltip,
  toaster
} from "evergreen-ui";

// Redux
import {
  setBackgroundTint,
  setFluidLayout,
  setPageTitle
} from "redux/layout.slice";

// Shared
import CustomFormPreview from "components/shared/custom-form-preview";

// Services
import SchemaService from "services/schema.service";

// Data Types
const fieldDataTypes = {
  Text: "Text",
  Date: "Date",
  Checkbox: "Checkbox",
  Boolean: "Boolean",
  Select: "Select"
};

// Tabs
export const tabs = [
  {
    id: "tabPatientInfo",
    label: "Patient Info"
  },
  {
    id: "tabInsuranceVerification",
    label: "Insurance Verification"
  },
  {
    id: "tabInsuranceBreakdown",
    label: "Insurance Breakdown"
  },
  {
    id: "tabPatientHistory",
    label: "Patient History"
  }
];

// Component
export const FormBuilder = () => {
  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();

  // Selectors
  const userReducer = useSelector(({ user }) => user);

  const { permissions } = userReducer;

  // State
  const [schema, setSchema] = useState(null);
  const [schemaId, setSchemaId] = useState(null);
  const [patientInfoSchema, setPatientInfoSchema] = useState(null);
  const [patientHistorySchema, setPatientHistorySchema] = useState(null);
  // prettier-ignore
  const [insuranceVerificationSchema, setInsuranceVerificationSchema] = useState(null);
  // prettier-ignore
  const [insuranceBreakdownSchema, setInsuranceBreakdownSchema] = useState(null);

  // Tabs
  const [currentTab, setCurrentTab] = useState(tabs[0].id);

  // Flags
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const [isFetchingSchema, setIsFetchingSchema] = useState(true);

  // Get Schema
  const _getSchema = async () => {
    try {
      setIsFetchingSchema(true);

      const response = await SchemaService.getSchema();

      const {
        patientInfo,
        patientHistory,
        insuranceVerification,
        insuranceBreakdown
      } = response?.schema;

      setPatientInfoSchema(_.cloneDeepWith(patientInfo));
      setPatientHistorySchema(_.cloneDeepWith(patientHistory));
      setInsuranceVerificationSchema(_.cloneDeepWith(insuranceVerification));
      setInsuranceBreakdownSchema(_.cloneDeepWith(insuranceBreakdown));
      setSchema(response?.schema);
      setSchemaId(response?.id);

      setIsFetchingSchema(false);
    } catch (exception) {
      setIsFetchingSchema(false);

      toaster.danger(exception.message);
    }
  };

  // On Toggle Edit Mode
  const _onToggleEditMode = () => {
    setIsEditModeActive(!isEditModeActive);
  };

  // On Cancel Changes
  const _onCancelChanges = () => {
    // Reset everything
    setPatientInfoSchema(_.cloneDeepWith(schema.patientInfo));
    setPatientHistorySchema(_.cloneDeepWith(schema.patientHistory));
    setInsuranceBreakdownSchema(_.cloneDeepWith(schema.insuranceBreakdown));
    setInsuranceVerificationSchema(
      _.cloneDeepWith(schema.insuranceVerification)
    );

    // Exit Edit Mode
    _onToggleEditMode();
  };

  // On Save Schema Updates
  const _onSaveSchemaUpdates = async () => {
    try {
      setIsSavingChanges(true);

      await SchemaService.updateSchema({
        schemaId,
        changes: {
          patientInfo: patientInfoSchema,
          insuranceVerification: insuranceVerificationSchema,
          insuranceBreakdown: insuranceBreakdownSchema,
          patientHistory: patientHistorySchema
        }
      });

      toaster.success("Schema Saved!");

      setIsEditModeActive(false);
      setIsSavingChanges(false);
    } catch (exception) {
      setIsSavingChanges(false);

      toaster.danger(exception.message);
    }
  };

  // Get Schema by Schema Name
  const getSchemaBySchemaName = schemaName => {
    if (schemaName === "patientInfoSchema") {
      return patientInfoSchema;
    }

    if (schemaName === "patientHistorySchema") {
      return patientHistorySchema;
    }

    if (schemaName === "insuranceBreakdownSchema") {
      return insuranceBreakdownSchema;
    }

    if (schemaName === "insuranceVerificationSchema") {
      return insuranceVerificationSchema;
    }
  };

  // Apply Update On Schema
  const applyUpdateOnSchema = (schemaName, updatedSchema) => {
    if (schemaName === "patientInfoSchema") {
      return setPatientInfoSchema(updatedSchema);
    }

    if (schemaName === "patientHistorySchema") {
      return setPatientHistorySchema(updatedSchema);
    }

    if (schemaName === "insuranceBreakdownSchema") {
      return setInsuranceBreakdownSchema(updatedSchema);
    }

    if (schemaName === "insuranceVerificationSchema") {
      return setInsuranceVerificationSchema(updatedSchema);
    }
  };

  // On Change Field Label
  const _onChangeFieldLabel = ({ schemaName, itemValue, itemId }) => {
    const schema = getSchemaBySchemaName(schemaName);

    const updatedSchema = _.map(schema, row => {
      return _.map(row, item => {
        if (_.isEqual(item.id, itemId)) {
          item.props.label = itemValue;
        }

        return item;
      });
    });

    applyUpdateOnSchema(schemaName, updatedSchema);
  };

  // On Change Field Type
  const _onChangeFieldType = ({ schemaName, itemValue, itemId }) => {
    const schema = getSchemaBySchemaName(schemaName);

    const updatedSchema = _.map(schema, row => {
      return _.map(row, item => {
        if (_.isEqual(item.id, itemId)) {
          item.type = itemValue;
        }

        return item;
      });
    });

    applyUpdateOnSchema(schemaName, updatedSchema);
  };

  // On Change Select Option
  const _onChangeSelectOptionLabel = ({
    optionId,
    optionValue,
    schemaName,
    itemId
  }) => {
    const schema = getSchemaBySchemaName(schemaName);

    const updatedSchema = _.map(schema, row => {
      return _.map(row, item => {
        if (item.id === itemId) {
          _.forEach(item.props?.options, option => {
            if (option.id === optionId) {
              option.label = optionValue;
              option.value = _.toUpper(optionValue.replace(" ", "_"));
            }
          });
        }

        return item;
      });
    });

    applyUpdateOnSchema(schemaName, updatedSchema);
  };

  // On Add Select Option
  const _onAddSelectOption = ({ schemaName, itemId }) => {
    const schema = getSchemaBySchemaName(schemaName);

    const updatedSchema = _.map(schema, row => {
      return _.map(row, item => {
        if (item.id === itemId) {
          if (!item.props?.options) {
            item.props.options = [];
          }

          const itemIndex = item.props.options.length + 1;

          item.props.options.push({
            id: `${Date.now()}`,
            label: `Option ${itemIndex}`,
            value: `OPTION_${itemIndex}`
          });
        }

        return item;
      });
    });

    applyUpdateOnSchema(schemaName, updatedSchema);
  };

  // On Delete Select Option
  const _onDeleteSelectOption = ({ schemaName, itemId, optionId }) => {
    // eslint-disable-next-line no-alert
    const confirmation = window.confirm("Delete option?");

    if (!confirmation) {
      return;
    }

    const schema = getSchemaBySchemaName(schemaName);

    const updatedSchema = _.map(schema, row => {
      return _.map(row, item => {
        if (item.id === itemId) {
          if (!item.props?.options) {
            item.props.options = [];
          }

          let updatedOptions = [];

          updatedOptions = _.filter(item.props.options, option => {
            if (option.id !== optionId) {
              return option;
            }
          });

          item.props.options = updatedOptions;
        }

        return item;
      });
    });

    applyUpdateOnSchema(schemaName, updatedSchema);
  };

  // Render Form
  const _renderForm = ({ schema, schemaName }) => {
    return _.map(schema, (row, ndx) => (
      <Builder.Rows key={`form-renderer-rows-row-${ndx}`}>
        {_.map(row, item => (
          <Builder.Row key={item.id}>
            <SchemaItem.Wrapper>
              {/* Label */}
              <SchemaItem.Label>
                <TextInputField
                  margin={0}
                  id={item.id}
                  label="Field Label"
                  disabled={!isEditModeActive}
                  onChange={event => {
                    _onChangeFieldLabel({
                      schemaName,
                      itemValue: event.target.value,
                      itemId: event.target.id
                    });
                  }}
                  value={item.props.label}
                  type="text"
                />
              </SchemaItem.Label>

              {/* Type */}
              <SchemaItem.Type>
                <SelectField
                  id={item.id}
                  label="Field Type"
                  disabled={!isEditModeActive}
                  onChange={event => {
                    _onChangeFieldType({
                      schemaName,
                      itemValue: event.target.value,
                      itemId: event.target.id
                    });
                  }}
                  value={item.type}
                  margin={0}
                >
                  {_.map(fieldDataTypes, type => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </SelectField>
              </SchemaItem.Type>
            </SchemaItem.Wrapper>

            {/* Options */}
            {item.type === "Select" && (
              <Options.Wrapper>
                {_.map(item.props.options, option => (
                  <Options.Row key={option.id}>
                    <Options.InputWrapper>
                      <TextInputField
                        margin={0}
                        id={option.id}
                        disabled={!isEditModeActive}
                        label="Option Label"
                        onChange={event => {
                          _onChangeSelectOptionLabel({
                            optionId: option.id,
                            optionValue: event.target.value,
                            itemId: item.id,
                            schemaName
                          });
                        }}
                        value={option.label}
                        type="text"
                      />
                    </Options.InputWrapper>
                    <Options.Actions>
                      <Tooltip content="Remove this field" position="top">
                        <IconButton
                          intent="danger"
                          icon={TrashIcon}
                          appearance="primary"
                          disabled={!isEditModeActive}
                          marginLeft={6}
                          onClick={() => {
                            _onDeleteSelectOption({
                              itemId: item.id,
                              optionId: option.id,
                              schemaName
                            });
                          }}
                        />
                      </Tooltip>
                    </Options.Actions>
                  </Options.Row>
                ))}

                <Options.Row>
                  <Button
                    disabled={!isEditModeActive}
                    onClick={() => {
                      _onAddSelectOption({
                        itemId: item.id,
                        schemaName
                      });
                    }}
                  >
                    Add Option
                  </Button>
                </Options.Row>
              </Options.Wrapper>
            )}
          </Builder.Row>
        ))}
      </Builder.Rows>
    ));
  };

  // On Change Tab
  const _onChangeTab = tabId => {
    setCurrentTab(tabId);
  };

  useEffect(() => {
    if (!permissions.canSeeFormBuilder) {
      return history.push("/not-found");
    }

    dispatch(setBackgroundTint(true));
    dispatch(setFluidLayout(false));
    dispatch(
      setPageTitle({
        title: "Form Builder",
        subTitle: "Customise the insurance form"
      })
    );

    // Get Schema
    _getSchema();
  }, []);

  if (isFetchingSchema) {
    return null;
  }

  const isPatientInfoTabActive = _.isEqual(currentTab, tabs[0].id);
  const isInsuranceVerificationTabActive = _.isEqual(currentTab, tabs[1].id);
  const isInsuranceBreakdownTabActive = _.isEqual(currentTab, tabs[2].id);
  const isPatientHistoryTabActive = _.isEqual(currentTab, tabs[3].id);

  return (
    <Wrapper>
      {/* Header */}
      <Section>
        <Header.Wrapper>
          <Header.Details>
            <Heading>Form Builder</Heading>
            <Text>Customise the insurance form for your dental office.</Text>
          </Header.Details>
          <Header.Actions>
            {isEditModeActive ? (
              <React.Fragment>
                <Button
                  appearance="default"
                  disabled={isSavingChanges}
                  onClick={_onCancelChanges}
                  marginLeft={12}
                >
                  Cancel
                </Button>
                <Button
                  appearance="primary"
                  isLoading={isSavingChanges}
                  onClick={_onSaveSchemaUpdates}
                  marginLeft={12}
                >
                  Save Changes
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button
                  appearance="default"
                  onClick={_onToggleEditMode}
                  marginRight={12}
                >
                  Edit
                </Button>

                <CustomFormPreview
                  rows={[
                    ...patientInfoSchema,
                    ...patientHistorySchema,
                    ...insuranceVerificationSchema,
                    ...insuranceBreakdownSchema
                  ]}
                />
              </React.Fragment>
            )}
          </Header.Actions>
        </Header.Wrapper>
      </Section>

      {/* Form */}
      <Section>
        {/* Tabs */}
        <Tabs.Wrapper>
          {_.map(tabs, tab => (
            <Tabs.Tab
              key={tab.id}
              isActive={_.isEqual(tab.id, currentTab)}
              onClick={() => _onChangeTab(tab.id)}
            >
              <Text>{tab.label}</Text>
            </Tabs.Tab>
          ))}
        </Tabs.Wrapper>

        <Tabs.Content>
          {/* Patient Information */}

          {/* Rows */}
          {isPatientInfoTabActive &&
            _renderForm({
              schema: patientInfoSchema,
              schemaName: "patientInfoSchema"
            })}

          {/* Insurance Verification */}

          {/* Rows */}
          {isInsuranceVerificationTabActive &&
            _renderForm({
              schema: insuranceVerificationSchema,
              schemaName: "insuranceVerificationSchema"
            })}

          {/* Insurance Breakdown */}

          {/* Rows */}
          {isInsuranceBreakdownTabActive &&
            _renderForm({
              schema: insuranceBreakdownSchema,
              schemaName: "insuranceBreakdownSchema"
            })}

          {/* Patient History */}

          {/* Rows */}
          {isPatientHistoryTabActive &&
            _renderForm({
              schema: patientHistorySchema,
              schemaName: "patientHistorySchema"
            })}
        </Tabs.Content>
      </Section>
    </Wrapper>
  );
};

// Exports
export default FormBuilder;
