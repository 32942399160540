// Modules
import Styled from "styled-components";

// Theme
import Colors, { withAlpha } from "theming/colors";
import Spacing from "theming/spacing";

// Wrapper
export const Wrapper = Styled.div`
  display: flex;
  justify-content: space-between;
  background: ${withAlpha(Colors.Gray, 0.5)};
  border-bottom: 1px solid ${Colors.Gray};
  width: 100%; height: 60px;

  ${props => {
    if (props.$isSticky) {
      return `
        position: sticky;
        backdrop-filter: blur(8px);
        top: 0; z-index: 6;
      `;
    }
  }} 
`;

// Left Wrapper
export const LeftWrapper = Styled.div`
  display: flex;
  justify-content: flex-start;
  padding-right: ${Spacing.Space1x};
  padding-left: ${Spacing.Space1x};
  align-items: center;
  flex: 1 1 100%;
  height: 100%;
`;

// Right Wrapper
export const RightWrapper = Styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: ${Spacing.Space1x};
  padding-left: ${Spacing.Space1x};
  align-items: center;
  flex: 1 1 100%;
  height: 100%;
`;
