// Modules
import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";

// Styles
import {
  Wrapper,
  SidebarWrapper,
  ContentWrapper,
  PageLayout
} from "./content.styles";

// Shared
import Navigation from "components/shared/navigation";
import Sidebar from "components/shared/sidebar";

// Pages
import Landing from "components/landing/landing";
import Login from "components/auth/login";
import CreateAccount from "components/auth/create-account";
import ForgotPassword from "components/auth/forgot-password";
import Dashboard from "components/dashboard/dashboard";
import Insurances from "components/insurances/insurances";
import InsurancesCreate from "components/insurances/insurances-create";
import InsurancesDetails from "components/insurances/insurances-details";
import FormBuilder from "components/form-builder/form-builder";
import Reports from "components/reports/reports";
import ReportsDetails from "components/reports/reports-details";
import TreatmentPlans from "components/treatment-plans/treatment-plans";
import ARClaims from "components/ar-claims/ar-claims";
import Invoices from "components/invoices/invoices";
import Integrations from "components/integrations/integrations";
import Users from "components/users/users";
import NotFound from "components/not-found/not-found";

// Component
export const Content = () => {
  // Hooks
  const history = useHistory();

  // Selectors
  const authReducer = useSelector(({ auth }) => auth);
  const layoutReducer = useSelector(({ layout }) => layout);

  const { isLoggedIn } = authReducer;
  const { isBackgroundTinted, isFluidLayout } = layoutReducer;

  // Determine Redirect
  const _determineRedirect = () => {
    // Public and Protected Routes
    const publicPaths = ["/login", "/forgot-password"];

    const { pathname } = window.location;

    const isCurrentPathPublic = _.find(publicPaths, path => {
      return pathname.includes(path);
    });

    if (isLoggedIn && isCurrentPathPublic) {
      const urlParams = new URLSearchParams(window.location.search);

      const returnPath = urlParams.get("return");

      return history.push(returnPath || "/dashboard");
    }

    if (!isLoggedIn && !isCurrentPathPublic) {
      return history.push(`/login?return=${window.location.pathname}`);
    }
  };

  useEffect(() => {
    _determineRedirect();
  }, [isLoggedIn]);

  return (
    <Wrapper>
      {/* Sidebar */}
      {!isFluidLayout && (
        <SidebarWrapper>
          <Sidebar />
        </SidebarWrapper>
      )}

      {/* Page */}
      <ContentWrapper>
        <PageLayout.Wrapper $isTinted={isBackgroundTinted}>
          {/* Navigation */}
          {!isFluidLayout && (
            <PageLayout.NavigationWrapper>
              <Navigation />
            </PageLayout.NavigationWrapper>
          )}

          {/* Routes */}
          <PageLayout.RoutesWrapper>
            <Switch>
              {/* prettier-ignore */}
              <Route exact path="/" component={Landing} />
              {/* prettier-ignore */}
              <Route exact path="/login" component={Login} />
              {/* prettier-ignore */}
              <Route exact path="/signup" component={CreateAccount} />
              {/* prettier-ignore */}
              <Route exact path="/forgot-password" component={ForgotPassword} />
              {/* prettier-ignore */}
              <Route exact path="/dashboard" component={Dashboard} />
              {/* prettier-ignore */}
              <Route exact path="/insurances" component={Insurances} />
              {/* prettier-ignore */}
              <Route exact path="/insurances/create" component={InsurancesCreate} />
              {/* prettier-ignore */}
              <Route exact path="/insurances/:insuranceId" component={InsurancesDetails} />
              {/* prettier-ignore */}
              <Route exact path="/reports" component={Reports} />
              {/* prettier-ignore */}
              <Route exact path="/reports/:reportId" component={ReportsDetails} />
              {/* prettier-ignore */}
              <Route exact path="/treatment-plans" component={TreatmentPlans} />
              {/* prettier-ignore */}
              <Route exact path="/ar-claims" component={ARClaims} />
              {/* prettier-ignore */}
              <Route exact path="/invoices" component={Invoices} />
              {/* prettier-ignore */}
              <Route exact path="/form-builder" component={FormBuilder} />
              {/* prettier-ignore */}
              <Route exact path="/integrations" component={Integrations} />
              {/* prettier-ignore */}
              <Route exact path="/users" component={Users} />
              {/* prettier-ignore */}
              <Route exact path="/*" component={NotFound} />
            </Switch>
          </PageLayout.RoutesWrapper>
        </PageLayout.Wrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

// Exports
export default Content;
