// Modules

// Services
import Http from "services/http.service";

// Get Comments
export const getComments = async subjectId => {
  const response = await Http.get(`/comments/${subjectId}`);

  return response.data;
};

// Add Comment
export const addComment = async payload => {
  const response = await Http.post("/comments", payload);

  return response.data;
};

// Exports
export default { getComments, addComment };
