// Modules
import Styled from "styled-components";

// Theme
import Colors from "theming/colors";
import Spacing from "theming/spacing";

// Images
import ForgotPasswordBg from "./images/login-bg.jpg";

// Photo
export const Photo = Styled.div`
  width: 100%; height: 100%;
  background-image: url(${ForgotPasswordBg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
  @media only screen and (max-width: 920px) {
    background-position: 30%;
  }
`;

// Content
export const Content = {
  Wrapper: Styled.div`
    max-width: 420px;
    min-width: 320px;
    padding: ${Spacing.Space2x};
    overflow-x: hidden;
    margin: auto;
    
    @media only screen and (max-width: 640px) {
      max-width: 380px;
      padding-bottom: ${Spacing.Space6x};
      padding-top: ${Spacing.Space4x};
    }

    @media only screen and (max-width: 525px) {
      padding-bottom: 120px;
    }
  `,
  Element: Styled.div`
    margin-top: ${Spacing.Space1x};
    margin-bottom: ${Spacing.Space1x};

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  `
};

// Header
export const Header = {
  Wrapper: Styled.div`
    width: 100%;
  `,
  LogoWrapper: Styled.div`
    margin-bottom: ${Spacing.Space2x};
  `,
  TitleWrapper: Styled.div`
    font-size: 150%;
    margin-bottom: 10px;

    @media only screen and (max-width: 640px) {
      text-align: center;
      font-size: 150%;
    }
  `,
  TextWrapper: Styled.div`
    @media only screen and (max-width: 640px) {
      text-align: center;
    }
  `,
  Action: Styled.div`
    margin-top: ${Spacing.Space1x};

    @media only screen and (max-width: 640px) {
      > button {
        width: 100%;
      }
    }
  `
};

// Form
export const Form = {
  Wrapper: Styled.div``,
  Element: Styled.div`
    margin-top: 0;
    margin-bottom: 0;

    &:first-child { margin-top: 0; }
    &:last-child { margin-bottom: 0; }
    ${props => {
      if (props.hasActions) {
        return `
          @media only screen and (max-width: 640px) {
            text-align: center;
            & > button {
              margin-top: 4px;
              margin-bottom: 4px;
              width: 100%;
            }
          }
        `;
      }
    }}
  `
};
