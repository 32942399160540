// Modules
import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  isFluidLayout: true,
  isBackgroundTinted: false,
  pageTitle: null,
  pageSubTitle: null
};

// Slice
export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setBackgroundTint: (state, action) => {
      state.isBackgroundTinted = action.payload;
    },
    setFluidLayout: (state, action) => {
      state.isFluidLayout = action.payload;
    },
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload.title;
      state.pageSubTitle = action.payload.subTitle;
    }
  }
});

// Actions
export const {
  setBackgroundTint,
  setFluidLayout,
  setPageTitle
} = layoutSlice.actions;

// Exports
export default layoutSlice.reducer;
