// Modules
import Styled from "styled-components";

// Wrapper
export const Wrapper = {
  Main: Styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `,
  Left: Styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: -3px;
  `,
  Right: Styled.div`
    padding-left: 10px;
  `
};
