// Modules
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Styles
import { Wrapper, ProgressBar, LogoWrapper } from "./bootstrap.styles.js";

// UI
import { toaster } from "evergreen-ui";

// Redux
import { setFluidLayout } from "redux/layout.slice";
import { setUser, setPermissions } from "redux/user.slice.js";
import { getInsurances } from "redux/insurances.slice";
import { setIsLoggedIn } from "redux/auth.slice";

// Shared
import Logo from "components/shared/logo";

// Pages
import Content from "components/content";

// Services
import AuthService from "services/auth.service";
import UserService from "services/user.service";

// Component
export const Bootstrap = () => {
  // Hooks
  const dispatch = useDispatch();

  // State
  const [isBootstrapping, setIsBootstrapping] = useState(true);
  const [currentProcess, setCurrentProcess] = useState("Starting up...");
  const [currentProgress, setCurrentProgress] = useState(0);

  // On App Load
  const _onAppLoad = async () => {
    try {
      setIsBootstrapping(true);

      setCurrentProgress(10);

      const isLoggedIn = AuthService.isLoggedIn();

      dispatch(setIsLoggedIn(isLoggedIn));

      if (isLoggedIn) {
        setCurrentProcess("Fetching User Profile");

        const user = await UserService.getUser();
        dispatch(setUser(user));

        setCurrentProgress(30);
        setCurrentProcess("Security Checks");

        const permissions = await UserService.getPermissions();
        dispatch(setPermissions(permissions));

        setCurrentProgress(80);
        setCurrentProcess("Getting All Your Insurances");

        await dispatch(getInsurances()).unwrap();

        setCurrentProgress(100);
        setCurrentProcess("Almost done!");
        dispatch(setFluidLayout(false));
      }

      if (!isLoggedIn) {
        dispatch(setFluidLayout(true));
        setIsBootstrapping(false);

        return;
      }

      setIsBootstrapping(false);
    } catch (exception) {
      toaster.danger("Something went wrong!", {
        duration: 500000,
        description: exception.uiMessage
      });
    }
  };

  useEffect(() => {
    _onAppLoad();
  }, []);

  if (isBootstrapping) {
    return (
      <Wrapper>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>

        <ProgressBar.Wrapper>
          <ProgressBar.Track>
            <ProgressBar.Fill $progress={currentProgress} />
          </ProgressBar.Track>

          <ProgressBar.Message>{currentProcess}</ProgressBar.Message>
        </ProgressBar.Wrapper>
      </Wrapper>
    );
  }

  return <Content />;
};

// Exports
export default Bootstrap;
