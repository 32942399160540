// Modules
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Styles
import { Wrapper, StateWrapper } from "./reports.styles.js";

// UI
import {
  Button,
  Icon,
  IconButton,
  Badge,
  Popover,
  Position,
  SearchInput,
  Menu,
  Spinner,
  Strong,
  ThFilteredIcon,
  FilterListIcon,
  ArrowRightIcon,
  toaster
} from "evergreen-ui";

// Redux
import {
  setBackgroundTint,
  setFluidLayout,
  setPageTitle
} from "redux/layout.slice";
import { getInsurances } from "redux/insurances.slice";

// Assets
import { formatDate, DateFormatLong } from "common/utilities";

// Shared
import Table from "components/shared/table";
import EmptyState from "components/shared/empty-state";
import ErrorState from "components/shared/error-state";
import Stripe from "components/shared/stripe";

// Filter Types
const FilterTypes = {
  All: "All",
  Verified: "Verified",
  InstantVerified: "Instant Verified",
  Unverified: "Unverified",
  InstantUnverified: "Instant Unverified",
  NewPatient: "New Patient",
  Recall: "Recall"
};

// Filterable Columns
const FilterableColumns = [
  {
    id: "patientInfo.patientFirstName",
    label: "Patient First Name"
  },
  {
    id: "patientInfo.patientLastName",
    label: "Patient Last Name"
  },
  {
    id: "createdBy.name",
    label: "Req. Dental Office"
  },
  {
    id: "patientInfo.insuranceCompanyName",
    label: "Insurance Company"
  }
];

// Component
export const Reports = () => {
  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();

  // Reducers
  const userReducer = useSelector(({ user }) => user);
  const insurancesReducer = useSelector(({ insurances }) => insurances);

  const { permissions } = userReducer;
  const { insurances } = insurancesReducer;

  // State
  const [filteredInsurances, setFilteredInsurances] = useState(null);
  const [isFetchingInsurances, setIsFetchingInsurances] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(FilterTypes.All);
  const [filterableColumn, setFilterableColumn] = useState(
    FilterableColumns[0]
  );
  const [hasFailedToFetchInsurances, setHasFailedToFetchInsurances] = useState(
    false
  );

  // Get Insurances
  const _getInsurances = async () => {
    try {
      setIsFetchingInsurances(true);

      if (insurances) {
        // Update insurances asyncrhonously
        dispatch(getInsurances());
      } else {
        // Get insurances syncrhonously
        await dispatch(getInsurances());
      }

      setHasFailedToFetchInsurances(false);
      setIsFetchingInsurances(false);
    } catch (exception) {
      setHasFailedToFetchInsurances(true);

      toaster.danger(exception.uiMessage, {
        duration: 60000
      });
    }
  };

  // On Change Filter
  const _onChangeFilter = type => {
    let updatedFilteredInsurances = [];

    switch (type) {
      case FilterTypes.All: {
        updatedFilteredInsurances = insurances;
        break;
      }

      case FilterTypes.Verified: {
        updatedFilteredInsurances = _.filter(insurances, x => {
          return x.isVerified;
        });
        break;
      }

      case FilterTypes.InstantVerified: {
        updatedFilteredInsurances = _.filter(insurances, x => {
          return x.isVerifiedInstantly;
        });
        break;
      }

      case FilterTypes.Unverified: {
        updatedFilteredInsurances = _.filter(insurances, x => {
          return (
            !x.isVerified &&
            !x.isVerifiedInstantly &&
            !x.requiresInstantVerification
          );
        });
        break;
      }

      case FilterTypes.InstantUnverified: {
        updatedFilteredInsurances = _.filter(insurances, x => {
          return (
            !x.isVerified &&
            !x.isVerifiedInstantly &&
            x.requiresInstantVerification
          );
        });
        break;
      }

      case FilterTypes.NewPatient: {
        updatedFilteredInsurances = _.filter(insurances, x => {
          const {
            patientInfo: { patientType }
          } = x;

          if (!patientType) {
            return x;
          }

          return _.isEqual(patientType, "NEW");
        });
        break;
      }

      case FilterTypes.Recall: {
        updatedFilteredInsurances = _.filter(insurances, x => {
          const {
            patientInfo: { patientType }
          } = x;

          return _.isEqual(patientType, "RECALL");
        });
        break;
      }

      default: {
        break;
      }
    }

    setCurrentFilter(type);
    setFilteredInsurances(updatedFilteredInsurances);
  };

  // On Search Insurances
  const _onSearchInsurances = event => {
    const { value: query } = event.target;

    // Reset current filter
    setCurrentFilter(FilterTypes.All);

    if (!query) {
      return setFilteredInsurances(insurances);
    }

    const filteredInsurances = _.filter(insurances, item => {
      if (_.includes(filterableColumn.id, ".")) {
        const splitColumnId = _.split(filterableColumn.id, ".");

        return _.includes(
          _.toLower(item[splitColumnId[0]][splitColumnId[1]]),
          _.toLower(query)
        );
      }

      return _.includes(_.toLower(item[filterableColumn.id]), _.toLower(query));
    });

    setFilteredInsurances(filteredInsurances);
  };

  // Render Status Badge
  const _renderStatusBadge = item => {
    const {
      requiresInstantVerification,
      isVerifiedInstantly,
      isVerified,
      verifiedAt: isInsuranceVerified
    } = item;

    // Verified
    if (isInsuranceVerified) {
      if (isVerifiedInstantly) {
        return <Badge color="green">Verified Instantly</Badge>;
      }
      if (isVerified) {
        return <Badge color="teal">Verified</Badge>;
      }
    }

    // Unverified
    if (!isVerifiedInstantly && !isVerified) {
      if (!isVerifiedInstantly && requiresInstantVerification) {
        return <Badge color="red">Instant Unverified</Badge>;
      }

      return <Badge>Unverified</Badge>;
    }
  };

  // On Change Filterable Column
  const _onChangeFitlerableColumn = filterableColumn => {
    setFilterableColumn(filterableColumn);
  };

  // On Click Item
  const _onClickItem = reportId => {
    history.push(`/reports/${reportId}`);
  };

  useEffect(() => {
    if (!permissions.canSeeReports) {
      return history.push("/not-found");
    }

    dispatch(setFluidLayout(false));
    dispatch(setPageTitle({ title: "Reports" }));
    dispatch(setBackgroundTint(true));

    // Get Insurances
    _getInsurances();
  }, []);

  useEffect(() => {
    setFilteredInsurances(insurances);
  }, [insurances]);

  if (isFetchingInsurances) {
    return (
      <Wrapper>
        <StateWrapper>
          <Spinner />
        </StateWrapper>
      </Wrapper>
    );
  }

  if (hasFailedToFetchInsurances) {
    return (
      <Wrapper>
        <StateWrapper>
          <ErrorState />
        </StateWrapper>
      </Wrapper>
    );
  }

  const tableHeaders = [
    {
      id: "thPatientName",
      label: "Patient Name"
    },
    {
      id: "thPatientDOB",
      label: "Birth Date"
    },
    {
      id: "thCompany",
      label: "Ins. Company"
    },
    {
      id: "thReqDentalOffice",
      label: "Req. Dental Office"
    },
    {
      id: "thApptDate",
      label: "Appt. Date"
    },
    {
      id: "thPatientType",
      label: "Patient Type"
    },
    {
      id: "thStatus",
      label: "Status"
    },
    {
      id: "thActions",
      width: 32
    }
  ];

  const tableData = _.map(filteredInsurances, item => {
    const { patientInfo } = item;

    const name = `${patientInfo.patientFirstName} ${patientInfo.patientLastName}`;

    return {
      id: item.id,
      fields: [
        { thId: "thPatientName", value: name },
        {
          thId: "thPatientDOB",
          value: patientInfo.patientDOB
            ? formatDate(patientInfo.patientDOB, DateFormatLong)
            : "-"
        },
        { thId: "thCompany", value: patientInfo.insuranceCompanyName || "-" },
        { thId: "thReqDentalOffice", value: item.createdBy?.name || "-" },
        {
          thId: "thApptDate",
          value: formatDate(patientInfo.appointmentDate, DateFormatLong)
        },
        {
          thId: "thPatientType",
          value: patientInfo.patientType === "RECALL" ? "Recall" : "New"
        },
        { thId: "thStatus", value: () => _renderStatusBadge(item) },
        {
          thId: "thActions",
          value: () => (
            <Icon color="muted" marginLeft={12} icon={ArrowRightIcon} />
          ),
          width: 32
        }
      ]
    };
  });

  return (
    <Wrapper>
      <Stripe
        leftContent={
          <React.Fragment>
            <Popover
              position={Position.BOTTOM_LEFT}
              content={
                <Menu>
                  <Menu.Group>
                    {_.map(FilterableColumns, x => {
                      return (
                        <Menu.Item
                          key={x.id}
                          onSelect={() => _onChangeFitlerableColumn(x)}
                        >
                          Search by <Strong>{x.label}</Strong>
                        </Menu.Item>
                      );
                    })}
                  </Menu.Group>
                </Menu>
              }
            >
              <IconButton marginRight={12} icon={FilterListIcon} />
            </Popover>

            <SearchInput
              onChange={_onSearchInsurances}
              placeholder={`Search by ${filterableColumn?.label}`}
            />
          </React.Fragment>
        }
        rightContent={
          <Popover
            position={Position.BOTTOM_RIGHT}
            content={
              <Menu>
                <Menu.Group>
                  <Menu.Item onSelect={() => _onChangeFilter(FilterTypes.All)}>
                    Show <Strong>All</Strong>
                  </Menu.Item>
                </Menu.Group>
                <Menu.Divider />
                <Menu.Group>
                  <Menu.Item
                    onSelect={() => _onChangeFilter(FilterTypes.Verified)}
                  >
                    Show Only <Strong>Verified</Strong>
                  </Menu.Item>
                  <Menu.Item
                    onSelect={() =>
                      _onChangeFilter(FilterTypes.InstantVerified)
                    }
                  >
                    Show Only <Strong>Instant Verified</Strong>
                  </Menu.Item>
                </Menu.Group>
                <Menu.Divider />
                <Menu.Group>
                  <Menu.Item
                    onSelect={() => _onChangeFilter(FilterTypes.Unverified)}
                  >
                    Show Only <Strong>Unverified</Strong>
                  </Menu.Item>
                  <Menu.Item
                    onSelect={() =>
                      _onChangeFilter(FilterTypes.InstantUnverified)
                    }
                  >
                    Show Only <Strong>Instant Unverified</Strong>
                  </Menu.Item>
                </Menu.Group>
                <Menu.Divider />
                <Menu.Group>
                  <Menu.Item
                    onSelect={() => _onChangeFilter(FilterTypes.NewPatient)}
                  >
                    Show Only <Strong>New Patients</Strong>
                  </Menu.Item>
                  <Menu.Item
                    onSelect={() => _onChangeFilter(FilterTypes.Recall)}
                  >
                    Show Only <Strong>Recall Patients</Strong>
                  </Menu.Item>
                </Menu.Group>
              </Menu>
            }
          >
            <Button iconBefore={ThFilteredIcon}>Showing {currentFilter}</Button>
          </Popover>
        }
      />

      {_.isEmpty(insurances) ? (
        <StateWrapper>
          <EmptyState />
        </StateWrapper>
      ) : (
        <Table
          data={tableData}
          onClickItem={_onClickItem}
          header={tableHeaders}
        />
      )}
    </Wrapper>
  );
};

// Exports
export default Reports;
