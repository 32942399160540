// Modules
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Moment from "moment";

// Styles
import {
  Wrapper,
  Section,
  SpinnerWrapper,
  OpenDental
} from "./insurances-create.styles.js";

// Assets
import { APP } from "common/constants";

// UI
import {
  Alert,
  Heading,
  IconButton,
  ChevronLeftIcon,
  Button,
  Text,
  Select,
  Spinner,
  toaster
} from "evergreen-ui";

// Redux
import {
  setBackgroundTint,
  setFluidLayout,
  setPageTitle
} from "redux/layout.slice";

// Shared
import Stripe from "components/shared/stripe";
import DateRangeSelector from "components/shared/date-range-selector";
import DynamicForm from "components/shared/dynamic-form";

// Services
import InsuranceService from "services/insurance.service";
import IntegrationService from "services/integration.service";
import OpenDentalService from "../../services/open-dental.service";
import SchemaService from "services/schema.service";

// Flatten Schema
const flattenSchema = array => {
  const result = {};

  _.map(array, innerArray => {
    return _.map(innerArray, x => {
      result[x.id] = "";

      if (x.type === "Checkbox") {
        result[x.id] = false;
      }

      if (x.type === "Boolean") {
        result[x.id] = false;
      }
    });
  });

  return result;
};

// Create Form Definition
const createFormDefinition = (schema, values, permissions) => {
  return _.map(schema, array => {
    return _.map(array, x => {
      if (x.id === "groupName") {
        x.isHidden = !permissions.canEditGroupName;
      }

      if (x.id === "groupNumber") {
        x.isHidden = !permissions.canEditGroupNumber;
      }

      if (x.id === "payorId") {
        x.isHidden = !permissions.canEditPayorId;
      }

      x.props.value = values[x.id];

      return x;
    });
  });
};

// Component
export const InsurancesCreate = () => {
  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();

  // Selectors
  const userReducer = useSelector(({ user }) => user);

  const { permissions } = userReducer;

  // State
  const [schema, setSchema] = useState(null);
  const [patientInfo, setPatientInfo] = useState(null);
  const [insuranceVerification, setInsuranceVerification] = useState(null);
  const [insuranceBreakdown, setInsuranceBreakdown] = useState(null);
  const [patientHistory, setPatientHistory] = useState(null);

  // Integrations
  const [odAppointments, setOdAppointments] = useState(null);
  // prettier-ignore
  const [isFetchingOdAppointments, setIsFetchingOdAppointments] = useState(false);
  const [isOpenDentalEnabled, setIsOpenDentalEnabled] = useState(false);
  const [openDentalApptDate, setOpenDentalApptDate] = useState([]);
  // prettier-ignore
  const [hasFailedToFetchOdAppointments, setHasFailedToFetchOdAppointments] = useState(false)

  // Flags
  const [isFetchingSchema, setIsFetchingSchema] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isFetchingIntegrations, setIsFetchingIntegrations] = useState(false);
  const [isCreatingInsurance, setIsCreatingInsurace] = useState(false);

  // Get Schema
  const _getSchema = async () => {
    try {
      setIsFetchingSchema(true);

      const response = await SchemaService.getSchema();

      setSchema(response?.schema);

      setPatientInfo(flattenSchema(response?.schema?.patientInfo));
      setPatientHistory(flattenSchema(response?.schema?.patientHistory));
      // prettier-ignore
      setInsuranceVerification(flattenSchema(response?.schema?.insuranceVerification));
      // prettier-ignore
      setInsuranceBreakdown(flattenSchema(response?.schema?.insuranceBreakdown));

      setIsFetchingSchema(false);
    } catch (exception) {
      toaster.danger(exception.uiMessage);

      setIsFetchingSchema(false);
    }
  };

  // Get OD Appointments
  const _getOdAppointments = async () => {
    try {
      setIsFetchingOdAppointments(true);
      setHasFailedToFetchOdAppointments(false);

      const startDate = openDentalApptDate?.[0]
        ? Moment(openDentalApptDate?.[0]).format("YYYY-MM-DD")
        : Moment(new Date()).format("YYYY-MM-DD");

      const endDate = openDentalApptDate?.[1]
        ? Moment(openDentalApptDate?.[1]).format("YYYY-MM-DD")
        : Moment(new Date()).format("YYYY-MM-DD");

      const odAppointments = await OpenDentalService.getAppointments(
        startDate,
        endDate
      );

      setOdAppointments(odAppointments);
      setIsFetchingOdAppointments(false);
      setHasFailedToFetchOdAppointments(false);
    } catch (exception) {
      setIsFetchingOdAppointments(false);
      setHasFailedToFetchOdAppointments(true);
    }
  };

  // Get Integrations
  const _getIntegrations = async () => {
    try {
      setIsFetchingIntegrations(true);

      const integrations = await IntegrationService.getIntegrations();

      const { openDental } = integrations;

      setIsOpenDentalEnabled(openDental?.isEnabled);
      setIsFetchingIntegrations(false);
    } catch (exception) {
      toaster.danger(exception.uiMessage);

      setIsFetchingIntegrations(false);
    }
  };

  // On Change Open Dental Appointment Date
  const _onChangeOpenDentalApptDate = date => {
    setOpenDentalApptDate(date);
  };

  // On Change OD Patient
  const _onChangeOdPatient = event => {
    const { value } = event.target;

    const patientInfo = _.find(odAppointments, patient => {
      return _.isEqual(patient.patientNumber, _.toInteger(value));
    });

    setPatientInfo(patientInfo || flattenSchema(schema?.patientInfo));
  };

  // On Change Patient Information
  const _onChangePatientInfo = (type, name, value) => {
    const autofillableFields = [
      "patientFirstName",
      "patientLastName",
      "patientDOB"
    ];

    if (!autofillableFields.includes(name)) {
      return setPatientInfo({ ...patientInfo, [name]: value });
    }

    if (name === "patientFirstName") {
      if (patientInfo.insuredFirstName === patientInfo.patientFirstName) {
        setPatientInfo({
          ...patientInfo,
          patientFirstName: value,
          insuredFirstName: value
        });
      } else {
        setPatientInfo({
          ...patientInfo,
          patientFirstName: value
        });
      }
    }

    if (name === "patientLastName") {
      if (patientInfo.insuredLastName === patientInfo.patientLastName) {
        setPatientInfo({
          ...patientInfo,
          patientLastName: value,
          insuredLastName: value
        });
      } else {
        setPatientInfo({
          ...patientInfo,
          patientLastName: value
        });
      }
    }

    if (name === "patientDOB") {
      if (patientInfo.insuredDOB === patientInfo.patientDOB) {
        setPatientInfo({
          ...patientInfo,
          patientDOB: value,
          insuredDOB: value
        });
      } else {
        setPatientInfo({
          ...patientInfo,
          patientDOB: value
        });
      }
    }
  };

  // On Back Press
  const _onBackPress = () => {
    return history.goBack();
  };

  // On Cancel
  const _backToInsurances = () => {
    return history.push("/insurances");
  };

  // On Create
  const _onCreate = async () => {
    try {
      setIsCreatingInsurace(true);

      const payload = {
        patientInfo,
        insuranceVerification,
        insuranceBreakdown,
        patientHistory
      };

      const insurance = await InsuranceService.createInsurance(payload);

      history.push(`/insurances/${insurance.id}`);

      setIsCreatingInsurace(false);
    } catch (exception) {
      setIsCreatingInsurace(false);

      toaster.danger(exception.uiMessage, {
        duration: 60000
      });
    }
  };

  useEffect(() => {
    if (!permissions.canCreateInsurance) {
      return history.push("/not-found");
    }

    dispatch(setFluidLayout(false));
    dispatch(setBackgroundTint(true));
    dispatch(
      setPageTitle({
        title: "Insurances",
        subTitle: "Create Insurance"
      })
    );

    // Get Schema
    _getSchema();

    // Get Integrations
    _getIntegrations();
  }, []);

  if (isFetchingSchema) {
    return (
      <Wrapper>
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      </Wrapper>
    );
  }

  // Form Definitions
  const formDefinitions = [
    {
      id: "FD_PatientInformation",
      title: "Patient Information",
      description: "Fill out the patient details",
      isVisible: permissions.canSeePatientInformation,
      formFields: createFormDefinition(
        schema.patientInfo,
        patientInfo,
        permissions
      ),
      onChange: _onChangePatientInfo
    }
  ];

  const canCreateInsurance =
    patientInfo?.patientFirstName &&
    patientInfo?.patientLastName &&
    patientInfo?.patientDOB &&
    patientInfo?.appointmentDate &&
    patientInfo?.insuredFirstName &&
    patientInfo?.insuredLastName &&
    patientInfo?.insuredDOB &&
    patientInfo?.socialSecurityNumber &&
    patientInfo?.insuredMemberId &&
    patientInfo?.insuranceCompanyName &&
    patientInfo?.patientType;

  return (
    <Wrapper>
      {/* Stripe */}
      <Stripe
        isSticky
        leftContent={
          <IconButton
            icon={ChevronLeftIcon}
            disabled={isCreatingInsurance}
            onClick={_onBackPress}
          />
        }
        rightContent={
          <React.Fragment>
            <Button
              appearance="minimal"
              disabled={isCreatingInsurance}
              onClick={_backToInsurances}
              marginLeft={12}
            >
              Cancel
            </Button>
            <Button
              appearance="primary"
              isLoading={isCreatingInsurance}
              disabled={!canCreateInsurance}
              onClick={_onCreate}
              marginLeft={12}
            >
              Create
            </Button>
          </React.Fragment>
        }
      />

      {/* Open Dental Integration */}
      {permissions.canAutofillPatientDetails && isOpenDentalEnabled ? (
        <Section.Wrapper>
          <Section.Header.Wrapper>
            <Section.Header.Left>
              <Section.Header.TitleWrapper>
                <Heading>OpenDental Integration</Heading>
              </Section.Header.TitleWrapper>
              <Section.Header.TextWrapper>
                <Text>Select a patient to auto-fill the details.</Text>
              </Section.Header.TextWrapper>
            </Section.Header.Left>
          </Section.Header.Wrapper>

          <Section.Content>
            {hasFailedToFetchOdAppointments && (
              <Alert title="Could not get patients list!" intent="danger">
                There was a problem in fetching the patients list from your
                OpenDental instance. Please refresh the page to try again. If
                the problem persists, please contact us at {APP.SUPPORT_EMAIL}
              </Alert>
            )}

            {isFetchingOdAppointments ? (
              <Spinner size={24} />
            ) : (
              !hasFailedToFetchOdAppointments && (
                <OpenDental.Wrapper>
                  <OpenDental.Row>
                    <DateRangeSelector
                      selectedRange={openDentalApptDate}
                      onChange={_onChangeOpenDentalApptDate}
                      label="Select a date range"
                    />
                    <Button
                      appearance="primary"
                      disabled={!openDentalApptDate?.length}
                      onClick={_getOdAppointments}
                      marginLeft={12}
                    >
                      Get Appointments
                    </Button>
                  </OpenDental.Row>

                  {_.isArray(odAppointments) && _.isEmpty(odAppointments) && (
                    <OpenDental.Row>
                      <Text color="muted">
                        There are no appointments for the selected date range.
                      </Text>
                    </OpenDental.Row>
                  )}

                  {_.isArray(odAppointments) && !_.isEmpty(odAppointments) && (
                    <OpenDental.Row>
                      <Select width={300} onChange={_onChangeOdPatient}>
                        <option>Select a patient</option>
                        {_.map(odAppointments, patient => (
                          <option
                            key={patient.patientNumber}
                            value={patient.patientNumber}
                          >
                            #{patient.patientNumber} -{" "}
                            {patient.patientFirstName} {patient.patientLastName}
                          </option>
                        ))}
                      </Select>
                    </OpenDental.Row>
                  )}
                </OpenDental.Wrapper>
              )
            )}
          </Section.Content>
        </Section.Wrapper>
      ) : null}

      {/* Form Definitions */}
      {_.map(formDefinitions, definition => {
        if (!definition.isVisible) {
          return null;
        }

        return (
          <Section.Wrapper key={definition.id}>
            <Section.Header.Wrapper>
              <Section.Header.Left>
                <Section.Header.TitleWrapper>
                  <Heading>{definition.title}</Heading>
                </Section.Header.TitleWrapper>
                <Section.Header.TextWrapper>
                  <Text>{definition.description}</Text>
                </Section.Header.TextWrapper>
              </Section.Header.Left>

              <Section.Header.Right>{definition.actions}</Section.Header.Right>
            </Section.Header.Wrapper>

            <Section.Content>
              <DynamicForm
                fields={definition.formFields}
                onChange={definition.onChange}
              />
            </Section.Content>
          </Section.Wrapper>
        );
      })}

      <Section.Wrapper>
        <Alert>
          You will be able to upload related documents after creating the
          insurance.
        </Alert>
      </Section.Wrapper>
    </Wrapper>
  );
};

// Exports
export default InsurancesCreate;
