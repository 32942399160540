// Modules
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  Wrapper,
  Section,
  Header,
  Options,
  ErrorStateWrapper,
  SpinnerWrapper
} from "./integrations.styles";

// UI
import {
  Button,
  Heading,
  Text,
  TextInputField,
  Switch,
  Spinner,
  toaster
} from "evergreen-ui";

// Redux
import {
  setBackgroundTint,
  setFluidLayout,
  setPageTitle
} from "redux/layout.slice";

// Shared
import ErrorState from "components/shared/error-state";

// Services
import IntegrationService from "services/integration.service";

// Component
export const Integrations = () => {
  // Hooks
  const dispatch = useDispatch();

  // Reducers
  const userReducer = useSelector(({ user }) => user);

  const { permissions } = userReducer;

  // State
  // prettier-ignore
  const [isFetchingIntegrations, setIsFetchingIntegrations] = useState(true)
  // prettier-ignore
  const [hasFailedToFetchIntegrations, setHasFailedToFetchIntegrations] = useState(true)
  // prettier-ignore
  const [isOpenDentalEnabled, setIsOpenDentalEnabled] = useState(false);
  // prettier-ignore
  const [isTogglingOpenDental, setIsTogglingOpenDental] = useState(false);
  // prettier-ignore
  const [openDentalDeveloperKey, setOpenDentalDeveloperKey] = useState("");
  // prettier-ignore
  const [openDentalCustomerKey, setOpenDentalCustomerKey] = useState("");
  // prettier-ignore
  const [isSavingOpenDentalCredentials, setIsSavingOpenDentalCredentials] = useState(false);

  // Get Integrations
  const getIntegrations = async () => {
    try {
      setIsFetchingIntegrations(true);
      setHasFailedToFetchIntegrations(false);

      const integrations = await IntegrationService.getIntegrations();

      const { openDental } = integrations;

      // Open Dental
      setIsOpenDentalEnabled(openDental?.isEnabled);
      setOpenDentalDeveloperKey(openDental?.developerKey);
      setOpenDentalCustomerKey(openDental?.customerKey);

      setIsFetchingIntegrations(false);
    } catch (error) {
      setIsFetchingIntegrations(false);
      setHasFailedToFetchIntegrations(true);
    }
  };

  // On Change OpenDental Customer Key
  const onChangeOpenDentalCustomerKey = e => {
    setOpenDentalCustomerKey(e.target.value);
  };

  // On Change OpenDental Developer Key
  const onChangeOpenDentalDeveloperKey = e => {
    setOpenDentalDeveloperKey(e.target.value);
  };

  // On Save OpenDental Credentials
  const onSaveOpenDentalCredentials = async () => {
    try {
      setIsSavingOpenDentalCredentials(true);

      await IntegrationService.saveOpenDentalCredentials({
        customerKey: openDentalCustomerKey,
        developerKey: openDentalDeveloperKey
      });

      toaster.success("Saved!");

      setIsSavingOpenDentalCredentials(false);
    } catch (exception) {
      setIsSavingOpenDentalCredentials(false);

      toaster.danger(exception.uiMessage, {
        duration: 60000
      });
    }
  };

  // On Toggle OpenDental Integration
  const onToggleOpenDentalIntegration = async () => {
    try {
      setIsTogglingOpenDental(true);

      const serviceFunction = isOpenDentalEnabled
        ? IntegrationService.disableOpenDental
        : IntegrationService.enableOpenDental;

      await serviceFunction();

      toaster.success(
        isOpenDentalEnabled
          ? "Disabled OpenDental Integration"
          : "Enabled OpenDental Integration"
      );

      setIsOpenDentalEnabled(!isOpenDentalEnabled);
      setIsTogglingOpenDental(false);
    } catch (exception) {
      setIsTogglingOpenDental(false);

      toaster.danger(exception.uiMessage, {
        duration: 60000
      });
    }
  };

  useEffect(() => {
    if (!permissions.canSeeIntegrations) {
      return history.replace("/not-found");
    }

    dispatch(setFluidLayout(false));
    dispatch(setPageTitle({ title: "Integrations" }));
    dispatch(setBackgroundTint(true));

    getIntegrations();
  }, []);

  if (isFetchingIntegrations) {
    return (
      <Wrapper>
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      </Wrapper>
    );
  }

  if (hasFailedToFetchIntegrations) {
    return (
      <Wrapper>
        <ErrorStateWrapper>
          <ErrorState />
          <Button appearance="primary" onClick={getIntegrations}>
            Retry
          </Button>
        </ErrorStateWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {/* Header */}
      <Section>
        <Header.Wrapper>
          <Header.Details>
            <Heading>Integrations</Heading>
            <Text>
              Connect to external services and automate your workflow.
            </Text>
          </Header.Details>
        </Header.Wrapper>
      </Section>

      {/* Options */}
      <Section>
        <Options.Wrapper>
          {/* Open Dental */}
          <Options.Item>
            <Options.HeaderWrapper>
              <Options.HeaderDetails>
                <Heading>OpenDental&trade;</Heading>
              </Options.HeaderDetails>
              <Options.HeaderActions>
                <Switch
                  height={24}
                  intent="success"
                  disabled={isTogglingOpenDental}
                  onChange={onToggleOpenDentalIntegration}
                  checked={isOpenDentalEnabled}
                  hasCheckIcon
                />
              </Options.HeaderActions>
            </Options.HeaderWrapper>

            <Options.FormWrapper>
              <Options.FormItem>
                <TextInputField
                  label="Customer Key"
                  placeholder="XXXX-XXXX-XXXX"
                  description="This can be obtained from your OpenDental account."
                  onChange={onChangeOpenDentalCustomerKey}
                  value={openDentalCustomerKey}
                  marginBottom={0}
                  disabled={
                    !isOpenDentalEnabled ||
                    isTogglingOpenDental ||
                    isSavingOpenDentalCredentials
                  }
                />
              </Options.FormItem>
              <Options.FormItem>
                <TextInputField
                  label="Developer Key"
                  placeholder="XXXX-XXXX-XXXX"
                  description="This can be obtained from your OpenDental account."
                  onChange={onChangeOpenDentalDeveloperKey}
                  value={openDentalDeveloperKey}
                  marginBottom={0}
                  disabled={
                    !isOpenDentalEnabled ||
                    isTogglingOpenDental ||
                    isSavingOpenDentalCredentials
                  }
                />
              </Options.FormItem>
              <Options.FormItem>
                <Button
                  appearance="primary"
                  isLoading={isSavingOpenDentalCredentials}
                  disabled={
                    !isOpenDentalEnabled || isSavingOpenDentalCredentials
                  }
                  onClick={onSaveOpenDentalCredentials}
                >
                  Save
                </Button>
              </Options.FormItem>
            </Options.FormWrapper>
          </Options.Item>
        </Options.Wrapper>
      </Section>
    </Wrapper>
  );
};

// Exports
export default Integrations;
