// With Alpha
export const withAlpha = (hex, alpha = 1) => {
  const HexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

  if (HexRegex.test(hex)) {
    const converted = HexRegex.exec(hex);

    if (Array.isArray(converted)) {
      const [, r, g, b] = converted;

      const color = {
        b: parseInt(b, 16),
        g: parseInt(g, 16),
        r: parseInt(r, 16)
      };

      return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
    }
  }

  return hex;
};

// Colors
export const Colors = {
  Black: "#121212",
  Danger: "#EF476F",
  Gray: "#E9EAED",
  Info: "#118AB2",
  Primary: "#75CAAA",
  Secondary: "#75CAAA",
  Success: "#06D6A0",
  Warning: "#FFD166",
  White: "#FFFFFF",
  withAlpha
};

// Exports
export default Colors;
