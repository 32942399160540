// Modules
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Styles
import {
  Wrapper,
  InnerWrapper,
  PhotoWrapper,
  ContentWrapper
} from "./layout.styles";
import { Photo, Content, Header, Form } from "./forgot-password.styles";

// UI
import {
  Button,
  Heading,
  Paragraph,
  Text,
  TextInputField,
  toaster
} from "evergreen-ui";

// Redux
import { setFluidLayout } from "redux/layout.slice";

// Shared
import Logo from "components/shared/logo";

// Services
import AuthService from "services/auth.service";

// Component
export const ForgotPassword = () => {
  // Hooks
  const history = useHistory();
  const dispatch = useDispatch();

  // Selectors
  const authReducer = useSelector(({ auth }) => auth);

  const { isLoggedIn } = authReducer;

  // State
  const [emailAddress, setEmailAddress] = useState("");
  const [isProcessingDone, setIsProcessingDone] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  // On Change Email Address
  const _onChangeEmailAddress = event => {
    const { value } = event.target;

    setEmailAddress(value);
  };

  // On Request Password Reset
  const _onRequestPasswordReset = async () => {
    try {
      setIsProcessing(true);
      setIsProcessingDone(false);

      await AuthService.requestPasswordReset({ emailAddress });

      setIsProcessingDone(true);
      setIsProcessing(false);
    } catch (exception) {
      setIsProcessing(false);
      setIsProcessingDone(false);

      toaster.danger(exception.uiMessage, {
        duration: 60000
      });
    }
  };

  // To Login
  const _toLogin = () => {
    history.push("/login");
  };

  useEffect(() => {
    dispatch(setFluidLayout(true));
  }, []);

  if (isLoggedIn) {
    // Prevent flicker that
    // occurs while transitioning
    return null;
  }

  if (isProcessingDone) {
    return (
      <Wrapper>
        <InnerWrapper>
          {/* Photo Wrapper */}
          <PhotoWrapper>
            <Photo />
          </PhotoWrapper>

          {/* Content Wrapper */}
          <ContentWrapper>
            <Content.Wrapper>
              <Content.Element>
                {/* Header */}
                <Header.Wrapper>
                  <Header.TitleWrapper>
                    <Heading size={800}>Sent!</Heading>
                  </Header.TitleWrapper>

                  <Header.TextWrapper>
                    <Paragraph>
                      We have sent a Password Reset Link to your email address.
                      Please use that to reset your password.
                    </Paragraph>
                  </Header.TextWrapper>

                  <Header.Action>
                    <Button onClick={_toLogin}>Back to Login</Button>
                  </Header.Action>
                </Header.Wrapper>
              </Content.Element>
            </Content.Wrapper>
          </ContentWrapper>
        </InnerWrapper>
      </Wrapper>
    );
  }

  const canSubmit = !isProcessing && emailAddress;

  return (
    <Wrapper>
      <InnerWrapper>
        {/* Content Wrapper */}
        <ContentWrapper>
          <Content.Wrapper>
            <Content.Element>
              {/* Header */}
              <Header.Wrapper>
                <Header.LogoWrapper>
                  <Logo />
                </Header.LogoWrapper>
                <Header.TitleWrapper>
                  <Heading size={800}>Forgot Password?</Heading>
                </Header.TitleWrapper>

                <Header.TextWrapper>
                  <Paragraph>
                    Having trouble logging in? Tell us your email address and
                    we'll send you the instructions to reset your password.
                  </Paragraph>
                </Header.TextWrapper>
              </Header.Wrapper>
            </Content.Element>

            {/* Form */}
            <Content.Element>
              <Form.Wrapper>
                {/* EmailAddress */}
                <Form.Element>
                  <TextInputField
                    label="Email Address"
                    onChange={_onChangeEmailAddress}
                    disabled={isProcessing}
                    type="email"
                  />
                </Form.Element>

                {/* Submit */}
                <Form.Element>
                  <Button
                    type="submit"
                    appearance="primary"
                    onClick={_onRequestPasswordReset}
                    disabled={!canSubmit}
                    isLoading={isProcessing}
                    marginBottom={12}
                    width="100%"
                  >
                    Send Instructions
                  </Button>
                </Form.Element>

                <Form.Element>
                  <Text
                    marginBottom={12}
                    textAlign="center"
                    display="block"
                    width="100%"
                  >
                    OR
                  </Text>
                </Form.Element>

                <Form.Element>
                  <Button width="100%" onClick={_toLogin}>
                    Back to Login
                  </Button>
                </Form.Element>
              </Form.Wrapper>
            </Content.Element>
          </Content.Wrapper>
        </ContentWrapper>

        {/* Photo Wrapper */}
        <PhotoWrapper>
          <Photo />
        </PhotoWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

// Exports
export default ForgotPassword;
