// Environment Variables
const { REACT_APP_API_BASE_URL, REACT_APP_STRIPE_PUBLIC_KEY } = process.env;

// Application
export const APP = {
  NAME: "Dental eVerify",
  SUPPORT_EMAIL: "info@dentaleverify.com"
};

// URLs
export const URLS = {
  API_BASE_URL: REACT_APP_API_BASE_URL
};

// Gender Options
export const GENDER_OPTIONS = {
  male: {
    id: 1,
    name: "Male",
    hidden: false
  },
  female: {
    id: 2,
    name: "Female",
    hidden: false
  }
};

// Software Options
export const SOFTWARE_OPTIONS = {
  openDental: {
    id: 1,
    name: "OpenDental"
  },
  eagleSoft: {
    id: 2,
    name: "EagleSoft"
  },
  dentrix: {
    id: 3,
    name: "Dentrix"
  },
  other: {
    id: 4,
    name: "Other"
  }
};

// Country Options
export const COUNTRY_OPTIONS = {
  unitedStates: {
    id: 2,
    name: "United States"
  }
};

// User Role Options
export const USER_ROLE_OPTIONS = {
  dentalOffice: {
    id: 1,
    name: "Dental Office",
    hidden: false
  },
  insuranceAgent: {
    id: 2,
    name: "Insurance Agent",
    hidden: false
  },
  administrator: {
    id: 99,
    name: "Administrator",
    hidden: true
  }
};

// Stripe
export const STRIPE = {
  PUBLIC_KEY: REACT_APP_STRIPE_PUBLIC_KEY
};

// Exports
export default {
  APP,
  URLS,
  GENDER_OPTIONS,
  COUNTRY_OPTIONS,
  USER_ROLE_OPTIONS,
  SOFTWARE_OPTIONS,
  STRIPE
};
