// Modules
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// Styles
import {
  Wrapper,
  InnerWrapper,
  Illustration,
  MessageWrapper,
  TitleWrapper
} from "./not-found.styles";

// UI
import { Button, Heading, Text, Small } from "evergreen-ui";

// Redux
import {
  setBackgroundTint,
  setFluidLayout,
  setPageTitle
} from "redux/layout.slice";

// Images
import ImageNotFound from "./images/not-found.svg";

// Component
export const NotFound = () => {
  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();

  // Back to Dashboard
  const _backToDashboard = () => {
    history.replace("/dashboard");
  };

  useEffect(() => {
    dispatch(setFluidLayout(false));
    dispatch(setPageTitle({ title: "🤔" }));
    dispatch(setBackgroundTint(true));
  }, []);

  return (
    <Wrapper>
      <InnerWrapper>
        <Illustration src={ImageNotFound} />
        <TitleWrapper>
          <Heading size={600}>Lost, are we?</Heading>
        </TitleWrapper>
        <MessageWrapper>
          <Text color="muted">
            The page you were looking for is not here.
            <br />
            <Small>(unless you were looking for this page)</Small>
          </Text>
        </MessageWrapper>

        <Button marginTop={30} onClick={_backToDashboard}>
          Back to Dashboard
        </Button>
      </InnerWrapper>
    </Wrapper>
  );
};

// Exports
export default NotFound;
