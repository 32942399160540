// Modules
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

// Styles
import { Wrapper } from "./landing.styles.js";

// Component
export const Landing = () => {
  // Hooks
  const history = useHistory();

  // Selectors
  const authReducer = useSelector(({ auth }) => auth);

  const { isLoggedIn } = authReducer;

  useEffect(() => {
    if (isLoggedIn) {
      return history.push("/dashboard");
    }

    return history.push("/login");
  }, []);

  return <Wrapper></Wrapper>;
};

// Exports
export default Landing;
