// Modules
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  Wrapper,
  Section,
  SpinnerWrapper
} from "./insurances-details.styles.js";

// UI
import {
  Heading,
  Alert,
  IconButton,
  ChevronLeftIcon,
  Dialog,
  Button,
  Text,
  Spinner,
  DownloadIcon,
  TrashIcon,
  Popover,
  DeleteIcon,
  TickCircleIcon,
  HandIcon,
  Menu,
  toaster
} from "evergreen-ui";

// Redux
import {
  setBackgroundTint,
  setFluidLayout,
  setPageTitle
} from "redux/layout.slice";
import { updateInsurances } from "redux/insurances.slice";

// Shared
import Table from "components/shared/table";
import Comments from "components/shared/comments";
import DynamicForm from "components/shared/dynamic-form";
import Stripe from "components/shared/stripe";

// Services
import CommentService from "services/comment.service.js";
import InsuranceService from "services/insurance.service";
import SchemaService from "services/schema.service";

// Component
export const InsurancesDetails = () => {
  // Hooks
  const dispatch = useDispatch();
  const locationParams = useParams();
  const history = useHistory();

  // Selectors
  const userReducer = useSelector(({ user }) => user);
  const insurancesReducer = useSelector(({ insurances }) => insurances);

  const { permissions, user } = userReducer;
  const { insurances } = insurancesReducer;

  // State
  const [schema, setSchema] = useState(null);
  const [insurance, setInsurance] = useState(null);
  const [patientInfo, setPatientInfo] = useState(null);
  const [commentMessage, setCommentMessage] = useState("");
  const [insuranceVerification, setInsuranceVerification] = useState(null);
  const [insuranceBreakdown, setInsuranceBreakdown] = useState(null);
  const [patientHistory, setPatientHistory] = useState(null);
  const [insuranceMeta, setInsuranceMeta] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [comments, setComments] = useState(null);

  // Flags
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [isChangingStatus, setIsChangingStatus] = useState(false);
  const [isEditingInsurance, setIsEditingInsurance] = useState(false);
  const [isVerifyingInsurance, setIsVerifyingInsurance] = useState(false);
  const [isFetchingInsurance, setIsFetchingInsurance] = useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const [isFetchingSchema, setIsFetchingSchema] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // Insurance States
  const insuranceStates = {
    active: true,
    inactive: false,
    onHold: "on-hold"
  };

  // Get Schema
  const _getSchema = async schemaOwnerId => {
    try {
      setIsFetchingSchema(true);

      const response = await SchemaService.getSchemaByOwner(schemaOwnerId);

      setSchema(response?.schema);

      setIsFetchingSchema(false);
    } catch (exception) {
      setIsFetchingSchema(false);
    }
  };

  // Get Comments
  const _getComments = async insuranceId => {
    try {
      setIsLoadingComments(true);

      const comments = await CommentService.getComments(insuranceId);

      setComments(comments);

      setIsLoadingComments(false);
    } catch (exception) {
      setIsLoadingComments(false);

      toaster.danger("Something went wrong!", {
        description: exception.uiMessage,
        duration: 60000
      });
    }
  };

  // On Add Comment
  const _onAddComment = async () => {
    try {
      setIsAddingComment(true);

      const payload = {
        author: user.role,
        subjectId: insurance.id,
        message: commentMessage
      };

      // Add Comment
      await CommentService.addComment(payload);

      // Get Comments
      const updatedComments = await CommentService.getComments(insurance.id);

      setComments(updatedComments);

      setCommentMessage("");

      setIsAddingComment(false);
    } catch (exception) {
      setIsAddingComment(false);

      toaster.danger("Something went wrong!", {
        description: exception.uiMessage,
        duration: 60000
      });
    }
  };

  // On Change Comment Message
  const _onChangeCommentMessage = event => {
    const { value } = event.target;

    setCommentMessage(value);
  };

  // Get Insurance
  const _getInsurance = async insuranceId => {
    try {
      setIsFetchingInsurance(true);

      const insurance = await InsuranceService.getInsurance(insuranceId);

      // Update Redux Store
      const updatedInsurances = _.map(insurances, x => {
        if (_.isEqual(x.id, insuranceId)) {
          x = insurance;
        }

        return x;
      });

      dispatch(updateInsurances(updatedInsurances));

      setInsurance(insurance);

      const {
        patientInfo,
        insuranceBreakdown,
        insuranceVerification,
        patientHistory,
        documents,
        meta
      } = insurance;

      if (!patientInfo.patientType) {
        patientInfo.patientType = "NEW";
      }

      setPatientInfo(patientInfo);
      setInsuranceBreakdown(insuranceBreakdown);
      setInsuranceVerification(insuranceVerification);
      setPatientHistory(patientHistory);
      setDocuments(documents);
      setInsuranceMeta(meta);

      const schemaOwnerId = insurance?.createdBy?.id;

      // Get Schema
      await _getSchema(schemaOwnerId);

      // Get Comments
      await _getComments(insurance?.id);

      setIsFetchingInsurance(false);
    } catch (exception) {
      setIsFetchingInsurance(false);

      toaster.danger("Something went wrong!", {
        duration: 60000,
        description: exception.uiMessage
      });
    }
  };

  // On Change Patient Information
  const _onChangePatientInfo = (type, name, value) => {
    setPatientInfo({ ...patientInfo, [name]: value });
  };

  // On Change Insurance Verification
  const _onChangeInsuranceVerification = (type, name, value) => {
    setInsuranceVerification({ ...insuranceVerification, [name]: value });
  };

  // On Change Insurance Breakdown
  const _onChangeInsuranceBreakdown = (type, name, value) => {
    setInsuranceBreakdown({ ...insuranceBreakdown, [name]: value });
  };

  // On Change Patient History
  const _onChangePatientHistory = (type, name, value) => {
    setPatientHistory({ ...patientHistory, [name]: value });
  };

  // On Change Instant Verification Flag
  const _onChangeInstantVerificationFlag = event => {
    const { value } = event.target;

    let requiresVerification, requiresInstantVerification;

    if (value === "true") {
      requiresVerification = false;
      requiresInstantVerification = true;
    } else {
      requiresVerification = true;
      requiresInstantVerification = false;
    }

    setInsuranceMeta({
      ...insuranceMeta,
      requiresInstantVerification,
      requiresVerification
    });
  };

  // On Back Press
  const _onBackPress = () => {
    return history.goBack();
  };

  // On Edit
  const _onEdit = async () => {
    try {
      setIsEditingInsurance(true);

      const { insuranceId } = locationParams;

      const payload = {
        ...insuranceMeta,
        patientInfo,
        insuranceBreakdown,
        insuranceVerification,
        patientHistory
      };

      await InsuranceService.updateInsurance(insuranceId, payload);

      // Update Redux Store
      const updatedInsurances = _.map(insurances, insurance => {
        if (_.isEqual(insurance.id, insuranceId)) {
          insurance = { ...insurance, ...payload };
        }

        return insurance;
      });

      dispatch(updateInsurances(updatedInsurances));

      toaster.success("Changes saved!");

      setIsEditModeActive(false);
      setIsEditingInsurance(false);
    } catch (exception) {
      setIsEditingInsurance(false);

      toaster.danger(exception.uiMessage, {
        duration: 60000
      });
    }
  };

  // Toggle Edit Mode
  const _toggleEditMode = () => {
    if (isEditModeActive) {
      const {
        patientInfo,
        insuranceBreakdown,
        insuranceVerification,
        patientHistory,
        documents,
        meta
      } = insurance;

      setPatientInfo(patientInfo);
      setInsuranceBreakdown(insuranceBreakdown);
      setInsuranceVerification(insuranceVerification);
      setPatientHistory(patientHistory);
      setDocuments(documents);
      setInsuranceMeta(meta);
    }

    setIsEditModeActive(!isEditModeActive);
  };

  // On Download Document
  const _onDownloadDocument = path => {
    window.open(path);
  };

  // On Delete Document
  const _onDeleteDocument = async documentId => {
    try {
      const { insuranceId } = locationParams;

      // Delete File
      await InsuranceService.deleteUploadedFile(insuranceId, documentId);

      // Update documents
      const updatedDocuments = _.filter(
        insurance.documents,
        d => !_.isEqual(d.id, documentId)
      );

      setDocuments(updatedDocuments);

      // Update Redux Store
      const updatedInsurances = _.map(insurances, insurance => {
        if (_.isEqual(insurance.id, insuranceId)) {
          insurance.documents = updatedDocuments;
        }

        return insurance;
      });

      dispatch(updateInsurances(updatedInsurances));

      toaster.success("Deleted!");
    } catch (exception) {
      toaster.danger("Couldn't delete the file. Please try again.");
    }
  };

  // On Upload Document
  const _onUploadDocument = async event => {
    try {
      setIsUploading(true);

      const { files } = event.target;

      const [selectedFile] = files;

      if (!selectedFile) {
        return;
      }

      const { insuranceId } = locationParams;

      // Upload
      const document = await InsuranceService.uploadDocument({
        file: selectedFile,
        insuranceId,
        fileAlias: encodeURIComponent(selectedFile.name)
      });

      const documentIdParts = _.split(document.id, "/");
      const actualDocumentId = documentIdParts[documentIdParts.length - 1];

      const updatedDocuments = [
        ...documents,
        {
          id: actualDocumentId,
          path: document.mediaLink,
          alias: selectedFile.name
        }
      ];

      setDocuments(updatedDocuments);

      // Update Redux Store
      const updatedInsurances = _.map(insurances, insurance => {
        if (_.isEqual(insurance.id, insuranceId)) {
          insurance.documents = updatedDocuments;
        }

        return insurance;
      });

      dispatch(updateInsurances(updatedInsurances));

      setIsUploading(false);
    } catch (exception) {
      setIsUploading(false);

      toaster.danger(exception.uiMessage, {
        duration: 60000
      });
    }
  };

  // On Verify Insurance
  const _onVerifyInsurance = async () => {
    try {
      setIsVerifyingInsurance(true);

      const { insuranceId } = locationParams;

      const { requiresInstantVerification } = insuranceMeta;

      const verifyFn = requiresInstantVerification
        ? InsuranceService.instantVerifyInsurance
        : InsuranceService.verifyInsurance;

      await verifyFn(insuranceId);

      await _getInsurance(insuranceId);

      setIsVerifyingInsurance(false);
    } catch (exception) {
      setIsVerifyingInsurance(false);

      toaster.danger(exception.uiMessage, {
        duration: 60000
      });
    }
  };

  // On Mark Insurance
  const _onMarkInsurance = async state => {
    try {
      setIsChangingStatus(true);

      const { insuranceId } = locationParams;

      let isActive = state === insuranceStates.active;

      if (state === insuranceStates.onHold) {
        isActive = insuranceStates.onHold;
      }

      const response = await InsuranceService.changeInsuranceStatus(
        insuranceId,
        isActive
      );

      setInsuranceMeta({ ...insuranceMeta, isActive });

      const toasterIntent =
        isActive === insuranceStates.active
          ? "success"
          : isActive === insuranceStates.inactive
          ? "danger"
          : isActive === insuranceStates.onHold
          ? "warning"
          : "warning";

      toaster[toasterIntent](response.message);

      _getInsurance(insuranceId);

      setIsChangingStatus(false);
    } catch (exception) {
      setIsChangingStatus(false);

      toaster.danger(exception.uiMessage, {
        duration: 60000
      });
    }
  };

  // Delete Insurance
  const _deleteInsurance = async () => {
    try {
      setIsDeleting(true);

      const { insuranceId } = locationParams;

      await InsuranceService.deleteInsurance(insuranceId);

      // Update Redux Store
      const updatedInsurances = _.filter(insurances, insurance => {
        return !_.isEqual(insurance.id, insuranceId);
      });

      dispatch(updateInsurances(updatedInsurances));

      toaster.success("Deleted!");

      history.push("/insurances");
    } catch (exception) {
      setIsDeleting(false);

      toaster.danger(exception.uiMessage, {
        duration: 60000
      });
    }
  };

  // On Show Delete Confirmation
  const _onShowDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  // On Hide Delete Confirmation
  const _onHideDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    dispatch(setFluidLayout(false));
    dispatch(setBackgroundTint(true));
    dispatch(
      setPageTitle({
        title: "Insurances",
        subTitle: "Insurance Details"
      })
    );

    const { insuranceId } = locationParams;

    // Get Insurance
    _getInsurance(insuranceId);
  }, []);

  if (isFetchingInsurance || isFetchingSchema) {
    return (
      <Wrapper>
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      </Wrapper>
    );
  }

  const canEditPatientInfo =
    isEditModeActive && permissions.canEditPatientInformation;
  const canEditVerificationInfo =
    isEditModeActive && permissions.canEditInsuranceVerification;
  const canEditBreakdownInfo =
    isEditModeActive && permissions.canEditInsuranceBreakdown;
  const canEditPatientHistory =
    isEditModeActive && permissions.canEditPatientHistory;

  // Form Definitions
  const formDefinitions = [
    // Patient Information
    {
      id: "FD_PatientInformation",
      title: "Patient Information",
      description: "Fill out the patient details",
      isVisible: permissions.canSeePatientInformation,
      onChange: _onChangePatientInfo,
      formFields: _.map(schema?.patientInfo, array => {
        return _.map(array, x => {
          let disabled = !canEditPatientInfo;

          if (x.id === "groupName") {
            x.isHidden = !permissions.canEditGroupName;
            disabled = !isEditModeActive || !permissions.canEditGroupName;
          }

          if (x.id === "groupNumber") {
            x.isHidden = !permissions.canEditGroupNumber;
            disabled = !isEditModeActive || !permissions.canEditGroupNumber;
          }

          if (x.id === "payorId") {
            x.isHidden = !permissions.canEditPayorId;
            disabled = !isEditModeActive || !permissions.canEditPayorId;
          }

          x.props.value = patientInfo[x.id];
          x.props.disabled = disabled;

          if (x.id === "requiresInstantVerification") {
            x.props.value = insuranceMeta.requiresInstantVerification;
            x.props.onChange = _onChangeInstantVerificationFlag;
          }

          return x;
        });
      })
    },

    // Insurance Verification
    {
      id: "FD_InsuranceVerification",
      title: "Insurance Verification",
      description: "Fill out the insurance verification details",
      isVisible: permissions.canSeeInsuranceVerification,
      onChange: _onChangeInsuranceVerification,
      formFields: _.map(schema?.insuranceVerification, array => {
        return _.map(array, x => {
          x.props.value = insuranceVerification[x.id];
          x.props.disabled = !canEditVerificationInfo;

          return x;
        });
      })
    },

    // Insurance Breakdown
    {
      id: "FD_InsuranceBreakdown",
      title: "Insurance Breakdown",
      description: "Fill out the insurance breakdown details",
      isVisible: permissions.canSeeInsuranceBreakdown,
      onChange: _onChangeInsuranceBreakdown,
      formFields: _.map(schema?.insuranceBreakdown, array => {
        return _.map(array, x => {
          x.props.value = insuranceBreakdown[x.id];
          x.props.disabled = !canEditBreakdownInfo;

          return x;
        });
      })
    },

    // Patient History
    {
      id: "FD_PatientHistory",
      title: "Patient History",
      description: "Fill out the patient history details",
      isVisible: permissions.canSeePatientHistory,
      onChange: _onChangePatientHistory,
      formFields: _.map(schema?.patientHistory, array => {
        return _.map(array, x => {
          x.props.value = patientHistory[x.id];
          x.props.disabled = !canEditPatientHistory;

          return x;
        });
      })
    }
  ];

  // Documents Table
  const documentsTableHeaders = [
    { id: "thAlias", label: "Alias" },
    { id: "thActions", width: isEditModeActive ? 120 : 120 }
  ];
  const documentsTableData = _.map(documents, doc => ({
    id: doc.id,
    fields: [
      { thId: "thAlias", value: doc.alias },
      {
        width: isEditModeActive ? 120 : 120,
        thId: "thActions",
        value: () => (
          <React.Fragment>
            <IconButton
              appearance="minimal"
              onClick={() => _onDownloadDocument(doc.path)}
              icon={DownloadIcon}
              marginLeft={12}
            />

            {permissions.canDeleteInsuranceDocuments && (
              <IconButton
                intent="danger"
                disabled={!isEditModeActive}
                onClick={() => _onDeleteDocument(doc.id)}
                appearance="minimal"
                icon={TrashIcon}
                marginLeft={12}
              />
            )}
          </React.Fragment>
        )
      }
    ]
  }));

  const canEditInsurance =
    patientInfo.patientFirstName &&
    patientInfo.patientLastName &&
    patientInfo.patientDOB &&
    patientInfo.appointmentDate &&
    patientInfo.insuredFirstName &&
    patientInfo.insuredLastName &&
    patientInfo.insuredDOB &&
    patientInfo.socialSecurityNumber &&
    patientInfo.insuredMemberId &&
    patientInfo.insuranceCompanyName;

  const shouldShowEditActions =
    !insuranceMeta.verifiedAt &&
    (permissions.canEditInsurance ||
      permissions.canMarkInsuranceActiveInactive);

  const shouldShowVerificationActions =
    permissions.canVerifyInsurance ||
    permissions.canInstantVerifyInsurance ||
    permissions.canMarkInsuranceAsEligible ||
    permissions.canMarkInsuranceAsInEligible;

  const shouldShowActiveInactiveButton =
    isEditModeActive &&
    (permissions.canMarkInsuranceAsActive |
      permissions.canMarkInsuranceAsInActive ||
      permissions.canMarkInsuranceAsOnHold);

  const allowEditingAfterVerification =
    permissions.canEditInsuranceAfterVerification;

  return (
    <Wrapper>
      {/* Stripe */}
      <Stripe
        isSticky
        leftContent={
          <React.Fragment>
            <IconButton
              icon={ChevronLeftIcon}
              disabled={isEditingInsurance}
              onClick={_onBackPress}
              marginRight={12}
            />

            {!isLoadingComments && (
              <Comments
                threads={comments}
                onAddComment={_onAddComment}
                onChangeMessage={_onChangeCommentMessage}
                isCommenting={isAddingComment}
              />
            )}
          </React.Fragment>
        }
        rightContent={
          <React.Fragment>
            {permissions.canDeleteInsurance && (
              <Button
                intent="danger"
                appearance="primary"
                disabled={isDeleting}
                onClick={_onShowDeleteConfirmation}
                marginLeft={12}
              >
                Delete
              </Button>
            )}

            {/* Edit Actions */}
            {shouldShowEditActions || allowEditingAfterVerification ? (
              isEditModeActive ? (
                <React.Fragment>
                  <Button
                    appearance="minimal"
                    disabled={isEditingInsurance || isVerifyingInsurance}
                    onClick={_toggleEditMode}
                    marginLeft={12}
                  >
                    Cancel Editing
                  </Button>

                  {(shouldShowActiveInactiveButton ||
                    allowEditingAfterVerification) && (
                    <Popover
                      content={
                        <Menu>
                          {permissions.canMarkInsuranceAsActive && (
                            <Menu.Item
                              // prettier-ignore
                              onClick={() => _onMarkInsurance(insuranceStates.active)}
                              intent="success"
                              icon={TickCircleIcon}
                            >
                              <Text>Active</Text>
                            </Menu.Item>
                          )}
                          {permissions.canMarkInsuranceAsInActive && (
                            <Menu.Item
                              // prettier-ignore
                              onClick={() => _onMarkInsurance(insuranceStates.inactive)}
                              intent="danger"
                              icon={DeleteIcon}
                            >
                              <Text>Inactive</Text>
                            </Menu.Item>
                          )}
                          {permissions.canMarkInsuranceAsOnHold && (
                            <Menu.Item
                              // prettier-ignore
                              onClick={() => _onMarkInsurance(insuranceStates.onHold)}
                              intent="warning"
                              icon={HandIcon}
                            >
                              <Text>On Hold</Text>
                            </Menu.Item>
                          )}
                        </Menu>
                      }
                    >
                      <Button
                        marginLeft={12}
                        isLoading={isChangingStatus}
                        disabled={
                          !canEditInsurance ||
                          !shouldShowActiveInactiveButton ||
                          isChangingStatus
                        }
                      >
                        Mark as...
                      </Button>
                    </Popover>
                  )}

                  <Button
                    appearance="primary"
                    isLoading={isEditingInsurance}
                    disabled={!canEditInsurance || isVerifyingInsurance}
                    onClick={_onEdit}
                    marginLeft={12}
                  >
                    Save Changes
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    appearance="primary"
                    isLoading={isEditingInsurance}
                    disabled={isEditModeActive || isVerifyingInsurance}
                    onClick={_toggleEditMode}
                    marginLeft={12}
                  >
                    Edit Details
                  </Button>
                </React.Fragment>
              )
            ) : null}

            {/* Verification Actions */}
            {shouldShowVerificationActions || allowEditingAfterVerification ? (
              <React.Fragment>
                <Button
                  appearance="primary"
                  isLoading={isVerifyingInsurance}
                  disabled={isEditModeActive || insuranceMeta.verifiedAt}
                  onClick={_onVerifyInsurance}
                  marginLeft={12}
                >
                  {insuranceMeta.verifiedAt
                    ? "Verified"
                    : insuranceMeta.requiresInstantVerification
                    ? "Instant Verify Insurance"
                    : "Verify Insurance"}
                </Button>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        }
      />

      {/* Message */}
      <Section.Wrapper>
        <Alert
          title={
            insurance.isActive === insuranceStates.active
              ? "Insurance is active!"
              : insurance.isActive === insuranceStates.inactive
              ? "Insurance is inactive"
              : insurance.isActive === insuranceStates.onHold
              ? "Insurance is put on hold!"
              : insuranceMeta.verifiedAt
              ? "Insurance is verified!"
              : "Cannot determine insurance status."
          }
          intent={
            insurance.isActive === insuranceStates.active
              ? "success"
              : insurance.isActive === insuranceStates.inactive
              ? "danger"
              : insurance.isActive === insuranceStates.onHold
              ? "warning"
              : insuranceMeta.verifiedAt
              ? "success"
              : "warning"
          }
        >
          {insurance.isActive === insuranceStates.onHold
            ? "Open discussions to learn more."
            : null}
        </Alert>
      </Section.Wrapper>

      {/* Form */}
      {formDefinitions[0].isVisible && (
        <Section.Wrapper key={formDefinitions[0].id}>
          <Section.Header.Wrapper>
            <Section.Header.TitleWrapper>
              <Heading>{formDefinitions[0].title}</Heading>
            </Section.Header.TitleWrapper>
            <Section.Header.TextWrapper>
              <Text>{formDefinitions[0].description}</Text>
            </Section.Header.TextWrapper>
          </Section.Header.Wrapper>

          <Section.Content>
            <DynamicForm
              isEditable={isEditModeActive}
              fields={formDefinitions[0].formFields}
              onChange={formDefinitions[0].onChange}
            />
          </Section.Content>
        </Section.Wrapper>
      )}

      {/* Documents */}
      <Section.Wrapper>
        <Section.Header.Wrapper>
          <Section.Header.TitleWrapper>
            <Heading>Documents</Heading>
          </Section.Header.TitleWrapper>
          <Section.Header.TextWrapper>
            <Text>
              Manage treatment plans, supporting files and other documents
            </Text>
          </Section.Header.TextWrapper>
        </Section.Header.Wrapper>

        <Section.Content>
          <Table
            withBorder
            header={documentsTableHeaders}
            data={documentsTableData}
          />
        </Section.Content>

        {isEditModeActive && (
          <Section.Content>
            <input
              type="file"
              id="file-input"
              accept="application/pdf"
              onChange={_onUploadDocument}
              hidden
            />

            {permissions.canUploadInsuranceDocuments && (
              <Button
                appearance="primary"
                disabled={isUploading || isEditingInsurance}
                isLoading={isUploading}
                marginTop={12}
              >
                <label style={{ cursor: "pointer" }} htmlFor="file-input">
                  Upload Document
                </label>
              </Button>
            )}
          </Section.Content>
        )}
      </Section.Wrapper>

      {/* Form */}
      {_.map(_.slice(formDefinitions, 1), definition => {
        if (!definition.isVisible) {
          return null;
        }

        return (
          <Section.Wrapper key={definition.id}>
            <Section.Header.Wrapper>
              <Section.Header.TitleWrapper>
                <Heading>{definition.title}</Heading>
              </Section.Header.TitleWrapper>
              <Section.Header.TextWrapper>
                <Text>{definition.description}</Text>
              </Section.Header.TextWrapper>
            </Section.Header.Wrapper>

            <Section.Content>
              <DynamicForm
                isEditable={isEditModeActive}
                fields={definition.formFields}
                onChange={definition.onChange}
              />
            </Section.Content>
          </Section.Wrapper>
        );
      })}

      <Dialog
        intent="danger"
        isShown={showDeleteConfirmation}
        title="Delete Insurance?"
        onConfirm={_deleteInsurance}
        onCloseComplete={_onHideDeleteConfirmation}
        onCancel={_onHideDeleteConfirmation}
        isConfirmLoading={isDeleting}
        isConfirmDisabled={isDeleting}
        shouldCloseOnOverlayClick={!isDeleting}
        shouldCloseOnEscapePress={!isDeleting}
        hasClose={!isDeleting}
        hasCancel={!isDeleting}
        confirmLabel="I Understand, Delete"
      >
        Are you sure you want to delete this insurance? This action is
        IRREVERSIBLE!
      </Dialog>
    </Wrapper>
  );
};

// Exports
export default InsurancesDetails;
