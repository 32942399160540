// Modules
import Styled from "styled-components";

// Wrapper
export const Wrapper = Styled.div`
  width: 100%; height: 100vh;
  user-select: none;
`;

// Inner Wrapper
// removed overflow:hidden
export const InnerWrapper = Styled.div`
  display: flex;
  width: 100%; height: 100%;
`;

// Photo Wrapper
export const PhotoWrapper = Styled.div`
  width: 50%; height: 100%;
  overflow: hidden;

  @media only screen and (max-width: 920px) {
    min-width: 125px;
    max-width: 125px;
  }

  @media only screen and (max-width: 640px) {
    display: none;
  }
`;

// Content Wrapper
export const ContentWrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%; min-height: 100%;
  overflow: auto;
  
  @media only screen and (max-width: 920px) {
    width: 100%;
  }
`;
