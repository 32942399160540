// Modules
import React from "react";
import Moment from "moment";

// UI
import { Button, Popover, Position } from "evergreen-ui";

// Datetime
import { DateRangePicker } from "@blueprintjs/datetime";

// Component
export const DateRangeSelector = ({
  label,
  icon,
  onChange,
  selectedRange = []
}) => {
  let buttonLabel;

  if (selectedRange[0] && selectedRange[1]) {
    buttonLabel = `${Moment(selectedRange[0]).format("MM/DD/YYYY")} to ${Moment(
      selectedRange[1]
    ).format("MM/DD/YYYY")}`;
  } else {
    buttonLabel = label;
  }

  return (
    <Popover
      content={
        <React.Fragment>
          <DateRangePicker
            onChange={onChange}
            allowSingleDayRange
            shortcuts={false}
            value={selectedRange}
          />
        </React.Fragment>
      }
      position={Position.BOTTOM_LEFT}
    >
      <Button iconAfter={icon}>{buttonLabel}</Button>
    </Popover>
  );
};

// Exports
export default React.memo(DateRangeSelector);
