// Modules
import React, { useState } from "react";
import { useSelector } from "react-redux";

// Styles
import {
  Wrapper,
  InnerWrapper,
  PageTitle,
  MiniProfileWrapper
} from "./navigation.styles.js";

// UI
import {
  Text,
  Dialog,
  KeyIcon,
  IconButton,
  PowerIcon,
  TextInputField,
  toaster,
  Button
} from "evergreen-ui";

// Shared
import MiniProfile from "./mini-profile.jsx";

// Services
import AuthService from "services/auth.service.js";

// Component
export const Navigation = React.memo(() => {
  // Selectors
  const layoutReducer = useSelector(({ layout }) => layout);

  const { pageTitle, pageSubTitle } = layoutReducer;

  // State
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  // Logout
  const _onLogout = () => {
    AuthService.logout();
  };

  // On Open Settings
  const _onOpenSettings = () => setIsSettingsOpen(true);

  // On Close Settings
  const _onCloseSettings = () => setIsSettingsOpen(false);

  // On Change New Password
  const _onChangeNewPassword = event => {
    setNewPassword(event.target.value);
  };

  // On Change Password
  const _onChangePassword = async () => {
    try {
      setIsChangingPassword(true);

      await AuthService.changePassword({ newPassword });

      toaster.success("Password changed!");

      setIsChangingPassword(false);
      setIsSettingsOpen(false);
    } catch (exception) {
      setIsChangingPassword(false);

      toaster.danger(exception.uiMessage);
    }
  };

  return (
    <Wrapper>
      <InnerWrapper.Main>
        {/* Right Side */}
        <InnerWrapper.Right>
          {/* Page Title */}
          <PageTitle.Wrapper>
            <PageTitle.TitleWrapper>
              <Text>{pageTitle}</Text>
            </PageTitle.TitleWrapper>
            <PageTitle.SubTitleWrapper>
              <Text size={300} color="muted">
                {pageSubTitle}
              </Text>
            </PageTitle.SubTitleWrapper>
          </PageTitle.Wrapper>

          {/* Mini Profile */}
          <MiniProfileWrapper>
            <MiniProfile />
            <IconButton
              icon={KeyIcon}
              onClick={_onOpenSettings}
              marginLeft={12}
              marginTop={3}
            />

            <IconButton
              icon={PowerIcon}
              onClick={_onLogout}
              marginLeft={12}
              marginTop={3}
            />
          </MiniProfileWrapper>
        </InnerWrapper.Right>
      </InnerWrapper.Main>

      <Dialog
        title="Change Password"
        isShown={isSettingsOpen}
        hasClose={!isChangingPassword}
        shouldCloseOnOverlayClick={!isChangingPassword}
        shouldCloseOnEscapePress={!isChangingPassword}
        onCloseComplete={_onCloseSettings}
        hasFooter={false}
      >
        <TextInputField
          type="password"
          label="New Password"
          disabled={isChangingPassword}
          onChange={_onChangeNewPassword}
          marginBottom={8}
        />
        <Button
          marginBottom={24}
          onClick={_onChangePassword}
          isLoading={isChangingPassword}
          disabled={isChangingPassword}
          appearance="primary"
        >
          Change Password
        </Button>
      </Dialog>
    </Wrapper>
  );
});

// Exports
export default React.memo(Navigation);
