// Modules
import Axios from "axios";
import _ from "lodash";

// Assets
import { APP, URLS } from "common/constants";

// Services
import StorageService from "services/storage.service";

// Sanitize App Name
const sanitizedAppName = APP.NAME.replace(/ /g, "");

// Create Instance
const Http = Axios.create({
  baseURL: URLS.API_BASE_URL
});

// Request Interceptor
Http.interceptors.request.use(config => {
  // Inject access token
  const accessToken = StorageService.getFromLocalStorage("act");

  if (accessToken) {
    config.headers.authorization = accessToken;
  }

  // Inject user id
  const userId = StorageService.getFromLocalStorage("usr");

  if (userId) {
    config.headers[`x-${_.toLower(sanitizedAppName)}-user`] = userId;
    config.headers[`x-integration-dental-office-id`] = userId;
  }

  // Inject user role
  const userRole = StorageService.getFromLocalStorage("url");

  if (userRole) {
    config.headers[`x-${_.toLower(sanitizedAppName)}-role`] = userRole;
  }

  return config;
});

// Response Interceptor
Http.interceptors.response.use(
  response => {
    // Intercept incoming responses here

    return Promise.resolve(response);
  },
  async error => {
    const { config, request, response } = error;

    const status = request?.status || response?.status;

    error.uiMessage =
      error?.response?.data?.message ||
      "Looks like something went wrong from our end. Can you please refresh the page and try again after a while.";

    if (status !== 401) {
      return Promise.reject(error);
    }

    // Refresh token flow
    const refreshToken = StorageService.getFromLocalStorage("rft");

    try {
      const response = await Axios.post(
        `${URLS.API_BASE_URL}/auth/refresh`,
        null,
        {
          headers: {
            ...config.headers,
            authorization: refreshToken
          }
        }
      );

      const {
        accessToken: newAccessToken,
        refreshToken: newRefreshToken
      } = response.data;

      StorageService.setInLocalStorage("act", newAccessToken);
      StorageService.setInLocalStorage("rft", newRefreshToken);

      // Do not attempt to retry the request if not a GET
      // We do not want multiple POST/PUT/DELETE requests to be made
      if (config.method !== "get") {
        return Promise.reject(error);
      }

      try {
        // Make the request again
        const originalRequest = await Axios[config.method](
          config.url,
          config.data,
          {
            ...config,
            headers: {
              ...config.headers,
              authorization: newAccessToken
            }
          }
        );

        return Promise.resolve(originalRequest);
      } catch (exception) {
        window.location.href = `/login?return=${window.location.pathname}`;
      }
    } catch (exception) {
      return Promise.reject(exception);
    }
  }
);

// Exports
export default Http;
