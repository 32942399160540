// Modules
import React from "react";

// Styles
import {
  Wrapper,
  Illustration,
  TitleWrapper,
  MessageWrapper
} from "./error-state.styles.js";

// UI
import { Heading, Text } from "evergreen-ui";

// Images
import ImageError from "./images/error.svg";

// Component
export const ErrorState = React.memo(() => {
  return (
    <Wrapper>
      <Illustration src={ImageError} />
      <TitleWrapper>
        <Heading size={600}>...aww snap!</Heading>
      </TitleWrapper>
      <MessageWrapper>
        <Text color="muted">
          Something went wrong on our side.
          <br />
          Please try again in a while.
        </Text>
      </MessageWrapper>
    </Wrapper>
  );
});

// Exports
export default React.memo(ErrorState);
