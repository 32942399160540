// Modules
import Styled from "styled-components";

// UI
import { Heading, Text } from "evergreen-ui";

// Theme
import Colors, { withAlpha } from "theming/colors";
import Spacing from "theming/spacing";

// Wrapper
export const Wrapper = Styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 58rem; 
  margin: auto;

  padding: ${Spacing.Space1x};
  padding-top: ${Spacing.Space3x};
  padding-bottom: 160px;
`;

// Section
export const Section = Styled.div`
  width: 100%;
  margin-bottom: ${Spacing.Space1x};
  margin-top: ${Spacing.Space1x};

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

// Header
export const Header = {
  Wrapper: Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  `,
  Details: Styled.div`
    display: flex;
    flex-direction: column;
  `,
  Actions: Styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `
};

// Tabs
export const Tabs = {
  Wrapper: Styled.div`
    display: flex;
    background: ${withAlpha(Colors.Gray, 0.65)};
    backdrop-filter: blur(4px);
    position: sticky;
    z-index: 2;
    top: -1px;
  `,
  Tab: Styled.div`
    flex: 1;
    text-align: center;
    padding: ${Spacing.SpaceNx(0.5)} ${Spacing.Space1x};
    border: 1px solid ${Colors.Gray};
    border-right-width: 0;
    cursor: pointer;

    background: ${props => {
      if (props.isActive) {
        return Colors.White;
      }

      return withAlpha(Colors.White, 0.5);
    }};
   
    &:first-child {
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-right-width: 1px;
    }
  `,
  Content: Styled.div`
    background: ${Colors.White};
    padding: ${Spacing.Space2x};
    border: 1px solid ${Colors.Gray};
    border-radius: 0 0 4px 4px;
    border-top-width: 0;
  `
};

// Builder
export const Builder = {
  Rows: Styled.div`
    width: 100%; 
    padding-top: ${Spacing.Space1x};
    padding-bottom: ${Spacing.Space1x};
    margin-bottom: ${Spacing.SpaceNx(0.75)};
    margin-top: ${Spacing.SpaceNx(0.75)};
    border: 1px dashed ${Colors.Gray};
    border-right-width: 0;
    border-left-width: 0;

    &:first-child {
      margin-top: 0;
      padding-top: 0;
      border-top-width: 0;
    }
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom-width: 0;
    }
  `,
  Row: Styled.div`
    width: 100%;
    margin-bottom: ${Spacing.SpaceNx(1)};
    margin-top: ${Spacing.SpaceNx(1)};
    flex-direction: column;
    display: flex;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  `
};

// Schema Item
export const SchemaItem = {
  Wrapper: Styled.div`
    display: flex;
    width: 100%;
  `,
  DragHandle: Styled.div`
    flex: 0.05;
    padding-top: 34px;
    cursor: move;
  `,
  Label: Styled.div`
    flex: 0.6;
  `,
  Type: Styled.div`
    flex: 0.4;
    padding-left: ${Spacing.SpaceNx(0.5)};
  `,
  Actions: Styled.div`
    flex: 0;
    display: flex;
    padding-left: ${Spacing.SpaceNx(0.5)};
    align-items: flex-end;
    padding-top: 26px;
  `
};

// Options
export const Options = {
  Wrapper: Styled.div`
    margin-top: ${Spacing.Space1x};
    border-left: 2px solid ${Colors.Gray};
    padding-left: ${Spacing.Space1x};
  `,
  Row: Styled.div`
    display: flex;
    margin-bottom: ${Spacing.SpaceNx(0.5)};
    margin-top: ${Spacing.SpaceNx(0.5)};

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  `,
  InputWrapper: Styled.div`
    flex: 1;
  `,
  Actions: Styled.div`
    flex: 0;
    display: flex;
    padding-left: ${Spacing.SpaceNx(0.25)};
    align-items: flex-end;
    padding-top: 26px;
  `
};
