// Modules
import Styled from "styled-components";
import { NavLink } from "react-router-dom";

// UI
import { Text } from "evergreen-ui";

// Theme
import Colors, { withAlpha } from "theming/colors";
import Spacing from "theming/spacing";

// Wrapper
export const Wrapper = Styled.div`
  width: 100%; height: 100%;
  border-right: 1px solid ${Colors.Gray};
  background: ${Colors.White};
`;

// Header
export const Header = Styled.div`
  width: 100%; height: 70px;
  border-bottom: 1px solid ${Colors.Gray};
  background: ${Colors.White};

  display: flex;
  justify-content: center;
  align-items: center;
`;

// Logo Wrapper
export const LogoWrapper = Styled.div``;

// Content
export const Content = Styled.div`
  width: 100%; height: 100%;
`;

// Menu
export const Menu = {
  Wrapper: Styled.div`
    width: 100%;
    padding: ${Spacing.Space1x};
  `,
  Section: Styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${Spacing.Space1x};
    width: 100%;
  `,
  SectionTitle: Styled(Text)`
    font-size: 75%;
    text-transform: uppercase;
    padding: ${Spacing.SpaceNx(0.5)};
    padding-bottom: ${Spacing.SpaceNx(0.25)};
    color: ${Colors.Black};
    opacity: 0.5;
  `,
  Item: Styled(NavLink)`
    padding: ${Spacing.SpaceNx(0.25)} ${Spacing.SpaceNx(0.5)};
    text-decoration: none;

    display: flex;
    align-items: center;

    > * {
      margin-top: -2px;
      transition: color 0.12s ease-in;
      color: ${Colors.Black};
    }

    &:hover {
      text-decoration: none;

      > * {
        color: ${Colors.Primary};
      }
    }

    &.active {
      > * {
        color: ${Colors.Primary};
        font-weight: bolder;
      }
    }
  `,
  ItemIconWrapper: Styled.div`
    margin-right: 12px;
    color: ${Colors.Primary};
    margin-top: 2px;
  `
};
