// Modules
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

// Styles
import {
  Wrapper,
  Header,
  Content,
  LogoWrapper,
  Menu
} from "./sidebar.styles.js";

// UI
import {
  Icon,
  Text,
  DashboardIcon,
  DataConnectionIcon,
  LayersIcon,
  DollarIcon,
  ChartIcon,
  DiagnosisIcon,
  BuildIcon,
  PeopleIcon,
  BankAccountIcon
} from "evergreen-ui";

// Assets
import { USER_ROLE_OPTIONS } from "common/constants";

// Shared
import Logo, { LogoTypes } from "components/shared/logo";

// Component
export const Sidebar = React.memo(() => {
  // Hooks
  const history = useHistory();

  // Selectors
  const userReducer = useSelector(({ user }) => user);

  const { permissions, user } = userReducer;

  // Menu Items
  const menuItems = [
    {
      title: "Overview",
      items: [
        {
          title: "Dashboard",
          isHidden: !permissions.canSeeDashboard,
          icon: DashboardIcon,
          to: "/dashboard"
        },
        {
          title: "Invoices",
          isHidden: !permissions.canSeeInvoices,
          icon: DollarIcon,
          to: "/invoices"
        }
      ]
    },
    {
      title: "Manage",
      items: [
        {
          title: "Insurances",
          isHidden: !permissions.canSeeInsurances,
          icon: LayersIcon,
          to: "/insurances"
        },
        {
          title: "Reports",
          isHidden: !permissions.canSeeReports,
          icon: ChartIcon,
          to: "/reports"
        },
        {
          title: "Treatment Plans",
          isHidden: !permissions.canSeeTreatmentPlans,
          icon: DiagnosisIcon,
          to: "/treatment-plans"
        },
        {
          title: "AR Claims",
          isHidden: !permissions.canSeeARClaims,
          icon: BankAccountIcon,
          to: "/ar-claims"
        }
      ]
    },
    {
      title: "Automation",
      isHidden: USER_ROLE_OPTIONS.dentalOffice.id !== user?.role,
      items: [
        {
          title: "Integrations",
          isHidden: !permissions.canSeeIntegrations,
          icon: DataConnectionIcon,
          to: "/integrations"
        }
      ]
    },
    {
      title: "Customise",
      isHidden: USER_ROLE_OPTIONS.dentalOffice.id !== user?.role,
      items: [
        {
          title: "Form Builder",
          isHidden: !permissions.canSeeFormBuilder,
          icon: BuildIcon,
          to: "/form-builder"
        }
      ]
    },
    {
      title: "Administration",
      isHidden: USER_ROLE_OPTIONS.administrator.id !== user?.role,
      items: [
        {
          title: "Users",
          isHidden: !permissions.canSeeUsers,
          icon: PeopleIcon,
          to: "/users"
        }
      ]
    }
  ];

  useEffect(() => {
    if (!permissions.canSeeTreatmentPlans) {
      return history.replace("/not-found");
    }
  });

  return (
    <Wrapper>
      <Header>
        {/* Logo */}
        <LogoWrapper>
          <Logo type={LogoTypes.Color} />
        </LogoWrapper>
      </Header>

      <Content>
        {/* Menu */}
        <Menu.Wrapper>
          {_.map(menuItems, section => {
            if (section.isHidden) {
              return null;
            }

            return (
              <Menu.Section key={`MenuItem_${section.title}`}>
                <Menu.SectionTitle>{section.title}</Menu.SectionTitle>
                {_.map(section.items, item => {
                  if (item.isHidden) {
                    return null;
                  }

                  return (
                    <Menu.Item
                      key={`MenuItem_${section.title}_${item.title}`}
                      to={item.to}
                    >
                      <Menu.ItemIconWrapper>
                        <Icon icon={item.icon} />
                      </Menu.ItemIconWrapper>
                      <Text>{item.title}</Text>
                    </Menu.Item>
                  );
                })}
              </Menu.Section>
            );
          })}
        </Menu.Wrapper>
      </Content>

      {/* <InnerWrapper> */}
      {/* Logo */}
      {/* <LogoWrapper>
          <Logo type={LogoTypes.Color} />
        </LogoWrapper> */}

      {/* Menu */}
      {/* <Menu.Wrapper>
          {_.map(menuItems, section => {
            if (section.isHidden) {
              return null;
            }

            return (
              <Menu.Section key={`MenuItem_${section.title}`}>
                <Menu.SectionTitle>{section.title}</Menu.SectionTitle>
                {_.map(section.items, item => {
                  if (item.isHidden) {
                    return null;
                  }

                  return (
                    <Menu.Item
                      key={`MenuItem_${section.title}_${item.title}`}
                      to={item.to}
                    >
                      <Menu.ItemIconWrapper>
                        <Icon icon={item.icon} />
                      </Menu.ItemIconWrapper>
                      <Text>{item.title}</Text>
                    </Menu.Item>
                  );
                })}
              </Menu.Section>
            );
          })}
        </Menu.Wrapper> */}
      {/* </InnerWrapper> */}
    </Wrapper>
  );
});

// Exports
export default React.memo(Sidebar);
