// Modules
import _ from "lodash";
import React, { useState } from "react";
import Modal from "react-modal";

// Styles
import { Header, TableWrapper } from "./custom-form-preview.styles";

// Theme
import Colors from "theming/colors";

// UI
import { Button, IconButton, CrossIcon, Heading } from "evergreen-ui";

// Set Modal Element
Modal.setAppElement("#app");

const styles = {
  table: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%"
  },
  label: {
    height: 50,
    fontWeight: "bold",
    border: `1px solid ${Colors.Gray}`,
    backgroundColor: Colors.withAlpha(Colors.Gray, 0.25),
    padding: 16
  },
  text: {
    height: 50,
    border: `1px solid ${Colors.Gray}`,
    backgroundColor: "white",
    padding: 16
  }
};

// Component
export const CustomFormPreview = ({ rows }) => {
  // State
  const [isOpen, setIsOpen] = useState(false);

  // Open Preview
  const _openPreview = () => setIsOpen(true);

  // Close Preview
  const _closePreview = () => setIsOpen(false);

  return (
    <React.Fragment>
      <Button onClick={_openPreview}>Preview</Button>

      <Modal isOpen={isOpen} onRequestClose={_closePreview}>
        <Header>
          {/* Heading */}
          <Heading>Form Structure Preview</Heading>

          {/* Close Button */}
          <IconButton onClick={_closePreview} icon={CrossIcon} />
        </Header>

        {/* Table */}
        <TableWrapper id="table-wrapper">
          <table id="table" style={styles.table}>
            <tbody>
              {_.map(rows, (row, ndx) => (
                <React.Fragment key={`report-table-row-${ndx}`}>
                  <tr>
                    {_.map(row, item => {
                      const key = `report-table-row-header-${item.id}`;

                      return (
                        <td key={key} style={styles.label}>
                          {item.props.label}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    {_.map(row, item => {
                      const key = `report-table-row-type-${item.props.label}`;

                      if (item.type === "Boolean") {
                        return (
                          <td key={key} style={styles.text}>
                            True/False
                          </td>
                        );
                      }

                      if (item.type === "Select") {
                        return (
                          <td key={key} style={styles.text}>
                            {_.map(item.props.options, o => o.label).join("/")}
                          </td>
                        );
                      }

                      return (
                        <td key={key} style={styles.text}>
                          {item.type || "-"}
                        </td>
                      );
                    })}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </TableWrapper>
      </Modal>
    </React.Fragment>
  );
};

// Exports
export default React.memo(CustomFormPreview);
