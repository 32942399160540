// Modules
import Styled from "styled-components";
import { Link } from "react-router-dom";

// Theme
import Colors, { withAlpha } from "theming/colors";
import Spacing from "theming/spacing";

// Wrapper
export const Wrapper = Styled(Link)`
  width: 100%;
  text-decoration: none;
  background: ${({ $color }) => withAlpha($color, 0.75) || Colors.White};
  border: 1px solid ${({ $color }) => $color || Colors.Gray};
  padding: ${Spacing.Space1x};
  border-radius: 4px;
  display: block;

  transition: transform 0.075s ease-in;

  &:hover {
    text-decoration: none;
    transform: scale(1.025);
  }
  
  &:active {
    transform: scale(0.975);
  }
`;

// Label Wrapper
export const LabelWrapper = Styled.div``;

// Count Wrapper
export const CountWrapper = Styled.div``;
