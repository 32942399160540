// Modules
import Styled from "styled-components";

// Theme
import Colors from "theming/colors";
import Spacing from "theming/spacing";

// Wrapper
export const Wrapper = Styled.div`
  max-width: 520px;
`;

// Threads
export const Threads = Styled.div``;

// Thread
export const Thread = {
  Wrapper: Styled.div`
    width: 100%;
    padding-top: ${Spacing.SpaceNx(0.75)};
    padding-bottom: ${Spacing.SpaceNx(0.75)};
    border-bottom: 1px solid ${Colors.Gray};

    &:first-child {
      padding-top: 0;
    }
    
    &:last-child {
      border-bottom: 0;
    }
  `,
  Author: Styled.div`
    margin-bottom: ${Spacing.SpaceNx(0)};
  `,
  Timestamp: Styled.div`
    margin-bottom: ${Spacing.SpaceNx(0.25)};
  `,
  Message: Styled.div``
};

// Composer
export const Composer = {
  Wrapper: Styled.div`
    display: flex;
    margin-bottom: ${Spacing.Space1x};
    width: 100%;
  `,
  InputWrapper: Styled.div`
    width: 100%;
    padding-right: ${Spacing.SpaceNx(0.125)};
  `,
  SubmitWrapper: Styled.div`
    padding-left: ${Spacing.SpaceNx(0.125)};
  `
};
