// Modules
import Styled from "styled-components";

// Theme
import Colors, { withAlpha } from "theming/colors";
import Spacing from "theming/spacing";

// Wrapper
export const Wrapper = Styled.div`
  padding-bottom: 70px;
`;

// Item State
export const ItemState = Styled.div`
  width: 8px; height: 8px;
  border-radius: 100%;

  background: ${props => {
    if (props.$isActive) {
      return Colors.Success;
    }

    return Colors.Danger;
  }};
`;

// State Wrapper
export const StateWrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spacing.Space4x};
  width: 100%;
`;
