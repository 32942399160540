// Modules
import _ from "lodash";

// Services
import Http from "services/http.service";

// Caches
const zipCodeCache = {};

// Get Location By Zip Code
export const getLocationByZipCode = async zipCode => {
  try {
    if (_.isObject(zipCodeCache[zipCode])) {
      return Promise.resolve(zipCodeCache[zipCode].state);
    }

    const apiKey = process.env.REACT_APP_ZIPAPI_KEY;

    const response = await Http.get(
      `https://www.zipcodeapi.com/rest/${apiKey}/info.json/${zipCode}/radians`
    );

    if (_.isEmpty(response.data)) {
      return null;
    }

    zipCodeCache[zipCode] = response.data;

    return response.data.state;
  } catch (exception) {
    return Promise.reject(exception);
  }
};

// Get Subscription Plans
export const getSubscriptionPlans = async () => {
  try {
    const response = await Http.get(`/misc/subscription-plans`);

    return response.data;
  } catch (exception) {
    return Promise.reject(exception);
  }
};

// Exports
export default {
  getLocationByZipCode,
  getSubscriptionPlans
};
