// Modules
import { createSlice } from "@reduxjs/toolkit";

// Initial State
const initialState = {
  isLoggedIn: false
};

// Slice
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    }
  }
});

// Actions
export const { setIsLoggedIn } = authSlice.actions;

// Exports
export default authSlice.reducer;
