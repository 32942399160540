// Modules
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Styles
import {
  Wrapper,
  InnerWrapper,
  PhotoWrapper,
  ContentWrapper
} from "./layout.styles";
import { Photo, Content, Header, Form } from "./login.styles";

// UI
import {
  Button,
  Heading,
  Text,
  Paragraph,
  TextInputField,
  toaster
} from "evergreen-ui";

// Redux
import { setUser } from "redux/user.slice";
import { setFluidLayout } from "redux/layout.slice";
import { setIsLoggedIn } from "redux/auth.slice";

// Shared
import Logo from "components/shared/logo";

// Services
import AuthService from "services/auth.service";

// Component
export const Login = () => {
  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();

  // Selectors
  const authReducer = useSelector(({ auth }) => auth);

  const { isLoggedIn } = authReducer;

  // State
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  // On Change Username
  const _onChangeUsername = event => {
    const { value } = event.target;

    setUsername(value);
  };

  // On Change Password
  const _onChangePassword = event => {
    const { value } = event.target;

    setPassword(value);
  };

  // On Login
  const _onLogin = async () => {
    try {
      setIsLoggingIn(true);

      await AuthService.login({ username, password });

      const urlParams = new URLSearchParams(window.location.search);

      const returnPath = urlParams.get("return");

      // Hard reload to configure layout
      setTimeout(() => {
        window.location.href = returnPath || "/dashboard";
      }, 2000);
    } catch (exception) {
      setIsLoggingIn(false);

      dispatch(setUser(null));
      dispatch(setIsLoggedIn(false));

      toaster.danger(exception.uiMessage, {
        duration: 60000
      });
    }
  };

  // To Forgot Password
  const _toForgotPassword = () => {
    history.push("/forgot-password");
  };

  // To Sign Up
  const _toSignUp = () => {
    history.push("/signup");
  };

  useEffect(() => {
    dispatch(setFluidLayout(true));
  }, []);

  if (isLoggedIn) {
    // Prevent flicker that
    // occurs while transitioning
    return null;
  }

  const canLogin = !isLoggingIn && username && password;

  return (
    <Wrapper>
      <InnerWrapper>
        {/* Content Wrapper */}
        <ContentWrapper>
          <Content.Wrapper>
            <Content.Element>
              {/* Header */}
              <Header.Wrapper>
                {/* Logo */}
                <Header.LogoWrapper>
                  <Logo />
                </Header.LogoWrapper>

                <Header.TitleWrapper>
                  <Heading size={800}>Login</Heading>
                </Header.TitleWrapper>

                <Header.TextWrapper>
                  <Paragraph>
                    We help you verify dental insurance easier and faster.
                    Please login with your credentials to continue.
                  </Paragraph>
                </Header.TextWrapper>
              </Header.Wrapper>
            </Content.Element>

            {/* Form */}
            <Content.Element>
              <Form.Wrapper>
                {/* Username */}
                <Form.Element>
                  <TextInputField
                    label="Username"
                    onChange={_onChangeUsername}
                    disabled={isLoggingIn}
                    type="text"
                  />
                </Form.Element>

                {/* Password */}
                <Form.Element>
                  <TextInputField
                    label="Password"
                    onChange={_onChangePassword}
                    disabled={isLoggingIn}
                    type="password"
                  />
                </Form.Element>

                {/* Submit */}
                <Form.Element>
                  <Button
                    type="submit"
                    appearance="primary"
                    onClick={_onLogin}
                    disabled={!canLogin}
                    isLoading={isLoggingIn}
                    marginBottom={12}
                    width="100%"
                  >
                    Login
                  </Button>
                </Form.Element>

                <Form.Element>
                  <Button
                    marginBottom={12}
                    onClick={_toForgotPassword}
                    width="100%"
                  >
                    Forgot Password?
                  </Button>
                </Form.Element>

                <Form.Element>
                  <Text
                    textAlign="center"
                    width="100%"
                    display="block"
                    marginBottom={12}
                  >
                    OR
                  </Text>
                </Form.Element>

                <Form.Element>
                  <Button width="100%" appearance="primary" onClick={_toSignUp}>
                    Create Account
                  </Button>
                </Form.Element>
              </Form.Wrapper>
            </Content.Element>
          </Content.Wrapper>
        </ContentWrapper>

        {/* Photo Wrapper */}
        <PhotoWrapper>
          <Photo />
        </PhotoWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

// Exports
export default Login;
