// Modules
import React from "react";

// Styles
import { Wrapper } from "./tile.styles.js";

// UI
import { Heading, Text } from "evergreen-ui";

// Component
export const DashboardTile = ({ color, count, label, linkTo }) => {
  return (
    <Wrapper $color={color} to={linkTo}>
      <Text>{label}</Text>
      <Heading size={800}>{count || 0}</Heading>
    </Wrapper>
  );
};

// Exports
export default DashboardTile;
