// Modules

// Services
import Http from "services/http.service";

// Create Insurance Model
const createInsuranceModel = insurance => {
  return {
    ...insurance,
    documents: insurance.documents,
    meta: {
      requiresVerification: insurance.requiresVerification,
      requiresInstantVerification: insurance.requiresInstantVerification,
      verifiedBy: insurance.verifiedBy,
      verifiedAt: insurance.verifiedAt,
      isVerifiedInstantly: insurance.isVerifiedInstantly,
      isVerified: insurance.isVerified,
      isActive: insurance.isActive
    }
  };
};

// Get Statistics
export const getStatistics = () => {
  return Http.get("/insurances/statistics").then(response => response.data);
};

// Get Insurance
export const getInsurance = (insuranceId, sanitize = true) => {
  return Http.get(`/insurances/${insuranceId}`).then(response => {
    if (sanitize) {
      return createInsuranceModel(response.data);
    }

    return response.data;
  });
};

// Get Insurances
export const getInsurances = () => {
  return Http.get("/insurances").then(response => response.data);
};

// Create Insurance
export const createInsurance = insurance => {
  return Http.post("/insurances", insurance).then(response => response.data);
};

// Update Insurance
export const updateInsurance = (insuranceId, payload) => {
  return Http.patch(`/insurances/${insuranceId}`, payload).then(
    response => response.data
  );
};

// Delete Insurance
const deleteInsurance = insuranceId => {
  return Http.delete(`/insurances/${insuranceId}`).then(
    response => response.data
  );
};

// Delete Document
// eslint-disable-next-line no-unused-vars
export const deleteDocument = documentId => {
  // Statements
};

// Upload Document
export const uploadDocument = async ({ file, insuranceId, fileAlias }) => {
  try {
    const payload = new FormData();

    payload.append("file", file);

    const url = `/insurances/${insuranceId}/upload?alias=${fileAlias}`;

    const response = await Http.post(url, payload, {
      headers: { "Content-Type": "multipart/form-data" }
    });

    return response.data;
  } catch (exception) {
    return Promise.reject(exception);
  }
};

// Verify Insurance
export const verifyInsurance = async insuranceId => {
  const response = await Http.post(`/insurances/${insuranceId}/verify`);

  return response.data;
};

// Instant Verify Insurance
export const instantVerifyInsurance = async insuranceId => {
  const response = await Http.post(`/insurances/${insuranceId}/instant-verify`);

  return response.data;
};

// Change Insurance Status
export const changeInsuranceStatus = async (insuranceId, isActive) => {
  const response = await Http.post(`/insurances/${insuranceId}/change-status`, {
    isActive
  });

  return response.data;
};

export const deleteUploadedFile = async (insuranceId, documentId) => {
  const response = await Http.delete(
    `/insurances/${insuranceId}/documents/${documentId}`
  );

  return response.data;
};

// Exports
export default {
  getStatistics,
  getInsurance,
  getInsurances,
  createInsurance,
  updateInsurance,
  deleteInsurance,
  deleteDocument,
  uploadDocument,
  verifyInsurance,
  instantVerifyInsurance,
  changeInsuranceStatus,
  deleteUploadedFile
};
