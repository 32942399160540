// Modules
import Styled from "styled-components";

// Theme
import Spacing from "theming/spacing";

// Wrapper
export const Wrapper = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 520px;
`;

// Illustration
export const Illustration = Styled.img`
  width: 280px;
  margin-bottom: ${Spacing.Space2x};
`;

// Title Wrapper
export const TitleWrapper = Styled.div`
  margin-bottom: ${Spacing.SpaceNx(0.25)};
`;

// Message Wrapper
export const MessageWrapper = Styled.div`
  text-align: center;
`;
