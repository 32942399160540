// Modules
import React from "react";
import _ from "lodash";

// Styles
import { Wrapper, Row, Column } from "./table.styles.js";

// UI
import { Heading, Text } from "evergreen-ui";

// Component
export const Table = React.memo(({ header, data, onClickItem, withBorder }) => {
  // On Click Item
  const _onClickItem = value => {
    if (_.isFunction(onClickItem)) {
      onClickItem(value);
    }
  };

  return (
    <Wrapper $withBorder={withBorder}>
      {/* Header */}
      {_.isArray(header) && (
        <Row $isHeader>
          {_.map(header, item => {
            if (item.hidden) {
              return null;
            }

            return (
              <Column $width={item.width} key={item.id}>
                <Heading size={400}>{item.label}</Heading>
              </Column>
            );
          })}
        </Row>
      )}

      {/* Body */}
      {_.map(data, row => (
        <Row key={row.id} onClick={() => _onClickItem(row.id)}>
          {_.map(row.fields, (item, ndx) => {
            if (item.hidden) {
              return null;
            }

            if (_.isFunction(item.value)) {
              return (
                <Column
                  $width={item.width}
                  key={`${item.thId}_${item.value}_${ndx}`}
                >
                  {item.value()}
                </Column>
              );
            }

            return (
              <Column
                $width={item.width}
                key={`${item.thId}_${item.value}_${ndx}`}
              >
                <Text>{item.value}</Text>
              </Column>
            );
          })}
        </Row>
      ))}
    </Wrapper>
  );
});

// Exports
export default React.memo(Table);
