// Local Storage: Get
export const getFromLocalStorage = key => {
  return window.localStorage.getItem(key);
};

// Local Storage: Set
export const setInLocalStorage = (key, value) => {
  window.localStorage.setItem(key, value);
};

// Local Storage: Remove
export const removeFromLocalStorage = key => {
  window.localStorage.removeItem(key);
};

// Local Storage: Clear
export const clearLocalStorage = () => {
  window.localStorage.clear();
};

// Session Storage: Get
export const getFromSessionStorage = key => {
  return window.sessionStorage.getItem(key);
};

// Session Storage: Set
export const setInSessionStorage = (key, value) => {
  window.sessionStorage.setItem(key, value);
};

// Session Storage: Remove
export const removeFromSessionStorage = key => {
  window.sessionStorage.removeItem(key);
};

// Session Storage: Clear
export const clearSessionStorage = () => {
  window.sessionStorage.clear();
};

// Exports
export default {
  getFromLocalStorage,
  setInLocalStorage,
  removeFromLocalStorage,
  clearLocalStorage,
  getFromSessionStorage,
  setInSessionStorage,
  removeFromSessionStorage,
  clearSessionStorage
};
