// Modules
import Styled from "styled-components";

// Theme
import Colors, { withAlpha } from "theming/colors";
import Spacing from "theming/spacing";

// Wrapper
export const Wrapper = Styled.div`
  display: flex;
  flex-direction: column;

  ${props => {
    if (props.$withBorder) {
      return `
        border: 1px solid ${Colors.Gray};
        border-bottom-width: 0;
      `;
    }
  }}

  @media only screen and (max-width: 1200px) {
    background: ${Colors.White};
    overflow-x: auto;
  }
`;

// Row
export const Row = Styled.div`
  display: flex;
  align-items: center;
  width: 100%; height: 46px;
  background: ${Colors.White};
  border-bottom: 1px solid ${Colors.Gray};
  padding-right: ${Spacing.Space1x};
  padding-left: ${Spacing.Space1x};
  min-width: 160px;
    
  ${({ $isHeader }) => {
    if (!$isHeader) {
      return `
        cursor: pointer;

        &:hover {
          background: ${withAlpha(Colors.Gray, 0.15)};
        }
      `;
    }
  }}

 
  ${props => {
    if (props.$isHeader) {
      return `
        z-index: 1;
        box-shadow: 0 6px 6px ${withAlpha(Colors.Black, 0.02)};
        position: sticky;
        top: 0;
      `;
    }
  }};
`;

// Column
export const Column = Styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  border-right: 1px solid ${Colors.Gray};
  padding-right: ${Spacing.Space1x};
  padding-left: ${Spacing.Space1x};
  padding-bottom: 1px;
  min-width: 160px;
  height: 100%;

  ${props => {
    if (props.$width) {
      return `
        flex: 0;
        min-width: ${props.$width}px;
        width: ${props.$width}px;
      `;
    }
  }};

  &:first-child {
    padding-left: 0;
  }
  
  &:last-child {
    padding-right: 0;
    border-width: 0;
  }
`;
