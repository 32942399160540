// Modules
import _ from "lodash";
import React from "react";

// Styles
import { Wrapper, Row, Blocks } from "./dynamic-form.styles.js";

// UI
import { Checkbox, TextInputField, SelectField } from "evergreen-ui";

// Component
export const DynamicForm = React.memo(
  ({ fields, onChange, isEditable = true }) => {
    // On Change Field
    const _onChangeField = (type, event) => {
      const { checked, name, value } = event.target;

      if (type === "Checkbox") {
        return onChange(type, name, checked);
      }

      return onChange(type, name, value);
    };

    // Render Text Input
    const _renderTextInput = field => {
      const { id, type, props } = field;

      return (
        <Blocks.Block key={id}>
          <TextInputField
            id={props.id}
            name={props.name}
            label={props.label}
            disabled={!isEditable}
            onChange={event => _onChangeField(type, event)}
            description={props.description}
            type={props.type || "text"}
            marginBottom={6}
            {...props}
          />
        </Blocks.Block>
      );
    };

    // Render Date Input
    const _renderDateInput = field => {
      const { id, type, props } = field;

      return (
        <Blocks.Block key={id}>
          <TextInputField
            id={props.id}
            name={props.name}
            label={props.label}
            disabled={!isEditable}
            onChange={event => _onChangeField(type, event)}
            description={props.description}
            placeholder={props.placeholder}
            value={props.value}
            marginBottom={6}
            type="date"
            {...props}
          />
        </Blocks.Block>
      );
    };

    // Render Checkbox
    const _renderCheckbox = field => {
      const { id, type, props } = field;

      return (
        <Blocks.Block key={id}>
          <Checkbox
            name={props.name}
            checked={props.checked}
            value={_.toString(props.value)}
            disabled={!isEditable}
            onChange={event => _onChangeField(type, event)}
            label={props.label}
            marginTop={26}
            {...props}
          />
        </Blocks.Block>
      );
    };

    // Render Boolean Input
    const _renderBooleanInput = field => {
      const { id, type, props } = field;

      return (
        <Blocks.Block key={id}>
          <SelectField
            name={props.name}
            checked={props.value}
            disabled={!isEditable}
            onChange={event => _onChangeField(type, event)}
            label={props.label}
            marginBottom={0}
            marginTop={0}
            {...props}
          >
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </SelectField>
        </Blocks.Block>
      );
    };

    // Render Select
    const _renderSelect = field => {
      const { id, type, props } = field;

      return (
        <Blocks.Block key={id}>
          <SelectField
            name={props.name}
            checked={props.value}
            disabled={!isEditable}
            onChange={event => _onChangeField(type, event)}
            label={props.label}
            marginBottom={0}
            marginTop={0}
            {...props}
          >
            <option value="">Select an option</option>
            {_.map(props.options, option => (
              <option key={option.id} value={option.value}>
                {option.label}
              </option>
            ))}
          </SelectField>
        </Blocks.Block>
      );
    };

    // Render Form
    const _renderForm = rows => {
      return _.map(rows, (fields, ndx) => {
        return (
          <Row key={`dynamic-form-row-${ndx}`}>
            {_.map(fields, field => {
              if (field.isHidden) {
                return null;
              }

              switch (field.type) {
                case "Text": {
                  return _renderTextInput(field);
                }
                case "Checkbox": {
                  return _renderCheckbox(field);
                }
                case "Date": {
                  return _renderDateInput(field);
                }
                case "Boolean": {
                  return _renderBooleanInput(field);
                }
                case "Select": {
                  return _renderSelect(field);
                }
                default: {
                  return null;
                }
              }
            })}
          </Row>
        );
      });
    };

    if (_.isEmpty(fields)) {
      return null;
    }

    return (
      <Wrapper>
        <Blocks.Wrapper>{_renderForm(fields)}</Blocks.Wrapper>
      </Wrapper>
    );
  }
);

// Exports
export default React.memo(DynamicForm);
