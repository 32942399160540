// Modules
import Styled, { keyframes } from "styled-components";

// Theming
import Colors from "theming/colors";
import Spacing from "theming/spacing";

// Wrapper
export const Wrapper = Styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw; height: 100vh;
  justify-content: center;
  align-items: center;
`;

// Logo Wrapper
export const LogoWrapper = Styled.div`
  margin-bottom: ${Spacing.SpaceNx(2)};
`;

// Progress Bar
export const ProgressBar = {
  Wrapper: Styled.div`
    width: 100%;
    max-width: 280px;
    height: 4px;
  `,
  Track: Styled.div`
    width: 100%; height: 100%;
    background: ${Colors.Gray};
    margin-bottom: ${Spacing.SpaceNx(0.5)};
    border-radius: 30px;
    overflow: hidden;
  `,
  Fill: Styled.div`
    transition: width 1.25s ease-in;
    width: ${({ $progress }) => $progress}%;
    background: linear-gradient(to right, ${Colors.Primary}, ${Colors.Secondary});
    border-radius: 30px;
    height: 100%; 
  `,
  Message: Styled.p`
    text-align: center;
  `
};
