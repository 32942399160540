// Modules
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

// Styles
import {
  Wrapper,
  InnerWrapper,
  PhotoWrapper,
  ContentWrapper
} from "./layout.styles";
import { Photo, Content, Header, Form } from "./create-account.styles";

// UI
import {
  Button,
  Checkbox,
  Heading,
  Paragraph,
  TextInputField,
  Link,
  SelectField,
  Text,
  option,
  toaster
} from "evergreen-ui";

// Assets
import {
  SOFTWARE_OPTIONS,
  COUNTRY_OPTIONS,
  GENDER_OPTIONS,
  USER_ROLE_OPTIONS
} from "common/constants";

// Redux
import { setFluidLayout } from "redux/layout.slice";

// Shared
import Logo from "components/shared/logo";

// Services
import AuthService from "services/auth.service";
import LocationService from "services/location.service";
import MiscService from "services/misc.service";

// Component
export const CreateAccount = () => {
  // Hooks
  const dispatch = useDispatch();
  const history = useHistory();

  // State
  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    emailAddress: "",
    dateOfBirth: "",
    practiceName: "",
    taxId: "",
    faxNumber: "",
    providerName: "",
    npiNumber: "",
    address: "",
    zipCode: ""
  });
  const [locations, setLocations] = useState("");
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [role, setRole] = useState(USER_ROLE_OPTIONS?.dentalOffice?.id);
  const [country, setCountry] = useState(COUNTRY_OPTIONS?.unitedStates?.id);
  const [software, setSoftware] = useState(SOFTWARE_OPTIONS?.openDental?.id);
  const [subscriptionPlans, setSubscriptionPlans] = useState(null);
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [location, setLocation] = useState("");
  const [gender, setGender] = useState("");

  // Reset State
  const resetState = () => {
    setDetails({
      firstName: "",
      lastName: "",
      mobileNumber: "",
      emailAddress: "",
      dateOfBirth: "",
      practiceName: "",
      taxId: "",
      faxNumber: "",
      providerName: "",
      npiNumber: "",
      address: "",
      zipCode: ""
    });
    setLocations("");
    setRole(USER_ROLE_OPTIONS?.dentalOffice?.id);
    setCountry(COUNTRY_OPTIONS?.unitedStates?.id);
    setSubscriptionPlan(subscriptionPlans?.[0]?.id);
    setSoftware(SOFTWARE_OPTIONS.openDental.id);
    setLocation("");
    setGender("");
    setIsSigningUp(false);
    setIsSignedUp(false);
  };

  // Get Subscription Plans
  const getSubscriptionPlans = async () => {
    try {
      const subscriptionPlans = await MiscService.getSubscriptionPlans();

      setSubscriptionPlan(subscriptionPlans[0].id);

      setSubscriptionPlans(subscriptionPlans);
    } catch (exception) {
      toaster.danger("Something went wrong! Please refresh the page.", {
        description: exception.uiMessage
      });
    }
  };

  // Get Locations
  const getLocations = async () => {
    try {
      const locations = await LocationService.getLocations();

      if (_.isEmpty(locations)) {
        return setLocations(locations);
      }

      const [location] = locations;

      setLocation(location.id);
      setLocations(locations);
    } catch (exception) {
      toaster.danger("Something went wrong!", {
        duration: 500000,
        description: exception.uiMessage
      });
    }
  };

  // On Change Text
  const onChangeText = event => {
    const { name, value } = event.target;

    setDetails({ ...details, [name]: value });
  };

  // On Change ZIP Code
  const onChangeZipCode = async event => {
    const { value: zipCode } = event.target;

    setDetails({ ...details, zipCode });

    if (zipCode.length !== 5) {
      return;
    }

    try {
      const location = await MiscService.getLocationByZipCode(zipCode);

      if (!location) {
        return;
      }

      const selectedLocation = _.find(locations, l =>
        _.isEqual(l.short, location)
      );

      setLocation(selectedLocation?.id);
    } catch (exception) {
      // Do nothing
      // eslint-disable-next-line no-console
      console.log(exception);
    }
  };

  // On Change Subscription Plan
  const onChangeSubscriptionPlan = event => {
    const { value: subscriptionPlanId } = event.target;

    setSubscriptionPlan(subscriptionPlanId);
  };

  // On Change Role
  const onChangeRole = event => {
    const { value: roleId } = event.target;

    setRole(Number(roleId));
  };

  // On Change Location
  const onChangeLocation = event => {
    const { value: locationId } = event.target;

    setLocation(locationId);
  };

  // On Change Country
  const onChangeCountry = event => {
    const { value: countryId } = event.target;

    setCountry(countryId);
  };

  // On Change Software
  const onChangeSoftware = event => {
    const { value: softwareId } = event.target;

    setSoftware(softwareId);
  };

  // On Change Gender
  const onChangeGender = event => {
    const { value: genderId } = event.target;

    setGender(genderId);
  };

  // On Check Terms of Use
  const onCheckTermsOfUse = event => {
    const { checked: hasAgreedToTerms } = event.target;

    setHasAgreedToTerms(hasAgreedToTerms);
  };

  // On Change Date
  const onChangeDate = event => {
    const { name, value } = event.target;

    setDetails({ ...details, [name]: value });
  };

  // On Sign Up
  const onSignUp = async () => {
    try {
      setIsSigningUp(true);

      const user = {
        ...details,
        role,
        software,
        subscriptionPlan,
        locationId: location,
        country,
        gender
      };

      await AuthService.signUp(user);

      setIsSigningUp(false);
      setIsSignedUp(true);
    } catch (exception) {
      setIsSigningUp(false);
      setIsSignedUp(false);

      toaster.danger(exception.uiMessage, {
        duration: 60000
      });
    }
  };

  // On Back to Login
  const onBackToLogin = () => {
    // Reset State
    resetState();

    history.push("/login");
  };

  useEffect(() => {
    dispatch(setFluidLayout(true));

    // Get Subscription Plans
    getSubscriptionPlans();
  }, []);

  // Render Form Fields
  const renderFormFields = () => {
    // Fields For Roles
    const insuranceAgentFormFields = [
      "role",
      "firstName",
      "lastName",
      "mobileNumber",
      "emailAddress",
      "dateOfBirth",
      "gender",
      "taxId",
      "providerName",
      "npiNumber",
      "address",
      "zipCode",
      "location",
      "country"
    ];
    const dentalOfficeFormFields = [
      "role",
      "subscriptionPlan",
      "firstName",
      "lastName",
      "mobileNumber",
      "emailAddress",
      "dateOfBirth",
      "gender",
      "practiceName",
      "software",
      "taxId",
      "faxNumber",
      "providerName",
      "npiNumber",
      "address",
      "zipCode",
      "location",
      "country"
    ];

    // Fields
    const formFields = [
      {
        id: "role",
        controlType: "select",
        options: _.filter(USER_ROLE_OPTIONS, role => !role.hidden),
        props: {
          name: "role",
          required: true,
          label: "Choose Your Role",
          onChange: onChangeRole,
          value: role
        }
      },
      {
        id: "subscriptionPlan",
        controlType: "select",
        options: _.map(subscriptionPlans, plan => ({
          id: plan.id,
          name: `${plan.name} - ${plan.currencySymbol}${plan.price}`
        })),
        props: {
          name: "subscriptionplan",
          required: true,
          label: "Choose Plan",
          onChange: onChangeSubscriptionPlan,
          value: subscriptionPlan
        }
      },
      {
        id: "firstName",
        controlType: "text",
        props: {
          name: "firstName",
          value: details.firstName,
          label: "First Name",
          required: true
        }
      },
      {
        id: "lastName",
        controlType: "text",
        props: {
          name: "lastName",
          value: details.lastName,
          label: "Last Name",
          required: true
        }
      },
      {
        id: "mobileNumber",
        controlType: "text",
        props: {
          name: "mobileNumber",
          value: details.mobileNumber,
          label: "Phone Number",
          required: true
        }
      },
      {
        id: "emailAddress",
        controlType: "text",
        props: {
          name: "emailAddress",
          value: details.emailAddress,
          label: "Email Address",
          required: true
        }
      },
      {
        id: "dateOfBirth",
        controlType: "date",
        props: {
          name: "dateOfBirth",
          value: details.dateOfBirth,
          label: "Date of Birth"
        }
      },
      {
        id: "gender",
        controlType: "select",
        options: _.filter(GENDER_OPTIONS, gender => !gender.hidden),
        props: {
          name: "gender",
          label: "Gender",
          onChange: onChangeGender,
          required: true,
          value: gender
        }
      },
      {
        id: "practiceName",
        controlType: "text",
        props: {
          name: "practiceName",
          value: details.practiceName,
          label: "Practice Name",
          required: true
        }
      },
      {
        id: "software",
        controlType: "select",
        options: _.filter(SOFTWARE_OPTIONS, software => !software.hidden),
        props: {
          name: "software",
          label: "Software",
          onChange: onChangeSoftware,
          required: true,
          value: software
        }
      },
      {
        id: "taxId",
        controlType: "text",
        props: {
          name: "taxId",
          value: details.taxId,
          label: "Tax ID",
          required: true
        }
      },
      {
        id: "faxNumber",
        controlType: "text",
        props: {
          name: "faxNumber",
          value: details.faxNumber,
          label: "Fax Number"
        }
      },
      {
        id: "providerName",
        controlType: "text",
        props: {
          name: "providerName",
          value: details.providerName,
          label: "Provider Name"
        }
      },
      {
        id: "npiNumber",
        controlType: "text",
        props: {
          maxLength: 9,
          name: "npiNumber",
          value: details.npiNumber,
          label: "NPI #",
          required: true
        }
      },
      {
        id: "address",
        controlType: "text",
        props: {
          name: "address",
          value: details.address,
          label: "Address",
          required: true
        }
      },
      {
        id: "zipCode",
        controlType: "text",
        props: {
          name: "zipCode",
          value: details.zipCode,
          onChange: onChangeZipCode,
          label: "ZIP Code",
          required: true
        }
      },
      {
        id: "location",
        controlType: "select",
        options: locations,
        props: {
          name: "location",
          label: "Location",
          onChange: onChangeLocation,
          value: location,
          required: true
        }
      },
      {
        id: "country",
        controlType: "select",
        options: _.filter(COUNTRY_OPTIONS, country => !country.hidden),
        props: {
          name: "country",
          label: "Country",
          onChange: onChangeCountry,
          value: country,
          required: true
        }
      }
    ];

    let currentRoleFields;

    if (_.isEqual(USER_ROLE_OPTIONS.dentalOffice.id, role)) {
      currentRoleFields = dentalOfficeFormFields;
    }
    if (_.isEqual(USER_ROLE_OPTIONS.insuranceAgent.id, role)) {
      currentRoleFields = insuranceAgentFormFields;
    }

    return _.map(formFields, field => {
      if (!_.includes(currentRoleFields, field.id)) {
        return null;
      }

      const key = `SignUpFormField${field.id}`;

      if (_.isEqual(field.controlType, "text")) {
        const onChange = _.isFunction(field.props.onChange)
          ? field.props.onChange
          : onChangeText;

        return (
          <Form.Element key={key}>
            <TextInputField
              {...field.props}
              onChange={onChange}
              disabled={isSigningUp}
              marginBottom={0}
            />
          </Form.Element>
        );
      }

      if (_.isEqual(field.controlType, "select")) {
        return (
          <Form.Element key={key}>
            <SelectField
              {...field.props}
              disabled={isSigningUp}
              marginBottom={0}
            >
              {_.map(field.options, o => (
                <option key={`${key}Option${o.id}`} value={o.id}>
                  {o.name}
                </option>
              ))}
            </SelectField>
          </Form.Element>
        );
      }

      if (_.isEqual(field.controlType, "date")) {
        return (
          <Form.Element key={key}>
            <TextInputField
              {...field.props}
              onChange={onChangeDate}
              disabled={isSigningUp}
              marginBottom={0}
              type="date"
            />
          </Form.Element>
        );
      }

      return null;
    });
  };

  useEffect(() => {
    // Get Locations
    getLocations();

    // Reset State
    resetState();
  }, []);

  if (isSignedUp) {
    return (
      <Wrapper>
        <InnerWrapper>
          {/* Photo Wrapper */}
          <PhotoWrapper>
            <Photo />
          </PhotoWrapper>

          {/* Content Wrapper */}
          <ContentWrapper>
            <Content.Wrapper>
              <Content.Element>
                {/* Header */}
                <Header.Wrapper>
                  <Header.TitleWrapper>
                    <Heading size={800}>Signed Up!</Heading>
                  </Header.TitleWrapper>

                  <Header.TextWrapper>
                    <Paragraph>
                      We have sent an email with your login details. Please
                      check your email and login with those credentials.
                    </Paragraph>
                  </Header.TextWrapper>
                </Header.Wrapper>
              </Content.Element>

              {/* Form */}
              <Content.Element>
                <Button onClick={onBackToLogin}>Back to Login</Button>
              </Content.Element>
            </Content.Wrapper>
          </ContentWrapper>
        </InnerWrapper>
      </Wrapper>
    );
  }

  const canSignUp =
    !_.isEmpty(details.firstName) &&
    !_.isEmpty(details.lastName) &&
    !_.isEmpty(details.mobileNumber) &&
    !_.isEmpty(details.emailAddress) &&
    !_.isEmpty(details.taxId) &&
    !_.isEmpty(details.npiNumber) &&
    !_.isEmpty(details.address) &&
    !_.isEmpty(details.zipCode) &&
    hasAgreedToTerms &&
    subscriptionPlan &&
    software &&
    location &&
    country;

  return (
    <Wrapper>
      <InnerWrapper>
        {/* Content Wrapper */}
        <ContentWrapper>
          <Content.Wrapper>
            <Content.Element>
              {/* Header */}
              <Header.Wrapper>
                <Header.LogoWrapper>
                  <Logo />
                </Header.LogoWrapper>
                <Header.TitleWrapper>
                  <Heading size={800}>Sign Up</Heading>
                </Header.TitleWrapper>

                <Header.TextWrapper>
                  <Paragraph>
                    We help you verify dental insurance easier and faster.
                    Create an account for free and start verifying insurances.
                  </Paragraph>
                </Header.TextWrapper>
              </Header.Wrapper>
            </Content.Element>

            {/* Form */}
            <Content.Element>
              <Form.Wrapper>{renderFormFields()}</Form.Wrapper>
              <Form.Wrapper>
                <Form.Element withCheckbox hasActions>
                  <Checkbox
                    marginRight={12}
                    onChange={onCheckTermsOfUse}
                    checked={hasAgreedToTerms}
                  />
                  <Text>
                    I agree to the{" "}
                    <Link
                      rel="nofollow noreferrer"
                      href="/documents/div-terms-of-use.pdf"
                      target="_blank"
                    >
                      Terms of Use
                    </Link>
                  </Text>
                </Form.Element>
              </Form.Wrapper>
              <Form.Wrapper>
                <Form.Element hasActions>
                  <Button
                    appearance="primary"
                    onClick={onSignUp}
                    disabled={!canSignUp}
                    isLoading={isSigningUp}
                    marginBottom={12}
                    width="100%"
                  >
                    Create Account
                  </Button>

                  <Button width="100%" onClick={onBackToLogin}>
                    Already a member? Login here
                  </Button>
                </Form.Element>
              </Form.Wrapper>
            </Content.Element>
          </Content.Wrapper>
        </ContentWrapper>

        {/* Photo Wrapper */}
        <PhotoWrapper>
          <Photo />
        </PhotoWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

// Exports
export default CreateAccount;
