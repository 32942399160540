// Constants
const SpaceUnit = 1.5;

// Exports
export default {
  Space1x: `${SpaceUnit}rem`,
  Space2x: `${SpaceUnit * 2}rem`,
  Space3x: `${SpaceUnit * 3}rem`,
  Space4x: `${SpaceUnit * 4}rem`,
  Space5x: `${SpaceUnit * 5}rem`,
  Space6x: `${SpaceUnit * 6}rem`,
  SpaceNx: n => {
    return `${SpaceUnit * n}rem`;
  }
};
